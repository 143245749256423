
body{
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.social-icons {
  padding: 10px 0px;
}

.brdcrme {
  background-color: #F9F9F9;
  width: 100%;
  overflow: hidden;
}

.brdcrme ul {
  margin: 0px;
}

.brdcrme li {
  float: left;
  padding: 10px 15px;
  position: relative;
}

.brdcrme li+li:before {
  font-family: FontAwesome;
  content: "\f101";
  position: absolute;
  left: -2px;
  top: 10px;
}

.brdcrme li:last-child a {
  color: #ed3e25;
}

.force-small {
  text-transform: lowercase !important
}


/* The slider itself */

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 25px;
  background: #ffffff;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 30px;
  position: relative;
  margin: 15px 0 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 50px;
  height: 24px;
  background: #ed3e25;
  cursor: pointer;
  border-radius: 30px;
  border: 4px solid #fff;
}

.slider::-moz-range-thumb {
  width: 32px;
  height: 14px;
  background: #ed3e25;
  cursor: pointer;
  border-radius: 30px;
}


/*Float Button Number Start*/

.p_cta_right_pne {
  display: none;
  overflow: hidden;
  height: 46px;
  width: 46px;
  position: fixed;
  right: 20px;
  bottom: 100px;
  background-color: #2b9224;
  z-index: 9999;
  -webkit-border-radius: 30px;
  padding: 0 0 0 0;
  -moz-border-radius: 30px;
  border-radius: 30px;
  color: #fff;
  border: solid 3px #fff;
  line-height: 7px;
  font-size: 17px;
  font-weight: 600;
  -webkit-box-shadow: 6px 8px 5px -6px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 6px 8px 5px -6px rgba(0, 0, 0, 0.11);
  box-shadow: 6px 8px 5px -6px rgba(0, 0, 0, 0.11);
  cursor: pointer;
  -webkit-transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
  -webkit-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
}

.p_cta_right_pne_specific {
  bottom: 160px;
}

.p_cta_right_pne_specific .p_cta_icon_right {
  background-color: #ff9300;
}

.p_cta_right_pne_specific {
  background-color: #ef8a02;
}

.p_cta_right_pne_whatsapp {
  bottom: 219px;
}

.p_cta_right_pne_whatsapp .p_cta_icon_right {
  padding: 11px 12px;
}

.p_cta_right_pne_whatsapp .p_cta_icon_right {
  background-color: #2ba11f;
}

.p_cta_right_pne_whatsapp {
  background-color: #2b9224;
}

.p_cta_right_pne_whatsapp_single {
  bottom: 160px;
}

.p_cta_right_pne a {
  color: #fff !important;
}

.p_cta_icon_right {
  height: 40px;
  width: 40px;
  display: none;
  background-color: #2ba11f;
  font-size: 20px;
  -webkit-border-radius: 50%;
  padding: 12px;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  float: left;
  -webkit-transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition: all 99ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
  -webkit-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
}

.p_cta_icon_right_b {
  display: inline-block;
  float: left;
  height: 30px;
  overflow: hidden;
  -webkit-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
  -webkit-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
  width: 194px;
  padding: 16px 6px 6px 6px;
}

.p_cta_right_pne.atvb {
  width: 248px;
}


/*Float Button Number End*/

::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #324b90;
}

::selection {
  color: #fff;
  background: #324b90;
}

* {
  margin: 0px;
  padding: 0px;
  list-style: none;
  outline: none;
}

a,
a:focus {
  outline: none;
}

a {
  color: #232323;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

.redcol {
  color: #ed3e25 !important;
}

.sprite {
  background: url("../images/sprites.png") no-repeat;
  display: inline-block;
}

.fleft {
  float: none;
}

.fright {
  float: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #262262;
}

.fnt30-head {
  font-size: 30px;
  color: #262262;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #242424;
  font-size: 14px;
  overflow-x: hidden !important;
}

.montfont {
  font-family: 'Montserrat', sans-serif;
}

.opensansfont {
  font-family: 'Open Sans', sans-serif;
}

.latofont {
  font-family: 'Lato', sans-serif;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  margin: auto;
  z-index: 9999;
  width: 100%;
  background: rgba(4, 4, 4, 0.95);
  padding: 10px 0px 0;
  color: #fff;
  transition: all 0.3s ease-out;
}

header .logo {
  padding-top: 30px;
}

.logo img {
  width: 194px;
  margin-left: -50px;
  margin-top: 0px;
}


/* header.stickyheader .logo img{     margin-top: -16px;width: 300px;    margin-top: -20px;  } */

.topnavs {
  color: #fff;
  float: right;
}

.topnavs a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  padding-right: 16px;
}

.topnavs a+a {
  border-left: 1px solid #fff;
  padding-left: 16px;
  padding-right: 0;
}

.topnavs a i {
  margin-right: 5px;
}

.topnavs .liveChat h4 {
  margin: 0;
  color: #ffffff;
  font-weight: 500;
  margin-left: 25px;
  font-size: 14px;
}

.liveChat {
  display: inline-block;
}

.topnavs .livchat-divs {
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}

.topnavs .livchat-divs,
.topnavs .btn-radius-top {
  display: inline-block;
}

.topnavs .btn-radius-top {
  margin-left: 5px;
}

.topnavs .btn-radius-top a {
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 2px;
  font-family: 'Montserrat', sans-serif;
}

.topnavs .btn-radius-top a:hover {
  background-color: #fff;
  color: #242424;
}

.topnavs .btn-radius-top a:hover i {
  color: #ed3e25;
}

.topnavs .btn-radius-top a+a {
  background-color: #fff;
  color: #242424;
  margin-right: 0;
}

.topnavs .btn-radius-top a+a i {
  color: #ed3e25;
  font-weight: 700;
}

.topnavs .btn-radius-top a+a:hover {
  background-color: #ed3e25;
  color: #fff;
}

.topnavs .btn-radius-top a+a:hover i {
  color: #fff;
}

.topnavs .btn-radius-top a i {
  margin-right: 2px;
}

header .main-nav {
  display: block;
}
.navbar-inverse .navbar-nav>li>a{
  color: #fff;
}

/* header .main-nav>ul {
  display: block;
  width: 100%;
  float: left;
  margin-bottom: 0;
  text-align: right;
  position: relative;
} */

header .main-nav ul li {
  display: inline-block;
  z-index: 2222222;
}

header .main-nav ul>li>a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 18px 9px 18px;
  display: block;
  position: relative;
}

header .main-nav ul>li.home img {
  margin: -4px 0 0 0;
}

header .main-nav ul>li>a:hover {
  color: #ffc5d4;
}

header .main-nav ul>li.dropparent:hover>a::after {
  content: '';
  position: absolute;
  left: 0px;
  right: 0;
  margin: auto;
  bottom: 3px;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ff527f;
}

/* header .main-nav ul>li.selectnavs>a::before {
  content: '';
  position: absolute;
  left: 0px;
  right: 9px;
  margin: auto;
  bottom: 0;
  background: #fff;
  width: 80%;
  height: 3px;
} */

header .main-nav ul>li>a i {
  margin-left: 5px;
}

header .main-nav ul>li:first-child a {
  padding-left: 0;
}

header .main-nav ul>li:last-child a {
  padding-right: 0;
}

.bannermm {
  position: relative;
  width: 100%;
  background: url("../images/banner1-home.jpg") no-repeat;
  background-size: cover;
  padding: 190px 0 20px;
  min-height: 100vh;
  display: flex !important;
  align-items: center;
}
.slides-mm{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 50px 0px;
}

.bannermm.webbanner {
  background: url("../images/websitebanner-home.jpg") no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex !important;
  align-items: center;
}

.bannermm .leftcont-ban h1 {
  font-size: 36px;
  color: #fff;
  font-weight: 600;
  border-bottom: 2px solid #fff;
  padding-bottom: 15px;
}

.bannermm .leftcont-ban h1 span {
  font-weight: 300;
  font-size: 38px;
}

body.inner-pages .bannermm .leftcont-ban h1 {
  margin-top: 95px;
}

.bannermm .leftcont-ban h1 strong {
  font-weight: 700;
  font-size: 62px;
  margin-bottom: 8px;
  display: block;
}

body.mainhome .bannermm .leftcont-ban h1 {
  border-bottom: 0px;
}

body.mainhome .bannermm .leftcont-ban p {
  position: relative;
  display: inline-block;
}

body.mainhome .bannermm .leftcont-ban p:before {
  height: 2px;
  background-color: #fff;
  content: " ";
  display: block;
  width: 100%;
  position: absolute;
  top: -9px;
  left: 0px;
}

body.mainhome .bannermm.bnj-3 .leftcont-ban p:before {
  width: 580px;
}

body.mainhome .bannermm.bnj-2 .leftcont-ban p:before {
  width: 560px;
}

body.mainhome .bannermm.bnj-1 .leftcont-ban p:before {
  width: 545px;
}

.bannermm .leftcont-ban p {
  color: #fff;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  padding-top: 10px;
}

.posrel {
  position: relative;
}

.posinit {
  position: initial;
}

.leftcont-ban a {
  font-size: 23px;
  font-weight: 300;
  border-radius: 30px;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 20px;
  margin-top: 30px;
  display: block;
  width: 337px;
  height: 49px;
  overflow: hidden;
  display: inline-table;
  transition: all 0.3s ease-out;
}

.leftcont-ban a i {
  display: none;
}

.leftcont-ban a i.btnarow {
  background-position: -3px -3px;
  width: 47px;
  height: 10px;
  margin-left: 9px;
  vertical-align: middle;
  margin-top: -1px;
}

.leftcont-ban a:hover {
  background-color: #fff;
  color: #242424;
  font-weight: 500;
  width: 360px;
}

.leftcont-ban a:hover i {
  background-position: -0px -19px;
  display: flex !important;
}

.mainhome .bannermm {
  height: 100vh;
  /*  min-height: 890px; */
  display: flex;
  align-items: center;
  padding: 0;
}

.blink-bx {
  position: absolute;
  right: 0;
}

.blink-bx .blinkbxs {
  /* transform: translateY(-50%);*/
  display: inline-block;
  width: 195px;
  margin: 0 3px;
}

.parentdivss {
  position: relative;
  z-index: 22;
}

.blink-bx .blinkbxs div+div {
  margin-top: 10px;
}

.blink-bx .blinkbxs.blink-bx0 {
  transform: translateY(-50%);
}

.blink-bx .blinkbxs.blink-bx2 {
  transform: translateY(10%);
}

.blink-bx .blinkbxs.blink-bx3 {
  transform: translateY(5%);
}

.blinkbxs img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.5s ease;
}

.blinkbxs .active img {
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #0000006b);
  transform: scale(1.06);
}

.blink-bx .blinkbxs>div.active {
  position: relative;
  z-index: 2
}

.morethan-det {
  margin-top: 90px;
}

.morethan-det ul {
  margin-bottom: 0;
}

.morethan-det ul li {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: initial;
  width: 165px;
}

.morethan-det ul li span {
  font-family: 'Lato', sans-serif;
  display: block;
  font-size: 18px;
}

.morethan-det ul li strong {
  display: block;
  font-size: 40px;
}

.morethan-det ul li strong i {
  font-style: normal;
}

.morethan-det ul li+li {
  margin-left: 30px;
}


/*.morethan-det ul li div{ width: 100%; }*/

.LineAnimationButton {
  position: relative;
  -webkit-transition: .3s;
  transition: .3s;
  border-bottom-width: 0;
}

.LineAnimation-border {
  background-color: #fff;
}

.LineAnimation-border--right {
  right: 0;
  bottom: 8%;
  top: 12%;
  width: 2px;
  -webkit-transition: bottom .2s .4s;
  transition: bottom .2s .4s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.LineAnimation-border--bottom {
  bottom: 5px;
  right: 0;
  left: 95%;
  height: 2px;
  -webkit-transition: left .2s .2s;
  transition: left .2s .2s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.LineAnimation-border--top {
  top: 10px;
  right: 0;
  left: 60%;
  height: 2px;
  -webkit-transition: right .2s .6s;
  transition: right .2s .6s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.LineAnimation-border,
.LineAnimation-border-inner {
  position: absolute;
  content: '';
}

.morethan-det ul li:nth-child(2) .LineAnimation-border--bottom {
  left: 60%;
}

.morethan-det ul li:nth-child(3) .LineAnimation-border--bottom {
  left: 85%;
}

.morethan-det ul li:nth-child(4) .LineAnimation-border--bottom {
  left: 80%;
}

.morethan-det ul li:nth-child(5) .LineAnimation-border--bottom {
  left: 85%;
}

.scrolldown-fl {
  text-align: center;
  margin-top: 20px;
  width: 100%;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
}

.scrolldown-fl p {
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}

.scrolldown-fl a {
  display: inline-block;
  position: relative;
  z-index: 2;
  transition: opacity .3s;
  padding-bottom: 40px;
}

.scrolldown-fl img {
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

.scrolldown-fl a span.dotbol {
  background: #fff;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin-left: -2px;
}

.scrolldown-fl a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}

.scrolldown-fl a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.scrolldown-fl a span:nth-of-type(2) {
  top: 12px;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}

.scrolldown-fl a span:nth-of-type(3) {
  top: 24px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.scrolldown-fl a span:nth-of-type(4) {
  top: 16px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

@-webkit-keyframes sdb07 {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@keyframes sdb07 {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

.slick-dots {
  position: absolute;
  bottom: 10px !important;
  left: 0;
  list-style: none;
  display: block;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 0 0 0;
  padding: 0px 3px;
  cursor: pointer;
  width: initial;
  height: initial;
}
.slick-dots li button:before{
  font-size: 0px !important;
}
.slick-dots li button{
  background-color: #eeeeee !important;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  outline: none;
  background: #eeeeee;
  border: 1px solid #d8d8d8;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border-radius: 200px 200px 200px 200px;
}

.slick-dots li.slick-active button {
  background: #ea333f !important;
  width: 20px;
  border: 1px solid #ea333f !important;
  margin-right:12px !important;
}

.slick-prev,
.slick-next {
  background: url("../images/sprites.png") no-repeat;
  background-position: -51px 0;
  width: 55px;
  height: 14px;
}

.slick-next {
  background-position: -54px -17px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.incred-expe {
  padding: 80px 0;
  width: 100%;
  float: left;
}

.incred-expe .content-incred h3 {
  color: #111111;
  font-size: 36px;
  font-weight: 400;
  margin: 0 0 30px;
}

.incred-expe .content-incred .fnt-18 {
  font-size: 18px;
  margin-bottom: 20px;
}

.incred-expe img {
  width: 100%;
}

.what-offer {
  padding: 50px 0;
  width: 100%;
  float: left;
  text-align: center;
  background-size: 100% 100%;
}

.what-offer h3 {
  font-size: 36px;
  color: #111111;
  font-weight: 700;
}

.what-offer p {
  font-size: 16px;
  color: #111111;
  font-weight: 400;
}

.what-offer>div>ul {
  width: 100%;
  float: left;
  margin: 40px 0 0;
}

.what-offer>div>ul>li {
  width: 25%;
  float: left;
  position: relative;
  /* padding: 25px 0px 10px; */
}

.what-offer>div>ul>li div {
  width: 100%;
  padding: 0 25px;
  position: relative;
  z-index: 2;
  padding: 25px 60px 10px;
  min-height: 256px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-flow: column;
  transition: all 0.3s ease-out;
  justify-content: center;
}

.what-offer>div>ul>li div h3 {
  font-size: 22px;
  font-weight: 600;
  margin-left: 6px;
  margin: 0px 0 5px 6px;
}

.what-offer>div>ul>li div p {
  font-size: 13px;
  color: #242424;
  font-weight: 500;
  margin-bottom: 5px;
  letter-spacing: -0.2px;
  padding-left: 12px;
}

.what-offer>div>ul>li div:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../images/sprites.png") no-repeat;
  background-position: -3px -53px;
  width: 298px;
  height: 257px;
  z-index: -1;
}

.what-offer>div>ul>li+li div:before {
  left: -5px;
}

.what-offer>div>ul>li div+i {
  background-position: -364px -3px;
  width: 66px;
  height: 66px;
  margin: 10px 0;
}

.what-offer>div>ul>li i.icon2 {
  background-position: -454px -8px;
  width: 70px;
  height: 64px;
  margin: 0px 0 6px;
}

.what-offer>div>ul>li i.icon3 {
  background-position: -364px -88px;
  width: 61px;
  height: 63px;
  margin: 10px 0;
}

.what-offer>div>ul>li i.icon4 {
  background-position: -460px -88px;
  width: 64px;
  height: 64px;
  margin: 0px 0 6px;
}

.what-offer>div>ul>li div a {
  /* display: none !important; */
  color: #242424;
  font-size: 12px;
  text-decoration: underline;
  font-weight: 600;
  opacity: 0;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -o-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

.what-offer>div>ul>li div a i {
  color: #ed3e25;
  font-weight: 700;
}

.what-offer>div>ul>li div:hover a {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

.maincta {
  width: 100%;
  float: left;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 23px 0;
  background-image: url(../images/hero-1.jpg) !important;
}

.maincta h3 {
  color: #fff;
  font-size: 31px;
  font-weight: 400;
  margin: 0;
  line-height: 45px;
  margin-bottom: 8px;
}

.maincta h3 strong {
  font-size: 41px;
  font-weight: 700;
  display: block;
}

.maincta p {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.maincta .btn-radius-top {
  display: inline-block;
}

.maincta .btn-radius-top {
  /* margin-left: 10px; */
  float: right;
  margin-top: 30px;
}

.maincta .btn-radius-top a {
  border: 2px solid #fff;
  color: #fff;
  border-radius: 20px;
  padding: 8px 15px;
  font-size: 18px;
  font-weight: 500;
  margin: 0 5px;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.3s ease-out;
}

.maincta .btn-radius-top a:hover {
  color: #ed3e25;
  background-color: #fff;
}

.maincta .btn-radius-top a+a:hover {
  color: #fff;
  background-color: #ed3e25;
}

.maincta .btn-radius-top a+a:hover i {
  color: #fff;
}

.maincta .btn-radius-top a+a {
  background-color: #fff;
  color: #242424;
  margin-right: 0;
  font-weight: 600;
}

.maincta .btn-radius-top a+a i {
  color: #ed3e25;
  font-weight: 700;
}

.maincta .btn-radius-top a i {
  margin-right: 2px;
}

.maincta .callsdivs {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  display: block;
  text-align: center;
  float: left;
  width: 100%;
  padding: 45px 0 0px 0;
  text-shadow: 0px 0px 1px #313131;
}

.maincta .callsdivs strong {
  font-weight: normal;
  position: relative;
}

.maincta .callsdivs a {
  color: #fff;
}

.maincta .callsdivs i {
  font-style: normal;
  /*  font-size: 14px;
  position: absolute;s
  width: 100%;
  text-align: center;
  top: -19px;*/
}

.maincta .callsdivs strong+strong {
  margin-left: 10px;
  padding-left: 14px;
  border-left: 1px #fff solid;
}

.maincta .callsdivs .iconphone {
  background-position: -127px -2px;
  width: 26px;
  height: 26px;
  vertical-align: middle;
}

.padleft0 {
  padding-left: 0 !important;
}

.homeport-mm {
  padding: 30px 0;
  width: 100%;
  float: left;
}

.sechead {
  width: 100%;
  text-align: center;
}

.sechead h3 {
  color: #111111;
  font-size: 36px;
  font-weight: 700;
}

.sechead p {
  font-size: 16px;
  color: #242424;
}

.toptabs1 {
  width: 100%;
  float: left;
  margin-bottom: 0;
  text-align: center;
  margin: 20px 0;
}

.toptabs1 li {
  width: 150px;
  display: inline-block;
  margin: 0 5px;
  transition: all 0.3s ease-out;
}

.toptabs1 li span,
.toptabs1 li a {
  border: 1px solid #d8d8d8;
  border-radius: 30px;
  padding: 8px 10px;
  text-align: center;
  display: block;
  font-size: 14px;
  color: #ed3e25;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.toptabs1 li.active span,
.toptabs1 li.active a,
.toptabs1 li:hover span,
.toptabs1 li:hover a {
  background: #ed3e25;
  color: #fff;
  font-weight: 700;
  border: 1px solid transparent;
}

.portfoliomaindivs .topbartabs {
  border-top: 1px solid #d1d7e3;
  position: relative;
  width: 100%;
  float: left;
}

.portfoliomaindivs .tabschildrens {
  display: block;
  width: 100%;
  float: left;
  background: #fff;
  border: 1px solid #d1d7e3;
  position: relative;
  border-top: none;
  margin-top: -1px;
}

.portfoliomaindivs .tabschildrens ul {
  margin-bottom: 0;
  text-align: center;
}

.portfoliomaindivs .tabschildrens ul li {
  display: inline-block;
  padding: 10px 15px;
  position: relative;
}

.portfoliomaindivs .tabschildrens ul li+li:before {
  content: '';
  background: #242424;
  width: 4px;
  height: 1px;
  position: absolute;
  left: -5px;
  font-size: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.portfoliomaindivs .tabschildrens ul li span {
  color: #242424;
  font-size: 14px;
  cursor: pointer;
}

.portfoliomaindivs .tabschildrens ul li.active span {
  color: #ed3e25;
}

.portfoliomaindivs .topbartabs .container {
  position: relative;
}

.portfolio-mmbx .box-ports {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  transition: all linear 0.3s;
}

.portfolio-mmbx .box-ports img {
  width: 100%;
  border: 1px solid #e2dfdf;
}

.portfolio-mmbx {
  margin-top: 10px;
  position: relative;
}

.hovereffects-det {
  background: rgb(237 62 37 / 86%) !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
}

.portfolio-mmbx .box-ports:hover .hovereffects-det {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -o-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}

.portfolio-mmbx .box-ports:hover {
  transform: scale(1.1);
  z-index: 2;
}

.hovereffects-det h3 {
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.btnstyles-nn a {
  border: 2px solid #fff;
  color: #fff;
  border-radius: 20px;
  padding: 5px 30px;
  font-size: 18px;
  font-weight: 500;
  margin: 5px 5px;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
}

.btnstyles-nn a:focus {
  color: #ffffff;
}

.btnstyles-nn a i {
  margin: 0 5px;
}

.portfolio-mmbx .leftcont-ban {
  text-align: center;
}

.portfolio-mmbx .leftcont-ban a {
  border-color: #ed3e25;
  color: #ed3e25;
  text-align: center;
  transition: width 0.3s linear, color 0.3s linear;
  margin: auto;
  font-weight: 500;
 
}

.portfolio-mmbx .leftcont-ban a:hover {
  background-color: #ed3e25;
  color: #fff;
  width: 292px !important;
}

.portfolio-mmbx .leftcont-ban a:hover i {
  background-position: -3px -3px;
}

.det-packages {
  padding: 30px 0 40px;
  width: 100%;
  float: left;
  background-color: #f1f1f1;
}

.det-packages .boxpackages {
  background: #fff;
  border-radius: 15px;
  padding: 20px 20px 30px;
  margin: 20px 0;
  float: left;
  width: 100%;
  box-shadow: -2px 10px 40px -10px #00000059;
  position: relative;
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
}

.det-packages .boxpackages .packheads {
  border-bottom: 1px solid #d1d7e3;
}

.det-packages .boxpackages .packheads h3 {
  font-size: 21px;
  color: #111111;
  font-weight: 700;
  margin: 0px 0 5px;
}

.det-packages .boxpackages .packheads p {
  font-size: 13px;
  color: #242424;
  min-height: 37px;
}

.det-packages .boxpackages:hover {
  border: 1px solid #ed3e25;
}

.det-packages .boxpackages:hover .listpacks ul li:before {
  color: #ed3e25;
}

.det-packages .boxpackages:hover a.btn-orders {
  background: #ed3e25;
  color: #fff;
}

.packtitles {
  display: flex;
  justify-content: space-between;
}

.packtitles .fleft {
  float: none;
}

.packtitles .fright {
  float: none;
}

.packagesbxs .responsive-slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.packages-wrapper .nav {
  margin-bottom: 0;
  text-align: center;
  margin: 50px 0 30px;
  display: flex;
  justify-content: center;
  border-bottom: none;
}

.packages-wrapper .nav li.active a {
  background: #ed3e25;
  color: #fff;
  border: 1px solid transparent;
}

.packages-wrapper .nav li a {
  border: 1px solid #a8a8a8;
  border-radius: 30px;
  padding: 7px 28px;
  text-align: center;
  display: block;
  font-size: 14px;
  color: #ed3e25;
  transition: all 0.3s ease-out;
  cursor: pointer;
  margin: 0px 6px;
}

.packages-wrapper .nav li:hover a {
  background: #ed3e25;
  color: #fff;
  border: 1px solid transparent;
}

.boxpackages .packdetails {
  padding: 10px 0;
}

.boxpackages .packdetails .packtitles .fleft h3 {
  font-size: 54px;
  color: #ed3e25;
  font-weight: 700;
  margin: 0;
}

.boxpackages .packdetails .packtitles .fleft h3 sub {
  font-size: 18px;
  font-weight: 600;
  color: #242424;
  bottom: 0.05em;
  margin-left: 5px;
  display: none
}

.packdetails .packtitles .fright h4 {
  font-size: 18px;
  color: #c4c4c4;
  font-weight: 600;
}

.packdetails .packtitles .fright h4 b {
  display: block;
  color: #242424;
  font-weight: 600;
}

.det-packages .responsive-slider .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.packdetails .packtitles .fright h4 span.cutprice {
  position: relative;
  top: .5em;
  color: #c4c4c4;
}

.packdetails .packtitles .fright h4 b {
  /*border-bottom:3px solid #ed3e25;*/
  position: relative;
  /* top:-.5em;*/
}

.packdetails .packtitles .fright h4 b:before {
  content: '';
  background: #ed3e25;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: 19px;
  margin: auto;
  left: 0;
  right: 0;
  transform: rotate(-12deg);
  z-index: 2;
}

.packdetails .listpacks ul {
  overflow-x: auto;
  display: block;
  width: 100%;
  float: left;
  padding: 15px 0;
  margin: 0;
  height: 250px;
  border-bottom: 1px solid #d1d7e3;
}

.packdetails .listpacks ul li {
  position: relative;
  padding: 5px 25px;
  font-size: 14px;
  color: #455164;
}

.packdetails .listpacks ul li:after,
.pacbundles-det .headbx-bundle ul.listbundles-packbx li:after {
  content: '';
  right: 0px;
  height: 1px;
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#e0e3eb), to(transparent));
  background-image: -webkit-linear-gradient(left, #e0e3eb, transparent);
  background-image: -moz-linear-gradient(left, #000, transparent);
  background-image: -o-linear-gradient(left, #000, transparent);
  display: block;
  position: absolute;
  bottom: 0;
  left: 25px;
}

.packdetails .listpacks ul li ul li {
  padding: 0;
  color: #fff;
  font-size: 13px;
}

.packdetails .listpacks ul li ul li:before {
  display: none;
}

.packdetails .listpacks ul li.last:after {
  display: none;
}

.packdetails .listpacks ul li:before {
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 0;
  top: 7px;
  color: #b1b9cc;
}

.packdetails .listpacks ul li.haschildren .child-tooltip {
  position: absolute;
  right: 0;
  top: 7px;
  color: #ed3e25;
}

.packdetails .listpacks ul li.haschildren .child-tooltip:before {
  content: "\f05a";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
}

.packdetails .listpacks ul li.haschildren ul {
  display: none;
  position: absolute;
  border-radius: 4px;
  opacity: 0.8;
  right: 0px;
  color: #fff;
  width: 220px;
  background: #000;
  padding: 10px 10px;
  overflow: hidden;
  z-index: 9;
}

.packdetails .botarea-pack {
  padding: 15px 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.packdetails .botarea-pack a.btn-orders {
  border: 1px solid #ed3e25;
  border-radius: 30px;
  padding: 4px 12px;
  color: #ed3e25;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.3s ease-out;
  margin: 5px 0 0
}

.packdetails .botarea-pack .liovechats-bx {
  padding-left: 20px;
}

.packdetails .botarea-pack .liovechats-bx a span {
  font-size: 10px;
  color: #242424;
  position: relative;
}

.packdetails .botarea-pack .liovechats-bx a span:before {
  content: "\f27b";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: -25px;
  top: -2px;
  color: #ed3e25;
  font-size: 16px;
}

.packdetails .botarea-pack .liovechats-bx a h5 {
  font-weight: 700;
  color: #111111;
  font-size: 16px;
  margin: 0;
}


/*.det-packages .col-md-3.always-active.active .boxpackages,*/

.det-packages .boxpackages:hover {
  transform: scale(1.1);
  border: 1px solid #ed3e25;
}

.det-packages .boxpackages.active {
  border: 1px solid #ed3e25;
}


/* .det-packages .boxpackages.active .packtitles .fleft h3{ font-size: 60px; } */

.det-packages .boxpackages:hover .packdetails .listpacks ul li:before {
  color: #ed3e25;
}

.det-packages .boxpackages:hover .botarea-pack a.btn-orders {
  background: #ed3e25;
  color: #fff;
}

.det-packages .boxpackages.active .botarea-pack a.btn-orders:hover {
  background: #fff;
  color: #ed3e25;
}

.det-packages .toptabs1 li a {
  border: 1px solid #a8a8a8;
}

.det-packages .boxpackages.active:before {
  content: '';
  position: absolute;
  right: -6px;
  background: url("../images/sprites.png") no-repeat;
  background-position: -897px -452px;
  width: 95px;
  height: 95px;
  top: -6px;
  z-index: 2;
}

.btnredstyl {
  text-align: center;
  width: 100%;
  float: left;
  padding: 20px 0;
}

.btnredstyl a {
  display: inline-block;
  padding: 8px 30px;
  color: #ed3e25;
  border: 1px solid #ed3e25;
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
}

.btnredstyl a i {
  margin-left: 5px;
}

.btnredstyl a:hover {
  background: #fff;
}

.enterprices-bundles {
  width: 100%;
  /* margin-bottom: 90px;*/
  float: left;
  padding: 30px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.enterprices-bundles h3,
.enterprices-bundles p {
  color: #fff;
  position: relative;
}

.enterprices-bundles h3 {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.enterprices-bundles h3:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  width: 192px;
  height: 4px;
  bottom: 0;
}

.enterprices-bundles .sechead {
  margin-bottom: 30px;
}

.pacbundles-det .headbx-bundle .fleft {
  width: 100%;
}

.pacbundles-det .headbx-bundle .fleft h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0px 0 0;
  float: left;
  width: 143px;
}

.pacbundles-det .headbx-bundle .packbundleprice {
  /* float: left; */
  font-size: 12px;
  color: #b3b3b3;
  font-weight: 600;
  line-height: 20px;
  padding: 0 0 0 15px;
  position: relative;
  display: inline-block;
  float: right;
}

.pacbundles-det .headbx-bundle .packbundleprice span {
  font-size: 22px;
  font-weight: 700;
  display: block;
}

.pacbundles-det .headbx-bundle .packbundleprice:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 20px;
  background: #fff;
}

.pacbundles-det .headbx-bundle {}

.pacbundles-det .headbx-bundle ul.listbundles-packbx {
  display: block;
  width: 100%;
  float: left;
  padding: 15px 0;
  margin: 0;
}

.pacbundles-det .headbx-bundle ul.listbundles-packbx>li {
  position: relative;
  padding: 5px 25px;
  font-size: 14px;
  color: #fff;
}

.pacbundles-det .headbx-bundle ul.listbundles-packbx>li.last {
  border-bottom: none;
}

.pacbundles-det .headbx-bundle ul.listbundles-packbx>li:before {
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 0;
  top: 7px;
  color: #fff;
}

.pacbundles-det .headbx-bundle li.haschildren .child-tooltip {
  position: absolute;
  right: 20px;
  top: 7px;
  color: #fff;
}

.pacbundles-det .headbx-bundle li.haschildren .child-tooltip:before {
  content: "\f05a";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
}

.pacbundles-det .headbx-bundle li.haschildren ul {
  display: none;
  position: absolute;
  border-radius: 4px;
  opacity: 0.9;
  right: 20px;
  color: #fff;
  width: 200px;
  background: #000;
  padding: 10px 10px;
  overflow: hidden;
  z-index: 9;
  text-align: center;
}

.pacbundles-det .headbx-bundle li.haschildren ul li {
  font-size: 13px;
}

.enterprices-bundles .totalpackagespricess {
  border-top: 1px solid #fff;
  margin-top: 20px;
  padding: 10px 0;
}

.enterprices-bundles .totalpackagespricess h4 {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
}

.enterprices-bundles .totalpackagespricess .underline-txt {
  text-decoration: line-through;
  margin-left: 5px;
}

.enterprices-bundles .totalpackagespricess .yellcol {
  color: #f6ff00;
  font-size: 40px;
  font-weight: 700;
  margin-left: 5px;
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles {
  float: right;
  margin-top: 8px;
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles a {
  background: #fff;
  padding: 8px 28px;
  background: #fff;
  color: #ed3e25;
  font-size: 20px;
  border-radius: 30px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
  box-shadow: -1px 3px 20px -1px #00000069;
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles a13 {
  position: relative;
  overflow: hidden;
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles a13:after {
  position: absolute;
  z-index: 2;
  background-color: #ed3e25;
  width: 0%;
  height: 100%;
  content: " ";
  left: 0px;
  top: 0px;
  -webkit-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
  -webkit-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles a31:hover:after {
  height: 100%;
  width: 100%;
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles a:hover {
  -webkit-box-shadow: inset 0px 0px 35px -20px rgba(255, 0, 66, 1);
  -moz-box-shadow: inset 0px 0px 35px -20px rgba(255, 0, 66, 1);
  box-shadow: inset 0px 0px 35px -20px rgba(255, 0, 66, 1);
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles a13:hover span {
  position: relative;
}

.enterprices-bundles .totalpackagespricess .btn-orders-bundles p {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}

.packagesall .portfolio-mmbx .leftcont-ban a:hover {
  width: 330px;
}

.packagesall .portfolio-mmbx .leftcont-ban a {
  width: 300px;
}

.tabsmainport .innertabcontents {
  display: none;
}

.tabsmainport .innertabcontents.active {
  display: block;
}

.fixedform {
  opacity: 0;
  position: fixed;
  top: 10%;
  right: -408px;
  margin: auto;
  /* background: #fff; */
  padding: 10px 0px 10px 0;
  z-index: 222;
  /*  padding-left: 62px;*/
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.fixedform .barforms {
  position: absolute;
  left: 0;
  top: 4px;
  background: url("../images/sprites.png") no-repeat;
  background-position: -827px -601px;
  width: 62px;
  height: 346px;
}

.fixedform .getdiscount {
  padding: 16px 20px;
  background: #fff;
  border-bottom-left-radius: 10px;
}

.fixedform .getdiscount h3 {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin: 0 0 20px;
  font-size: 21px;
  font-weight: 600;
  color: #111111;
}

.fixedform form {
  width: 100%;
}

.fixedform .mm-field {
  margin-bottom: 13px;
  width: 100%;
  position: relative;
  border: 1px solid #ebe9e9;
}

.fixedform .mm-field input {
  width: 100%;
}

.fixedform .mm-field input[type=text],
.fixedform .mm-field select,
.fixedform .mm-field input[type=email] {
  background-color: transparent !important;
  z-index: 10;
  position: relative;
  border: 1px solid #fff;
  padding: 10px 10px 10px 60px;
  width: 100%;
}

.fixedform .mm-field {
  position: relative;
  border-radius: 2px;
}

.fixedform .mm-field:before {
  content: '';
  background: url("../images/sprites.png") no-repeat;
  background-position: -558px -167px;
  width: 52px;
  height: 44px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}

.fixedform .mm-field.pnfields:before {
  display: none;
}

.fixedform .mm-field.emailfield:before {
  background-position: -623px -167px;
  width: 52px;
  height: 44px;
}

.fixedform .mm-field.pnfields .intl-tel-input {
  width: 100%;
}

fieldset {
  position: absolute;
  top: 10px;
  left: 60px;
  z-index: 2;
  color: #666666;
  font-size: 15px;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease-in-out;
}

.intl-tel-input.allow-dropdown .flag-container,
.intl-tel-input.separate-dial-code .flag-container {
  z-index: 999;
}

.fixedform .intl-tel-input.allow-dropdown .flag-container .selected-flag {
  border-right: none;
}

.fixedform .intl-tel-input.allow-dropdown .flag-container .selected-flag {
  background: rgb(219, 219, 219);
}

.fixedform .mm-field.selectsertvice:before {
  background-position: -684px -166px;
  width: 52px;
  height: 44px;
}

.fixedform .mm-field.whbgnone {
  margin-bottom: 0;
}

.fixedform .mm-field.whbgnone:before {
  background-position: -725px -97px;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: initial;
}

.fixedform .mm-field.whbgnone button[type="submit"] {
  background: #ed3e25;
  border-radius: 30px;
  width: 100%;
  padding: 14px 35px;
  color: #fff;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  border: transparent;
}

.fixedform .mm-field fieldset.active {
  top: 0px;
  font-size: 10px;
  color: #000;
  padding: 1px 8px;
  z-index: 20;
  left: 52px;
  font-weight: 500;
}

.fixedform .mm-field.pnfields fieldset {
  left: 100px;
}

.fixedform .mm-field.pnfields fieldset.active {
  left: 92px;
}

.fixedform.active {
  right: 0;
}

.fixed-forms-main {
  opacity: 1;
}

.fixedform.active .barforms {
  background-position: -915px -601px;
}

header .main-nav>ul>li>.dropdown {
  display: none;
  visibility: hidden;
  opacity: 0;
}

header .main-nav>ul>li:hover>.dropdown {
  visibility: visible;
  opacity: 1;
  top: 96%;
  display: block;
}

header .main-nav ul li .dropdown {
  text-align: left;
  padding: 20px 20px;
  position: absolute;
  left: -203px !important;
  top: 130%;
  width: 720px;
  z-index: 999;
  border-radius: 20px;
  background: #f9f7fd;
  border: 2px solid #ed3e25;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.dropdown .lstnavig,
.dropdown .listdiscret,
.dropdown .listimgs {
  float: left;
  color: #242424;
  font-size: 14px;
}

.dropdown .lstnavig .liststylss {
  text-align: left;
  width: 208px;
}

.dropdown .lstnavig .liststylss>li {
  display: block;
  border-bottom: 1px solid #e7e6e9;
  position: initial;
}

.dropdown .lstnavig .liststylss>li>a {
  color: #242424;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 0;
  font-weight: 500;
}

.dropdown .lstnavig>.liststylss>li>a i {
  background-position: -14px -474px;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  margin-right: 10px;
}

.dropdown .lstnavig .liststylss i.iconlogo-pack {
  background-position: -52px -474px;
  width: 24px;
  height: 24px;
}

.dropdown .lstnavig .liststylss i.iconlogo-port {
  background-position: -88px -473px;
  width: 25px;
  height: 25px;
}

.dropdown .lstnavig .liststylss i.iconlogo-review {
  background-position: -128px -474px;
  width: 25px;
  height: 25px;
}

.dropdown .lstnavig .liststylss li.last {
  border-bottom: none;
}

.dropdown .lstnavig .liststylss a {
  position: relative;
  transition: all 0.3s ease-out;
}

.dropdown .lstnavig .liststylss>li>a:after {
  content: "\f0da";
  font-family: fontAwesome;
  width: 24px;
  font-size: 18px;
  display: inline-block;
  padding-left: 4px;
  height: 10px;
  opacity: 0;
  transition: all 0.3s ease-out;
  color: #ed3e25;
  position: relative;
  top: 3px;
}

.dropdown .lstnavig .liststylss>li:hover a:after,
.dropdown .lstnavig .liststylss li.active a:after {
  opacity: 1;
}

.dropdown .lstnavig .liststylss>li:hover>a,
.dropdown .lstnavig .liststylss li.active>a {
  color: #ed3e25;
}

.dropdown .listdiscret {
  width: 68%;
  padding: 20px 20px;
}

.dropdown .listdiscret p {
  color: #242424;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 20px;
}

.dropdown .listdiscret ul {}

.dropdown .listdiscret ul li {
  width: 50%;
  float: left;
}

.dropdown .listdiscret ul li a:before {
  content: "\f0da";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  top: 8.5px;
  left: 0;
  color: #999999;
}

.dropdown .listdiscret ul li a {
  position: relative;
  display: block;
  color: #242424;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  padding: 6px 10px 6px 20px !important;
  font-weight: 500;
}

.dropdown .listimgs {
  float: right;
  border: 1px solid #cccccc;
  padding: 5px 5px;
  margin-top: 10px;
  width: 32%;
}

.dropdown .listdiscret>ul>li>a:hover {
  color: #ed3e25;
}

.no-src {
  cursor: default;
}

.no-src:hover {
  color: initial !important
}

.dropdown .lstnavig .liststylss li.active .innerdropdowns {
  opacity: 1;
  visibility: visible;
}

.dropdown .lstnavig .liststylss li .innerdropdowns {
  position: absolute;
  left: 220px;
  width: 475px;
  top: 8px;
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
}

.dropdown .listdiscret .btnstarts {
  border: 1px solid #ed3e25;
  font-size: 18px;
  color: #ed3e25;
  font-weight: 500;
  padding: 8px 30px;
  display: inline-block;
  border-radius: 30px;
  position: relative;
}

.dropdown .listdiscret .btnstarts:hover {
  border-color: #fff;
  background: #ed3e25;
  color: #fff
}

.dropdown .listdiscret .btnstarts.startspackfr {
  text-align: left;
  width: 220px;
  padding: 8px 30px 8px 20px;
  font-style: italic;
}

.dropdown .listdiscret .btnstarts.startspackfr span {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #ed3e25;
  border-radius: 100%;
  border: 1px solid #ed3e25;
  width: 60px;
  height: 60px;
  font-size: 22px;
  text-shadow: 1px 1px 1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-style: normal;
  transition-duration: 0.3s
}

.dropdown .listdiscret .btnstarts.startspackfr:hover span {
  background: #fff;
  color: #ed3e25;
  border-color: #ed3e25;
  text-shadow: none
}

.dropdown .listdiscret .btnstarts i {
  margin-left: 5px;
}

.formOverlaySide.active {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.stationery-p .bannermm {
  background-image: url(../images/stationery-logodesign.jpg) !important;
  padding: 210px 0 20px;
  background-position: center center;
}

.logopackages-p.stationery-p .bannermm {
  background-position: center center;
  padding: 210px 0 20px;
}

.bannerdesign-p.faqs-p .bannermm {
  background-image: url(../images/faqsbanner.jpg);
  padding: 210px 0 20px;
  background-position: center center;
}

.gettouchfaqs {
  padding: 50px 0;
  width: 100%;
  float: left;
}

.gettouchfaqs h3 {
  font-size: 36px;
  font-weight: 700;
  color: #111111;
}

.gettouchfaqs .packagesbxs {
  padding: 20px 0;
  display: block;
}

.gettouchfaqs .packagesbxs .toptabs1 {
  text-align: left;
}

.gettouchfaqs .packagesbxs .toptabs1 li {
  width: initial;
}

.gettouchfaqs .packagesbxs .toptabs1 a {
  padding: 8px 30px;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #e20031;
  color: #000000;
}

.gettouchfaqs .packagesbxs .toptabs1 li.active a,
.gettouchfaqs .packagesbxs .toptabs1 li:hover a {
  color: #fff;
}

.gettouchfaqs .accordion-faq {}

.accordion-faq .inner {
  padding: 1em;
  overflow: hidden;
  display: none;
  margin-bottom: 0;
  padding-right: 50px;
  margin-top: 0;
}

.accordion-faq .inner p {
  font-size: 14px;
  color: #6a6a6a;
  margin-bottom: 20px;
}

.accordion-faq .inner p a {
  color: #fff;
  text-decoration: underline;
}

.inner.show {
  /*display: block;*/
}

.accordion-faq .inner ul {
  margin-bottom: 0;
  margin-top: 10px;
}

.accordion-faq .inner ul li {
  color: #fff;
  font-size: 15px;
}

.accordion-faq>li {
  margin: 0px 0px 0px;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  float: left;
  position: relative;
}

.accordion-faq a.accord-tab {
  width: 100%;
  display: block;
  color: #111111;
  border-radius: 0.15em;
  transition: color 0.3s ease;
  position: relative;
  padding: 30px 70px 0px 20px;
  font-weight: 700;
  min-height: 70px;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
}

.accordion-faq>li.actives:after {
  color: #000;
  content: "\f107";
}

.accordion-faq>li>a {
  padding: 0.75em;
  color: #333333 !important;
  font-size: 20px;
}

.accordion-faq>li:after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #000000;
  display: flex;
  align-items: center;
}

.accordion-faq>li>a:hover,
.accordion-faq>li>a.actives {
  /* color: #ed2530 !important; */
}

.accordion-faq a.accord-tab:hover,
.accordion-faq a.accord-tab.actives {
  color: #333333;
}

.accordion-faq li ul.dbltabs {
  background-color: transparent;
}

.accordion-faq li ul>li>a.accord-tab {
  padding: 15px 15px;
  font-size: 16px;
  font-weight: 600;
  min-height: initial;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
}

.accordion-faq>li.actives {
  background: #f4f4f4;
  border: 1px solid #fad2db;
}

.accordion-faq>li.actives a.accord-tab {
  min-height: initial;
}

.logodesignport-p.portfolio .bannermm {
  background-image: url(../images/logodesignportfolio-banner.jpg);
  padding: 250px 0 20px;
  background-position: center center;
}

.branding-portfolio-p.portfolio .bannermm {
  background-image: url(../images/branding-portfolio-bann.jpg);
  padding: 250px 0 20px;
  background-position: center center;
}


/* audio, canvas, progress, video {
width: 100%;
} */

.pricerange-main {
  padding: 50px 0;
  width: 100%;
  float: left;
  background-size: 100% 100%;
}

.pricerange-main .sechead h3,
.pricerange-main .sechead p {
  color: #fff;
}

.pricerange-main .pricingbox {
  width: 100%;
  float: left;
}

.pricerange-main .pricingbox li {
  float: left;
  text-align: center;
  width: 33.33%;
}


/*.pricerange-main .pricingbox li+li{ margin-left: 20px; }*/

.pricerange-main .pricingbox li h3 {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.pricerange-main .pricingbox .iconsbx {
  background: #fff;
  width: 98px;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid transparent;
  margin: auto;
}

.pricerange-main .pricingbox .iconsbx i.icon1 {
  background-position: -17px -614px;
  width: 53px;
  height: 53px;
}

.pricerange-main .pricingbox .iconsbx i.icon2 {
  background-position: -86px -617px;
  width: 55px;
  height: 52px;
}

.pricerange-main .pricingbox .iconsbx i.icon3 {
  background-position: -165px -609px;
  width: 54px;
  height: 54px;
}

.pricerange-main .pricingbox .iconsbx i.icon4 {
  background-position: -237px -607px;
  width: 57px;
  height: 57px;
}

.pricerange-main .pricingbox .iconsbx i.icon5 {
  background-position: -318px -615px;
  width: 55px;
  height: 48px;
}

.pricerange-main .pricingbox .iconsbx i.icon6 {
  background-position: -397px -615px;
  width: 58px;
  height: 52px;
}

.pricerange-main .pricingbox li * {
  transition: all 0.3s ease-out;
}

.pricerange-main .pricingbox li a {
  display: inline-block;
}


/*.pricerange-main .pricingbox li:hover a,
.pricerange-main .pricingbox li.active a{  transform: scale(1.1);}*/

.pricerange-main .pricingbox li:hover .iconsbx,
.pricerange-main .pricingbox li.active .iconsbx {
  box-shadow: 1px 1px 20px 3px #00000054;
  border-color: #dc1e4f;
}


/*.pricerange-main .pricingbox li:hover h3{ transform: scale(1.1); border-color: #dc1e4f; }*/

.pricerange-main .video-duration {
  text-align: center;
  margin-top: 35px;
}

.pricerange-main .video-duration h6 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.pricerange-main .video-duration span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.pricerange-main .video-qty {
  padding: 25px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 25px 5px #0000002b;
  text-align: center;
}

.pricerange-main .video-qty #videoqty {
  background: #fff;
  width: 90px;
  margin: 0 10px 0 0;
  padding: 7px 15px;
  border-radius: 30px;
  border: 1px solid #ccc;
  color: #242424;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.pricerange-main .video-qty h6,
.pricerange-main .video-qty label {
  font-size: 18px;
  font-weight: 600;
  color: #242424;
  margin-bottom: 20px;
}

.pricerange-main .video-qty label {
  font-size: 14px;
}

.mainpriceng-count {
  margin-top: 40px;
  position: relative;
}

.animation-pricing {
  padding: 38px 10px;
  opacity: 1;
  text-align: center;
  border-top: 1px solid #fff;
  margin-top: 30px;
}

.animation-pricing .col-md-4+.col-md-4 {
  border-left: 1px solid #ccc;
}

.animation-pricing h6 {
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0 10px;
  color: #fff;
}

.animation-pricing h5 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 0;
}

.posini {
  position: initial;
}

.animation-pricing .costing-initial {
  text-decoration: line-through;
}

.btnorders-pricing {
  margin-top: 40px;
}

.btnorders-pricing .topnavs .livchat-divs {
  border-bottom: none;
  margin-top: 8px;
  padding-bottom: 0;
}

.btnorders-pricing .topnavs a {
  font-size: 22px;
}

.btnorders-prices {
  margin-top: 50px;
  display: block;
  text-align: center;
}

.btnorders-prices a {
  background: #fff;
  padding: 8px 58px;
  background: #fff;
  color: #ed3e25;
  font-size: 20px;
  border-radius: 30px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
  box-shadow: -1px 3px 20px -1px #00000069;
}

.btnorders-prices a:hover {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(226, 226, 226, 1) 50%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(226, 226, 226, 1) 50%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(226, 226, 226, 1) 50%, rgba(255, 255, 255, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}


/* .stickyheader{background: rgba(149, 0, 42, 0.93);position: fixed;padding-top: 0;}
.stickyheader .topnavs{ display: none; }
.stickyheader .logo{padding-top: 18px;}
.stickyheader .logo img{ width: 250px; } */

.mainvideo-dur {
  border: 1px solid #ffff;
  padding: 14px 20px;
  border-radius: 10px;
}

.mainpriceng-count .leftcont-ban a {
  margin: 50px auto auto;
}

.testimonials .col-md-6:nth-child(2n):before {
  content: '';
  border-left: 1px solid #ccc;
  position: absolute;
  left: 0px;
  top: 0;
  height: 100%;
}

#allport .row {
  display: flex;
  align-items: center;
}


/*.mainhome .tabsmainport .innertabcontents > .container{ display: none; }*/

.portfolio .bannermm {
  background-image: url(../images/portfolio-banner.jpg);
  padding: 250px 0 20px;
  background-position: center center;
}

.portfolio .portfolio-mmbx .leftcont-ban a {
  display: none;
}

.portfolio .testimonials {
  padding-top: 0;
}

.logodesign-p .bannermm {
  background-image: url(../images/portfoliomain-p.jpg) !important;
  padding: 150px 0 100px !important;
  background-position: center center !important;
  min-height:30vh !important;
}
.navbar-inverse{
  background-color: #000 !important;
  border-color: #080808 !important;
}

.packaging-p .bannermm {
  background-image: url(../images/packaging-banner.jpg) !important;
  padding: 250px 0 20px;
  background-position: center center;
}

.branding .bannermm {
  background-image: url(../images/brandingbanner.jpg) !important;
  padding: 150px 0 100px !important;
  min-height: 30vh;
  background-position: center center !important;
}

.bannermm {
  display: flex;
  align-items: center;
  padding: 0px !important;
}

.inner-pg .bannermm .leftcont-ban,
.inner-pages .bannermm .leftcont-ban {
  margin-top: 100px;
}

.inner-pg .bannermm h1,
.inner-pg .bannermm h1 span,
.inner-pages .bannermm h1,
.inner-pages .bannermm h1 span {
  font-size: 35px;
}

.inner-pg .bannermm h1 strong,
.inner-pages .bannermm h1 strong {
  font-size: 48px;
}

.dropdown .anim-drop .listdiscret ul li a {
  line-height: 16px;
}

.dropdown .anim-drop .listdiscret p {
  margin-bottom: 10px;
}

.broucherdesign-p .incred-expe .slick-dots {
  bottom: -50px;
}

.incred-expe .slides-mm {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.incred-expe .slides-mm .col-lg-6{
  padding: 0 ;
}

.testimo .testimonials .row.testi-main+.row.testi-main {
  padding-top: 0;
}

.mainhome .what-offer>div>ul>li div a {
  display: block !important;
}

.logodesign-p .what-offer>div>ul>li div {
  padding-left: 65px;
}

.leftcont-ban a.poundbtn {
  width: 330px;
  display: flex;
  align-items: center;
}

.leftcont-ban a.poundbtn:hover {
  width: 370px;
}

.web-design .leftcont-ban a.poundbtn,
.animation-main .leftcont-ban a.poundbtn {
  width: 265px;
}

.web-design .leftcont-ban a.poundbtn:hover,
.animation-main .leftcont-ban a.poundbtn:hover {
  width: 330px;
}

.det-packages .sechead h3 {
  font-size: 33px;
}

.det-packages .packagesbxs .col-md-3:nth-child(n+5) {
  display: none;
}

.mainpackages .det-packages .packagesbxs .col-md-3:nth-child(n+5) {
  display: block;
}


/*.mainpackages .det-packages .packagesbxs .boxpackages{ margin-top: 0; }*/

.web-design .portfoliomaindivs .portfolio-mmbx .col-md-4:nth-child(n+7) {
  display: none;
}


/*.testimo .portfoliomaindivs .col-md-6:nth-child(n+9){ display: none; }*/

.accordion-faq .inner ul.dotsstyl {
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 20px;
}

.accordion-faq .inner ul.dotsstyl li {
  font-size: 14px;
  color: #6a6a6a !important;
  list-style: disc;
}

.sitemap-main .bannermm {
  background-image: url(../images/sitemap-banner.jpg) !important;
  padding: 250px 0 20px;
  background-position: center center;
  min-height: 350px;
}

.sitemap-main .bannermm .leftcont-ban {
  margin-top: 150px;
}

.sitemap-main .bannermm .leftcont-ban h1 {
  border-bottom: none;
}

.sitemap-main .termcndtn {
  padding: 30px 0;
  width: 100%;
}

.sitemap-main .sechead {
  text-align: left;
}

.martop15 {
  margin-top: 15px !important;
}

.animation .toptabs1 li {
  width: 240px;
  margin-bottom: 20px;
}

.enterprices-bundles .totalpackagespricess h4 b {
  position: relative;
}

.enterprices-bundles .totalpackagespricess h4 b:before {
  content: '';
  background: #000;
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  top: 19px;
  margin: auto;
  left: 0;
  right: 0;
  transform: rotate(-13deg);
  z-index: 2;
}

.mainpackages .toptabs1 li {
  width: 148px;
}

.inner-pg .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9),
.mainhome .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9),
.mainhome .portfoliomaindivs .innertabcontents .row .col-md-4:nth-child(n+7) {
  display: none;
}

.portfolio .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9) {
  display: block;
}

.packagesbranding .innertabcontents {
  display: none;
}

.packagesbranding .innertabcontents.active {
  display: block;
}

.det-packages .portfoliomaindivs .tabschildrens {
  background: transparent;
}

.animation.innerpackages-p .packagesbxs {
  margin-top: 20px;
}

.mainhome .bannermm .leftcont-ban a {
  width: 506px;
}

.mainhome .bannermm .leftcont-ban a:hover {
  width: 522px;
}

.contact-form-main .red-bg {
  min-height: 386px;
}

.contact-form-main .red-bg p a {
  color: #fff !important;
}

.mainhome .bannermm.webbanner .leftcont-ban a {
  width: 482px;
}

.mainhome .bannermm.webbanner .leftcont-ban a:hover {
  width: 498px;
}

.mainhome .bannermm.animbanner .leftcont-ban a {
  width: 548px;
}

.mainhome .bannermm.animbanner .leftcont-ban a:hover {
  width: 565px;
}

.portfoliomaindivs #logoport .tabschildrens ul li:nth-child(11n):before {
  display: none;
}

.fixedform .barforms {
  transition: all 0.3s ease-out;
}

.fixedform.fixed-forms-main .barforms {
  opacity: 1;
  left: -62px;
}
.testimonials .testi-a a {
  display: none;
}

.mainhome .incred-expe .slick-dots {
  bottom: -10px;
}

.incred-expe {
  padding: 50px 0 40px;
}

#alltest .testi-a a {
  display: block;
}

.padright0 {
  padding-right: 0;
}

.maincta .callsdivs {
  text-align: right;
  font-size: 21px;
  padding: 25px 0 0px 0;
}

.maincta p {
  font-size: 16px;
}


/* .maincta .callsdivs a{ padding: 0 10px 0 0; } */

.maincta .callsdivs a+a {
  border-left: 1px solid #fff;
  padding: 0 0px 0 15px;
}

.maincta .callsdivs .iconphone {
  transform: scale(0.8);
  margin-top: -4px;
}

.liveChat.separator {
  position: relative;
}

.liveChat.separator::before {
  content: '';
  position: absolute;
  border-left: 1px solid #ffffff;
  height: 85%;
  left: -10px;
}

.packages-wrapper .nav-tabs li:nth-child(5) {
  order: -1;
}

.packages-wrapper .nav-tabs li:nth-child(1) {
  order: -1;
}

.col-md-12.trustpilot-class.d-none {
  display: none;
}
.blog-detail {
  padding: 40px 0;
}
.content-block {
  display: flex;
  margin-bottom: 5rem;
}
/* .content-block, .content-block::before {
  height: 100%;
  display: block;
  background: #fff;
}
.content-block::before {
  content: " ";
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 50px rgb(166 209 237 / 20%);
  box-shadow: 0 10px 50px rgb(166 209 237 / 20%);
  z-index: -2;
} */
.img-container {
  max-width: 100%;
  position: relative;
}

.content-block img {
  width: 500px;
  object-fit: cover;
  height: 100%;
}
.img-container:before {
  position: absolute;
  content: "";
  background: #00000078;
  width: 100%;
  height: 100%;
}
.img-container .image-overlay-content {
  padding: 0 5px 5px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.image-overlay-content .image-content {
  padding: 0 0 3px 10px;
  -webkit-text-fill-color: unset;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #fff, rgba(0, 0, 0, 0)) 1 0;
  border-top: 0;
}
.image-content-box img.content-img {
  width: 110px;
  margin: 0 0 0 auto;
}
.blog-grid .content-block h5 {
  color: #100f0f;
  text-align: left;
  font-size: 2.237rem;
  font-weight: 700;
  font-family: Poppins,sans-serif;
}
.content-block__sub-title {
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #69798d;
  font-weight: 300;
  margin-bottom: 8px;
  display: none;
}
.arti-bx h1,
.arti-bx h2,
.arti-bx h3,
.arti-bx h4,
.arti-bx h5,
.arti-bx h6 {
  font-size: 1.25rem;
  margin: 20px 0;
}
.blog-bnr {
  position: absolute;
  height: 100%;
}
.blg-txt h1 {
  color: #fff;
  font-size: 66px;
  margin-bottom: 15px;
}
.blg-txt p {
  color: #fff;
  margin-bottom: 30px;
}


/* /blog css start */

.main-artcl {
  margin-top: 60px;
}

.article {
  margin-bottom: 116px;
}

.art-lft p {
  color: #fff;
  display: inherit;
  /* max-width: 206px; */
  font-size: 14px;
}

.articl-dtail {
  position: absolute;
  top: 0;
  background: #0000005c;
  width: 98%;
  height: 381px;
  padding: 0 0 0 20px;
  display: flex;
  align-items: end;
  gap: 30px;
}

h4.arthr-name {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  display: inline-block;
}

.article img {}

.art-right {
  margin-left: 93px;
}

.art-right p {
  color: #fff;
  font-size: 16px;
}


/* /blog css End */

@media only screen and (min-width :1100px) and (max-width :1550px) {
  .blink-bx {
      right: -200px;
  }
  .bannermm {
      min-height: 480px;
  }
  .mainhome .bannermm .leftcont-ban h1 {
      font-size: 30px;
  }
  .mainhome .bannermm .leftcont-ban p {
      font-size: 20px;
  }
  .mainhome .bannermm .leftcont-ban h1 strong {
      font-size: 41px;
  }
  .inner-pg .inner-pg .bannermm h1 strong {
      font-size: 42px;
  }
  .inner-pg .bannermm .leftcont-ban h1 {
      font-size: 30px;
  }
  .inner-pg .bannermm h1,
  .inner-pg .bannermm h1 span {
      font-size: 30px;
  }
}

.portfolio-mmbx .box-ports img {
  width: 100%;
  border: 1px solid #e2dfdf;
}
.portfolio-mmbx {
  margin-top: 10px;
  position: relative;
}
.hovereffects-det {
  background: rgba(255, 0, 66, 0.9);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
}
.portfolio-mmbx .box-ports:hover .hovereffects-det {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -o-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}
.portfolio-mmbx .box-ports:hover {
  transform: scale(1.1);
  z-index: 2;
}
.hovereffects-det h3 {
  color: #fff;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
.btnstyles-nn a {
  border: 2px solid #fff;
  color: #fff;
  border-radius: 20px;
  padding: 5px 30px;
  font-size: 18px;
  font-weight: 500;
  margin: 5px 5px;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
}
.btnstyles-nn a:focus {
  color: #ffffff;
}
.btnstyles-nn a i {
  margin: 0 5px;
}
.portfolio-mmbx .leftcont-ban {
  text-align: center;
}
.mainhome .leftcont-ban a{
    display: flex !important;
    align-items: center !important;
}
.mainhome .leftcont-ban a i{
  display: block;
}
.mainhome .leftcont-ban a i.btnarow {
  width: 45px;
}
.portfolio-mmbx .leftcont-ban a {
  border-color: #ed3e25;
  color: #ed3e25;
  text-align: left;
  transition: width 0.3s linear, color 0.3s ease-in-out !important;
  margin: auto;
  font-weight: 500;
  width: 292px !important;
  text-align: center;
}

.portfolio-mmbx .leftcont-ban a:hover {
  border-color: #ed3e25 !important;
  background-color: #ed3e25;
  color: #fff;
  width: 300px;
  display: flex;
  align-items: center;
}
.portfolio-mmbx .leftcont-ban a:hover i {
  background-position: -3px -3px;
}
.det-packages {
  padding: 30px 0 40px;
  width: 100%;
  float: left;
  background-color: #f1f1f1;
}
.det-packages .boxpackages {
  background: #fff;
  border-radius: 15px;
  padding: 20px 20px 30px;
  margin: 20px 0;
  float: left;
  width: 100%;
  box-shadow: -2px 10px 40px -10px #00000059;
  position: relative;
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
}
.det-packages .boxpackages .packheads {
  border-bottom: 1px solid #d1d7e3;
}
.det-packages .boxpackages .packheads h3 {
  font-size: 21px;
  color: #111111;
  font-weight: 700;
  margin: 0px 0 5px;
}
.det-packages .boxpackages .packheads p {
  font-size: 13px;
  color: #242424;
  min-height: 37px;
}
.det-packages .boxpackages:hover {
  border: 1px solid #ed3e25;
}
.det-packages .boxpackages:hover .listpacks ul li:before {
  color: #ed3e25;
}
.det-packages .boxpackages:hover a.btn-orders {
  background: #ed3e25;
  color: #fff;
}
.packtitles {
  display: flex;
  justify-content: space-between;
}
.packtitles .fleft {
  float: none;
}
.packtitles .fright {
  float: none;
}
.packagesbxs .responsive-slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.packages-wrapper .nav {
  margin-bottom: 0;
  text-align: center;
  margin: 50px 0 30px;
  display: flex;
  justify-content: center;
  border-bottom: none;
}
.packages-wrapper .nav li.active a {
  background: #ed3e25;
  color: #fff;
  border: 1px solid transparent;
}
.packages-wrapper .nav li a {
  border: 1px solid #a8a8a8;
  border-radius: 30px;
  padding: 7px 15px;
  text-align: center;
  display: block;
  font-size: 14px;
  color: #ed3e25;
  transition: all 0.3s ease-out;
  cursor: pointer;
  margin: 0px 6px;
}

/* width */
.packages-wrapper ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.packages-wrapper ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.packages-wrapper ::-webkit-scrollbar-thumb {
  background: #ed3e25; 
  border-radius: 10px;
}

/* Handle on hover */
.packages-wrapper ::-webkit-scrollbar-thumb:hover {
  background: #cf0101;
}

.packages-wrapper .nav li:hover a {
  background: #ed3e25;
  color: #fff;
}
.boxpackages .packdetails {
  padding: 10px 0;
}
.boxpackages .packdetails .packtitles .fleft h3 {
  font-size: 54px;
  color: #ed3e25;
  font-weight: 700;
  margin: 0;
}
.enterprices-bundles{
  background-image: url(../images/startup-bundle.png);
  background-position: center;
  background-size: cover;

}
.btn-primary{
  color: #fff;
  background-color: none !important;
  border-color: none !important;
}
.boxpackages .packdetails .packtitles .fleft h3 sub {
  font-size: 18px;
  font-weight: 600;
  color: #242424;
  bottom: 0.05em;
  margin-left: 5px;
  display: none
}
.packdetails .packtitles .fright h4 {
  font-size: 18px;
  color: #c4c4c4;
  font-weight: 600;
}
.packdetails .packtitles .fright h4 b {
  display: block;
  color: #242424;
  font-weight: 600;
}
.det-packages .responsive-slider .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.packdetails .packtitles .fright h4 span.cutprice {
  position: relative;
  top: .5em;
  color: #c4c4c4;
}
.packdetails .packtitles .fright h4 b {
  /*border-bottom:3px solid #ed3e25;*/
  
  position: relative;
  /* top:-.5em;*/
}
.packdetails .packtitles .fright h4 b:before {
  content: '';
  background: #ed3e25;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: 19px;
  margin: auto;
  left: 0;
  right: 0;
  transform: rotate(-12deg);
  z-index: 2;
}
.packdetails .listpacks ul {
  display: block;
  width: 100%;
  float: left;
  padding: 15px 0;
  margin: 0;
  height: 250px;
  border-bottom: 1px solid #d1d7e3;
}
.packdetails .listpacks ul li {
  position: relative;
  padding: 5px 25px;
  font-size: 14px;
  color: #455164;
}
.packdetails .listpacks ul li:after,
.pacbundles-det .headbx-bundle ul.listbundles-packbx li:after {
  content: '';
  right: 0px;
  height: 1px;
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#e0e3eb), to(transparent));
  background-image: -webkit-linear-gradient(left, #e0e3eb, transparent);
  background-image: -moz-linear-gradient(left, #000, transparent);
  background-image: -o-linear-gradient(left, #000, transparent);
  display: block;
  position: absolute;
  bottom: 0;
  left: 25px;
}
.packdetails .listpacks ul li ul li {
  padding: 0;
  color: #fff;
  font-size: 13px;
}
.packdetails .listpacks ul li ul li:before {
  display: none;
}
.packdetails .listpacks ul li.last:after {
  display: none;
}
.packdetails .listpacks ul li:before {
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 0;
  top: 7px;
  color: #b1b9cc;
}
.packdetails .listpacks ul li.haschildren .child-tooltip {
  position: absolute;
  right: 0;
  top: 7px;
  color: #ed3e25;
}
.packdetails .listpacks ul li.haschildren .child-tooltip:before {
  content: "\f05a";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
}
.packdetails .listpacks ul li.haschildren ul {
  display: none;
  position: absolute;
  border-radius: 4px;
  opacity: 0.8;
  right: 0px;
  color: #fff;
  width: 220px;
  background: #000;
  padding: 10px 10px;
  overflow: hidden;
  z-index: 9;
}
.packdetails .botarea-pack {
  padding: 15px 0 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.packdetails .botarea-pack a.btn-orders {
  border: 1px solid #ed3e25;
  border-radius: 30px;
  padding: 4px 12px;
  color: #ed3e25;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  transition: all 0.3s ease-out;
  margin: 5px 0 0
}
.packdetails .botarea-pack .liovechats-bx {
  padding-left: 20px;
}
.packdetails .botarea-pack .liovechats-bx a span {
  font-size: 10px;
  color: #242424;
  position: relative;
}
.packdetails .botarea-pack .liovechats-bx a span:before {
  content: "\f27b";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  right: -25px;
  top: -2px;
  color: #ed3e25;
  font-size: 16px;
}
.packdetails .botarea-pack .liovechats-bx a h5 {
  font-weight: 700;
  color: #111111;
  font-size: 16px;
  margin: 0;
}

/*.det-packages .col-md-3.always-active.active .boxpackages,*/
.det-packages .boxpackages:hover {
  transform: scale(1.1);
  border: 1px solid #ed3e25;
}
.det-packages .boxpackages.active {
  border: 1px solid #ed3e25;
}

/* .det-packages .boxpackages.active .packtitles .fleft h3{ font-size: 60px; } */
.det-packages .boxpackages:hover .packdetails .listpacks ul li:before {
  color: #ed3e25;
}
.det-packages .boxpackages:hover .botarea-pack a.btn-orders {
  background: #ed3e25;
  color: #fff;
}
.det-packages .boxpackages.active .botarea-pack a.btn-orders:hover {
  background: #fff;
  color: #ed3e25;
}
.det-packages .toptabs1 li a {
  border: 1px solid #a8a8a8;
}
.det-packages .boxpackages.active:before {
  content: '';
  position: absolute;
  right: -6px;
  background: url("../images/sprites.png") no-repeat;
  background-position: -897px -452px;
  width: 95px;
  height: 95px;
  top: -6px;
  z-index: 2;
}
.btnredstyl {
  text-align: center;
  width: 100%;
  float: left;
  padding: 20px 0;
}
.btnredstyl a {
  display: inline-block;
  padding: 8px 30px;
  color: #ed3e25;
  border: 1px solid #ed3e25;
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
}
.btnredstyl a i {
  margin-left: 5px;
}
.btnredstyl a:hover {
  background: #fff;
}
.enterprices-bundles {
  width: 100%;
  float: left;
  padding: 30px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.enterprices-bundles h3,
.enterprices-bundles p {
  color: #fff;
  position: relative;
}
.enterprices-bundles h3 {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.enterprices-bundles h3:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  width: 192px;
  height: 4px;
  bottom: 0;
}
.enterprices-bundles .sechead {
  margin-bottom: 30px;
}
.pacbundles-det .headbx-bundle .fleft {
  width: 100%;
}
.pacbundles-det .headbx-bundle .fleft h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0px 0 0;
  float: left;
  width: 143px;
}
.pacbundles-det .headbx-bundle .packbundleprice {
  /* float: left; */
  
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  line-height: 20px;
  padding: 0 0 0 10px;
  position: relative;
  display: inline-block;
  float: right;
}
.pacbundles-det .headbx-bundle .packbundleprice span {
  font-size: 22px;
  font-weight: 700;
  display: block;
}
.pacbundles-det .headbx-bundle .packbundleprice:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 20px;
  background: #fff;
}
.pacbundles-det .headbx-bundle {}
.pacbundles-det .headbx-bundle ul.listbundles-packbx {
  display: block;
  width: 100%;
  float: left;
  padding: 15px 0;
  margin: 0;
}
.pacbundles-det .headbx-bundle ul.listbundles-packbx>li {
  position: relative;
  padding: 5px 25px;
  font-size: 14px;
  color: #fff;
}
.pacbundles-det .headbx-bundle ul.listbundles-packbx>li.last {
  border-bottom: none;
}
.pacbundles-det .headbx-bundle ul.listbundles-packbx>li:before {
  content: "\f00c";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 0;
  top: 7px;
  color: #fff;
}
.pacbundles-det .headbx-bundle li.haschildren .child-tooltip {
  position: absolute;
  right: 20px;
  top: 7px;
  color: #fff;
}
.pacbundles-det .headbx-bundle li.haschildren .child-tooltip:before {
  content: "\f05a";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
}
.pacbundles-det .headbx-bundle li.haschildren ul {
  display: none;
  position: absolute;
  border-radius: 4px;
  opacity: 0.9;
  right: 20px;
  color: #fff;
  width: 200px;
  background: #000;
  padding: 10px 10px;
  overflow: hidden;
  z-index: 9;
  text-align: center;
}
.pacbundles-det .headbx-bundle li.haschildren ul li {
  font-size: 13px;
}
.enterprices-bundles .totalpackagespricess {
  border-top: 1px solid #fff;
  margin-top: 20px;
  padding: 10px 0;
}
.enterprices-bundles .totalpackagespricess h4 {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
}
.enterprices-bundles .totalpackagespricess .underline-txt {
  text-decoration: line-through;
  margin-left: 5px;
}
.enterprices-bundles .totalpackagespricess .yellcol {
  color: #f6ff00;
  font-size: 40px;
  font-weight: 700;
  margin-left: 5px;
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles {
  float: right;
  margin-top: 8px;
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles a {
  background: #fff;
  padding: 8px 28px;
  background: #fff;
  color: #ed3e25;
  font-size: 20px;
  border-radius: 30px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
  box-shadow: -1px 3px 20px -1px #00000069;
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles a13 {
  position: relative;
  overflow: hidden;
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles a13:after {
  position: absolute;
  z-index: 2;
  background-color: #ed3e25;
  width: 0%;
  height: 100%;
  content: " ";
  left: 0px;
  top: 0px;
  -webkit-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition: all 222ms cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
  
  -webkit-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  -o-transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  transition-timing-function: cubic-bezier(0.250, 0.250, 0.750, 0.750);
  /* linear */
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles a31:hover:after {
  height: 100%;
  width: 100%;
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles a:hover {
  -webkit-box-shadow: inset 0px 0px 35px -20px rgba(255, 0, 66, 1);
  -moz-box-shadow: inset 0px 0px 35px -20px rgba(255, 0, 66, 1);
  box-shadow: inset 0px 0px 35px -20px rgba(255, 0, 66, 1);
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles a13:hover span {
  position: relative;
}
.enterprices-bundles .totalpackagespricess .btn-orders-bundles p {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}
.packagesall .portfolio-mmbx .leftcont-ban a:hover {
  width: 310px;
}
.packagesall .portfolio-mmbx .leftcont-ban a {
  width: 310px !important;
  text-align: center;
  transition: all 0.4s ease-in-out;
}
.tabsmainport .innertabcontents {
  display: none;
}
.tabsmainport .innertabcontents.active {
  display: block;
}
.fixedform {
  opacity: 0;
  position: fixed;
  top: 10%;
  right: -408px;
  margin: auto;
  /* background: #fff; */
  
  padding: 10px 0px 10px 0;
  z-index: 222;
  /*  padding-left: 62px;*/
  
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.fixedform .barforms {
  position: absolute;
  left: 0;
  top: 4px;
  background: url("../images/sprites.png") no-repeat;
  background-position: -827px -601px;
  width: 62px;
  height: 346px;
}
.fixedform .getdiscount {
  padding: 16px 20px;
  background: #fff;
  border-bottom-left-radius: 10px;
}
.fixedform .getdiscount h3 {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin: 0 0 20px;
  font-size: 21px;
  font-weight: 600;
  color: #111111;
}
.fixedform form {
  width: 100%;
}
.fixedform .mm-field {
  margin-bottom: 13px;
  width: 100%;
  position: relative;
  border: 1px solid #ebe9e9;
}
.fixedform .mm-field input {
  width: 100%;
}
.fixedform .mm-field input[type=text],
.fixedform .mm-field select,
.fixedform .mm-field input[type=email] {
  background-color: transparent !important;
  z-index: 10;
  position: relative;
  border: 1px solid #fff;
  padding: 10px 10px 10px 60px;
  width: 100%;
}
.fixedform .mm-field {
  position: relative;
  border-radius: 2px;
}
.fixedform .mm-field:before {
  content: '';
  background: url("../images/sprites.png") no-repeat;
  background-position: -558px -167px;
  width: 52px;
  height: 44px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
}
.fixedform .mm-field.pnfields:before {
  display: none;
}
.fixedform .mm-field.emailfield:before {
  background-position: -623px -167px;
  width: 52px;
  height: 44px;
}
.fixedform .mm-field.pnfields .intl-tel-input {
  width: 100%;
}
fieldset {
  position: absolute;
  top: 10px;
  left: 60px;
  z-index: 2;
  color: #666666;
  font-size: 15px;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease-in-out;
}
.intl-tel-input.allow-dropdown .flag-container,
.intl-tel-input.separate-dial-code .flag-container {
  z-index: 999;
}
.fixedform .intl-tel-input.allow-dropdown .flag-container .selected-flag {
  border-right: none;
}
.fixedform .intl-tel-input.allow-dropdown .flag-container .selected-flag {
  background: rgb(219, 219, 219);
}
.fixedform .mm-field.selectsertvice:before {
  background-position: -684px -166px;
  width: 52px;
  height: 44px;
}
.fixedform .mm-field.whbgnone {
  margin-bottom: 0;
}
.fixedform .mm-field.whbgnone:before {
  background-position: -725px -97px;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: initial;
}
.fixedform .mm-field.whbgnone button[type="submit"] {
  background: #ed3e25;
  border-radius: 30px;
  width: 100%;
  padding: 14px 35px;
  color: #fff;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  border: transparent;
}
.fixedform .mm-field fieldset.active {
  top: 0px;
  font-size: 10px;
  color: #000;
  padding: 1px 8px;
  z-index: 20;
  left: 52px;
  font-weight: 500;
}
.fixedform .mm-field.pnfields fieldset {
  left: 100px;
}
.fixedform .mm-field.pnfields fieldset.active {
  left: 92px;
}
.fixedform.active {
  right: 0;
}
.fixed-forms-main {
  opacity: 1;
}
.fixedform.active .barforms {
  background-position: -915px -601px;
}
header .main-nav>ul>li>.dropdown {
  display: none;
  visibility: hidden;
  opacity: 0;
}
header .main-nav>ul>li:hover>.dropdown {
  visibility: visible;
  opacity: 1;
  top: 96%;
  display: block;
}
header .main-nav ul li .dropdown {
  text-align: left;
  padding: 20px 20px;
  position: absolute;
  left: -10px;
  top: 130%;
  width: 720px;
  z-index: 999;
  border-radius: 20px;
  background: #f9f7fd;
  border: 2px solid #ed3e25;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}
.dropdown .lstnavig,
.dropdown .listdiscret,
.dropdown .listimgs {
  float: left;
  color: #242424;
  font-size: 14px;
}
.dropdown .lstnavig .liststylss {
  text-align: left;
  width: 208px;
}
.dropdown .lstnavig .liststylss>li {
  display: block;
  border-bottom: 1px solid #e7e6e9;
  position: initial;
}
.dropdown .lstnavig .liststylss>li>a {
  color: #242424;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 0;
  font-weight: 500;
}
.dropdown .lstnavig>.liststylss>li>a i {
  background-position: -14px -474px;
  width: 26px;
  height: 26px;
  vertical-align: middle;
  margin-right: 10px;
}
.dropdown .lstnavig .liststylss i.iconlogo-pack {
  background-position: -52px -474px;
  width: 24px;
  height: 24px;
}
.dropdown .lstnavig .liststylss i.iconlogo-port {
  background-position: -88px -473px;
  width: 25px;
  height: 25px;
}
.dropdown .lstnavig .liststylss i.iconlogo-review {
  background-position: -128px -474px;
  width: 25px;
  height: 25px;
}
.dropdown .lstnavig .liststylss li.last {
  border-bottom: none;
}
.dropdown .lstnavig .liststylss a {
  position: relative;
  transition: all 0.3s ease-out;
}
.dropdown .lstnavig .liststylss>li>a:after {
  content: "\f0da";
  font-family: fontAwesome;
  width: 24px;
  font-size: 18px;
  display: inline-block;
  padding-left: 4px;
  height: 10px;
  opacity: 0;
  transition: all 0.3s ease-out;
  color: #ed3e25;
  position: relative;
  top: 3px;
}
.dropdown .lstnavig .liststylss>li:hover a:after,
.dropdown .lstnavig .liststylss li.active a:after {
  opacity: 1;
}
.dropdown .lstnavig .liststylss>li:hover>a,
.dropdown .lstnavig .liststylss li.active>a {
  color: #ed3e25;
}
.dropdown .listdiscret {
  width: 68%;
  padding: 20px 20px;
}
.dropdown .listdiscret p {
  color: #242424;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 20px;
}
.dropdown .listdiscret ul {}
.dropdown .listdiscret ul li {
  width: 50%;
  float: left;
}
.dropdown .listdiscret ul li a:before {
  content: "\f0da";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  top: 8.5px;
  left: 0;
  color: #999999;
}
.dropdown .listdiscret ul li a {
  position: relative;
  display: block;
  color: #242424;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  padding: 6px 10px 6px 20px !important;
  font-weight: 500;
}
.dropdown .listimgs {
  float: right;
  border: 1px solid #cccccc;
  padding: 5px 5px;
  margin-top: 10px;
  width: 32%;
}
.dropdown .listdiscret>ul>li>a:hover {
  color: #ed3e25;
}
.no-src {
  cursor: default;
}
.no-src:hover {
  color: initial !important
}
.dropdown .lstnavig .liststylss li.active .innerdropdowns {
  opacity: 1;
  visibility: visible;
}
.dropdown .lstnavig .liststylss li .innerdropdowns {
  position: absolute;
  left: 220px;
  width: 475px;
  top: 8px;
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
}
.dropdown .listdiscret .btnstarts {
  border: 1px solid #ed3e25;
  font-size: 18px;
  color: #ed3e25;
  font-weight: 500;
  padding: 8px 30px;
  display: inline-block;
  border-radius: 30px;
  position: relative;
}
.dropdown .listdiscret .btnstarts:hover {
  border-color: #fff;
  background: #ed3e25;
  color: #fff
}
.dropdown .listdiscret .btnstarts.startspackfr {
  text-align: left;
  width: 220px;
  padding: 8px 30px 8px 20px;
  font-style: italic;
}
.dropdown .listdiscret .btnstarts.startspackfr span {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #ed3e25;
  border-radius: 100%;
  border: 1px solid #ed3e25;
  width: 60px;
  height: 60px;
  font-size: 22px;
  text-shadow: 1px 1px 1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-style: normal;
  transition-duration: 0.3s
}
.dropdown .listdiscret .btnstarts.startspackfr:hover span {
  background: #fff;
  color: #ed3e25;
  border-color: #ed3e25;
  text-shadow: none
}
.dropdown .listdiscret .btnstarts i {
  margin-left: 5px;
}
.formOverlaySide.active {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}
.stationery-p .bannermm {
  background-image: url(../images/stationery-logodesign.jpg) !important;
  padding: 210px 0 20px;
  background-position: center center;
}
.logopackages-p.stationery-p .bannermm {
  background-position: center center;
  padding: 210px 0 20px;
}
.bannerdesign-p.faqs-p .bannermm {
  background-image: url(../images/faqsbanner.jpg);
  padding: 210px 0 20px;
  background-position: center center;
}
.gettouchfaqs {
  padding: 50px 0;
  width: 100%;
  float: left;
}
.gettouchfaqs h3 {
  font-size: 36px;
  font-weight: 700;
  color: #111111;
}
.gettouchfaqs .packagesbxs {
  padding: 20px 0;
  display: block;
}
.gettouchfaqs .packagesbxs .toptabs1 {
  text-align: left;
}
.gettouchfaqs .packagesbxs .toptabs1 li {
  width: initial;
}
.gettouchfaqs .packagesbxs .toptabs1 a {
  padding: 8px 30px;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #e20031;
  color: #000000;
}
.gettouchfaqs .packagesbxs .toptabs1 li.active a,
.gettouchfaqs .packagesbxs .toptabs1 li:hover a {
  color: #fff;
}
.gettouchfaqs .accordion-faq {}
.accordion-faq .inner {
  padding: 1em;
  overflow: hidden;
  display: none;
  margin-bottom: 0;
  padding-right: 50px;
  margin-top: 0;
}
.accordion-faq .inner p {
  font-size: 14px;
  color: #6a6a6a;
  margin-bottom: 20px;
}
.accordion-faq .inner p a {
  color: #fff;
  text-decoration: underline;
}
.inner.show {
  /*display: block;*/
}
.accordion-faq .inner ul {
  margin-bottom: 0;
  margin-top: 10px;
}
.accordion-faq .inner ul li {
  color: #fff;
  font-size: 15px;
}
.accordion-faq>li {
  margin: 0px 0px 0px;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  float: left;
  position: relative;
}
.accordion-faq a.accord-tab {
  width: 100%;
  display: block;
  color: #111111;
  border-radius: 0.15em;
  transition: color 0.3s ease;
  position: relative;
  padding: 30px 70px 0px 20px;
  font-weight: 700;
  min-height: 70px;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
}
.accordion-faq>li.actives:after {
  color: #000;
  content: "\f107";
}
.accordion-faq>li>a {
  padding: 0.75em;
  color: #333333 !important;
  font-size: 20px;
}
.accordion-faq>li:after {
  content: "\f105";
  font-family: FontAwesome;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #000000;
  display: flex;
  align-items: center;
}
.accordion-faq>li>a:hover,
.accordion-faq>li>a.actives {
  /* color: #ed2530 !important; */
}
.accordion-faq a.accord-tab:hover,
.accordion-faq a.accord-tab.actives {
  color: #333333;
}
.accordion-faq li ul.dbltabs {
  background-color: transparent;
}
.accordion-faq li ul>li>a.accord-tab {
  padding: 15px 15px;
  font-size: 16px;
  font-weight: 600;
  min-height: initial;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  margin-bottom: 10px;
}
.accordion-faq>li.actives {
  background: #f4f4f4;
  border: 1px solid #fad2db;
}
.accordion-faq>li.actives a.accord-tab {
  min-height: initial;
}
.logodesignport-p.portfolio .bannermm {
  background-image: url(../images/logodesignportfolio-banner.jpg);
  padding: 250px 0 20px;
  background-position: center center;
}
.branding-portfolio-p.portfolio .bannermm {
  background-image: url(../images/branding-portfolio-bann.jpg);
  padding: 250px 0 20px;
  background-position: center center;
}

/* audio, canvas, progress, video {
width: 100%;
} */
.pricerange-main {
  padding: 50px 0;
  width: 100%;
  float: left;
  background-size: 100% 100%;
}
.pricerange-main .sechead h3,
.pricerange-main .sechead p {
  color: #fff;
}
.pricerange-main .pricingbox {
  width: 100%;
  float: left;
}
.pricerange-main .pricingbox li {
  float: left;
  text-align: center;
  width: 33.33%;
}

/*.pricerange-main .pricingbox li+li{ margin-left: 20px; }*/
.pricerange-main .pricingbox li h3 {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 20px;
}
.pricerange-main .pricingbox .iconsbx {
  background: #fff;
  width: 98px;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid transparent;
  margin: auto;
}
.pricerange-main .pricingbox .iconsbx i.icon1 {
  background-position: -17px -614px;
  width: 53px;
  height: 53px;
}
.pricerange-main .pricingbox .iconsbx i.icon2 {
  background-position: -86px -617px;
  width: 55px;
  height: 52px;
}
.pricerange-main .pricingbox .iconsbx i.icon3 {
  background-position: -165px -609px;
  width: 54px;
  height: 54px;
}
.pricerange-main .pricingbox .iconsbx i.icon4 {
  background-position: -237px -607px;
  width: 57px;
  height: 57px;
}
.pricerange-main .pricingbox .iconsbx i.icon5 {
  background-position: -318px -615px;
  width: 55px;
  height: 48px;
}
.what-offer{
  background-image: url(../images/whatoffer-bg.jpg) !important;
}
.pricerange-main .pricingbox .iconsbx i.icon6 {
  background-position: -397px -615px;
  width: 58px;
  height: 52px;
}
.pricerange-main .pricingbox li * {
  transition: all 0.3s ease-out;
}
.pricerange-main .pricingbox li a {
  display: inline-block;
}

/*.pricerange-main .pricingbox li:hover a,
.pricerange-main .pricingbox li.active a{  transform: scale(1.1);}*/
.pricerange-main .pricingbox li:hover .iconsbx,
.pricerange-main .pricingbox li.active .iconsbx {
  box-shadow: 1px 1px 20px 3px #00000054;
  border-color: #dc1e4f;
}

/*.pricerange-main .pricingbox li:hover h3{ transform: scale(1.1); border-color: #dc1e4f; }*/
.pricerange-main .video-duration {
  text-align: center;
  margin-top: 35px;
}
.pricerange-main .video-duration h6 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.pricerange-main .video-duration span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.pricerange-main .video-qty {
  padding: 25px 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 25px 5px #0000002b;
  text-align: center;
}
.pricerange-main .video-qty #videoqty {
  background: #fff;
  width: 90px;
  margin: 0 10px 0 0;
  padding: 7px 15px;
  border-radius: 30px;
  border: 1px solid #ccc;
  color: #242424;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}
.pricerange-main .video-qty h6,
.pricerange-main .video-qty label {
  font-size: 18px;
  font-weight: 600;
  color: #242424;
  margin-bottom: 20px;
}
.pricerange-main .video-qty label {
  font-size: 14px;
}
.mainpriceng-count {
  margin-top: 40px;
  position: relative;
}
.animation-pricing {
  padding: 38px 10px;
  opacity: 1;
  text-align: center;
  border-top: 1px solid #fff;
  margin-top: 30px;
}
.animation-pricing .col-md-4+.col-md-4 {
  border-left: 1px solid #ccc;
}
.animation-pricing h6 {
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0 10px;
  color: #fff;
}
.animation-pricing h5 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.posini {
  position: initial;
}
.animation-pricing .costing-initial {
  text-decoration: line-through;
}
.btnorders-pricing {
  margin-top: 40px;
}
.btnorders-pricing .topnavs .livchat-divs {
  border-bottom: none;
  margin-top: 8px;
  padding-bottom: 0;
}
.btnorders-pricing .topnavs a {
  font-size: 22px;
}
.btnorders-prices {
  margin-top: 50px;
  display: block;
  text-align: center;
}
.btnorders-prices a {
  background: #fff;
  padding: 8px 58px;
  background: #fff;
  color: #ed3e25;
  font-size: 20px;
  border-radius: 30px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 5px;
  box-shadow: -1px 3px 20px -1px #00000069;
}
.btnorders-prices a:hover {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(226, 226, 226, 1) 50%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(226, 226, 226, 1) 50%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(226, 226, 226, 1) 50%, rgba(255, 255, 255, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

/* .stickyheader{background: rgba(149, 0, 42, 0.93);position: fixed;padding-top: 0;}
.stickyheader .topnavs{ display: none; }
.stickyheader .logo{padding-top: 18px;}
.stickyheader .logo img{ width: 250px; } */
.mainvideo-dur {
  border: 1px solid #ffff;
  padding: 14px 20px;
  border-radius: 10px;
}
.mainpriceng-count .leftcont-ban a {
  margin: 50px auto auto;
}
.testimonials .col-md-6:nth-child(2n):before {
  content: '';
  border-left: 1px solid #ccc;
  position: absolute;
  left: 0px;
  top: 0;
  height: 100%;
}
#allport .row {
  display: flex;
  align-items: center;
}

/*.mainhome .tabsmainport .innertabcontents > .container{ display: none; }*/
.portfolio .bannermm {
  background-image: url(../images/portfolio-banner.jpg) !important;
  padding: 200px 0 100px !important;
  background-position: center center !important;
  min-height: 30vh !important;
}
.portfolio .portfolio-mmbx .leftcont-ban a {
  display: none;
}
.portfolio .testimonials {
  padding-top: 0;
}
.logodesign-p .bannermm {
  background-image: url(../images/portfoliomain-p.jpg) !important;
  padding: 250px 0 20px;
  background-position: center center;
}
.packaging-p .bannermm {
  background-image: url(../images/packaging-banner.jpg) !important;
  padding: 250px 0 20px;
  background-position: center center;
}
.branding .bannermm {
  background-image: url(../images/brandingbanner.jpg) !important;
  padding: 250px 0 20px;
  background-position: center center;
}
.bannermm {
  display: flex;
  align-items: center;
  padding: 0px !important;
}
.inner-pg .bannermm .leftcont-ban,
.inner-pages .bannermm .leftcont-ban {
  margin-top: 100px;
}
.inner-pg .bannermm h1,
.inner-pg .bannermm h1 span,
.inner-pages .bannermm h1,
.inner-pages .bannermm h1 span {
  font-size: 35px;
}
.inner-pg .bannermm h1 strong,
.inner-pages .bannermm h1 strong {
  font-size: 48px;
}
.dropdown .anim-drop .listdiscret ul li a {
  line-height: 16px;
}
.dropdown .anim-drop .listdiscret p {
  margin-bottom: 10px;
}
.broucherdesign-p .incred-expe .slick-dots {
  bottom: -50px;
}
.incred-expe .slides-mm {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.testimo .testimonials .row.testi-main+.row.testi-main {
  padding-top: 0;
}
.mainhome .what-offer>div>ul>li div a {
  display: block !important;
}
.logodesign-p .what-offer>div>ul>li div {
  padding-left: 65px;
}
.leftcont-ban a.poundbtn {
  width: 370px;
  text-align: center;
}
.leftcont-ban a.poundbtn:hover {
  width: 380px;
}
.web-design .leftcont-ban a.poundbtn,
.animation-main .leftcont-ban a.poundbtn {
  width: 265px;
}
.web-design .leftcont-ban a.poundbtn:hover,
.animation-main .leftcont-ban a.poundbtn:hover {
  width: 330px;
}
.det-packages .sechead h3 {
  font-size: 33px;
}
.det-packages .packagesbxs .col-md-3:nth-child(n+5) {
  display: none;
}
.mainpackages .det-packages .packagesbxs .col-md-3:nth-child(n+5) {
  display: block;
}

/*.mainpackages .det-packages .packagesbxs .boxpackages{ margin-top: 0; }*/
.web-design .portfoliomaindivs .portfolio-mmbx .col-md-4:nth-child(n+7) {
  display: none;
}

/*.testimo .portfoliomaindivs .col-md-6:nth-child(n+9){ display: none; }*/
.accordion-faq .inner ul.dotsstyl {
  margin-top: 0;
  margin-bottom: 15px;
  padding-left: 20px;
}
.accordion-faq .inner ul.dotsstyl li {
  font-size: 14px;
  color: #6a6a6a !important;
  list-style: disc;
}
.sitemap-main .bannermm {
  background-image: url(../images/sitemap-banner.jpg) !important;
  padding: 250px 0 20px;
  background-position: center center;
  min-height: 350px;
}
.sitemap-main .bannermm .leftcont-ban {
  margin-top: 150px;
}
.sitemap-main .bannermm .leftcont-ban h1 {
  border-bottom: none;
}
.sitemap-main .termcndtn {
  padding: 30px 0;
  width: 100%;
}
.sitemap-main .sechead {
  text-align: left;
}
.martop15 {
  margin-top: 15px !important;
}
.animation .toptabs1 li {
  width: 240px;
  margin-bottom: 20px;
}
.enterprices-bundles .totalpackagespricess h4 b {
  position: relative;
}
.enterprices-bundles .totalpackagespricess h4 b:before {
  content: '';
  background: #000;
  width: 100%;
  height: 4px;
  display: block;
  position: absolute;
  top: 19px;
  margin: auto;
  left: 0;
  right: 0;
  transform: rotate(-13deg);
  z-index: 2;
}
.mainpackages .toptabs1 li {
  width: 148px;
}
.inner-pg .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9),
.mainhome .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9),
.mainhome .portfoliomaindivs .innertabcontents .row .col-md-4:nth-child(n+7) {
  display: none;
}
.portfolio .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9) {
  display: block;
}
.packagesbranding .innertabcontents {
  display: none;
}
.packagesbranding .innertabcontents.active {
  display: block;
}
.det-packages .portfoliomaindivs .tabschildrens {
  background: transparent;
}
.animation.innerpackages-p .packagesbxs {
  margin-top: 20px;
}
.mainhome .bannermm .leftcont-ban a {
  width: 506px;
}
.mainhome .bannermm .leftcont-ban a:hover {
  width: 522px;
}
.contact-form-main .red-bg {
  min-height: 386px;
}
.contact-form-main .red-bg p a {
  color: #fff !important;
}
.mainhome .bannermm.webbanner .leftcont-ban a {
  width: 482px;
}
.mainhome .bannermm.webbanner .leftcont-ban a:hover {
  width: 498px;
}
.mainhome .bannermm.animbanner .leftcont-ban a {
  width: 548px;
}
.mainhome .bannermm.animbanner .leftcont-ban a:hover {
  width: 565px;
}
.portfoliomaindivs #logoport .tabschildrens ul li:nth-child(11n):before {
  display: none;
}
.fixedform .barforms {
  transition: all 0.3s ease-out;
}
.fixedform.fixed-forms-main .barforms {
  opacity: 1;
  left: -62px;
}
.fixedform {}
.fixedform.fixed-forms-main {}
.testimonials .testi-a a {
  display: none;
}
.mainhome .incred-expe .slick-dots {
  bottom: -10px;
}
.incred-expe {
  padding: 50px 0 40px;
}
#alltest .testi-a a {
  display: block;
}
.padright0 {
  padding-right: 0;
}
.maincta .callsdivs {
  text-align: right;
  font-size: 21px;
  padding: 25px 0 0px 0;
}
.maincta p {
  font-size: 16px;
}

/* .maincta .callsdivs a{ padding: 0 10px 0 0; } */
.maincta .callsdivs a+a {
  border-left: 1px solid #fff;
  padding: 0 0px 0 15px;
}
.maincta .callsdivs .iconphone {
  transform: scale(0.8);
  margin-top: -4px;
}
.liveChat.separator {
  position: relative;
}
.liveChat.separator::before {
  content: '';
  position: absolute;
  border-left: 1px solid #ffffff;
  height: 85%;
  left: -10px;
}
.packages-wrapper .nav-tabs li:nth-child(5) {
  order: -1;
}
.packages-wrapper .nav-tabs li:nth-child(1) {
  order: -1;
}
.col-md-12.trustpilot-class.d-none {
  display: none;
}

/* /blog css start */
.main-artcl {
  margin-top: 60px;
}
.article {
  margin-bottom: 116px;
}
.art-lft p {
  color: #fff;
  display: inherit;
  /* max-width: 206px; */
  
  font-size: 14px;
}
.articl-dtail {
  position: absolute;
  top: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(60, 70, 80, 0.7203256302521008) 50%, rgba(0, 0, 0, 1) 100%);
  width: 100%;
  height: 381px;
  padding: 0 0 0 20px;
  display: flex;
  align-items: end;
  gap: 0;
}
h4.arthr-name {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  display: inline-block;
}
.art-right {
  /* float: right; */
  
  margin-left: 30px;
}
.art-right p {
  color: #fff;
  font-size: 16px;
  width: 132px;
}
.art-imbx {
  width: 381px;
  display: block;
}
.art-imbx img {
  width: 100%;
  height: 380px;
}

/* /blog css End */
@media only screen and (min-width :1100px) and (max-width :1550px) {
  .blink-bx {
      right: -200px;
  }
  
  .bannermm {
      min-height: 480px;
  }
  
  .mainhome .bannermm .leftcont-ban h1 {
      font-size: 30px;
  }
  
  .mainhome .bannermm .leftcont-ban p {
      font-size: 20px;
  }
  
  .mainhome .bannermm .leftcont-ban h1 strong {
      font-size: 41px;
  }
  
  .inner-pg .inner-pg .bannermm h1 strong {
      font-size: 42px;
  }
  
  .inner-pg .bannermm .leftcont-ban h1 {
      font-size: 30px;
  }
  
  .inner-pg .bannermm h1,
  .inner-pg .bannermm h1 span {
      font-size: 30px;
  }
}

/* shahrukh */
section.postReviws {
padding: 60px 0px;
/* background-color: #242424;
color: #fff; */
}
.testimonials{
background-color: #fff;

}
.reviewBtn{
background-color: #f80142;
border: 0;
}
.formfoot.d-flex {
display: flex;
justify-content: end;
}

#reviewResult {
text-align: center;
width: 100%;
}

#reviewResult span {
width: 100%;
display: inherit;
padding: 15px 0;
border-radius: 4px;
margin-top: 20px;
font-size: 16px;
}

form#frm textarea {
background-color: #eeeef3;
border: 1px solid;
}
.post-info img {
  border-radius: 5px;
  width: 100%;
  object-fit: cover;
}

.arti-bx p {
  color: #7c7c7c;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 1.4rem;
  font-family: Poppins,sans-serif;
}

.arti-bx .articl-titl {
  text-transform: capitalize;
  margin-bottom: 10px
}

.name-month a {
  color: #112846;
  margin-right: 20px;
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 600
}

.article-wrapper {
  padding: 0 0 60px
}

.art-im-bx img {
  object-fit: c;
  height: 100%;
  width: 100%
}
:has(.realtive-class,.blog-detail) header{
  position: relative;
}
.arti-bx h1, .arti-bx h2, .arti-bx h3, .arti-bx h4, .arti-bx h5, .arti-bx h6 {
  font-size: 2.25rem;
  margin: 20px 0;
  font-weight: 700;
  font-family: Poppins,sans-serif;
}
.top-slider{ margin: 2rem 0;  border: 10px solid #000; border-left: 0; border-right: 0;}
.title-p {
  text-align: left;
  margin-left: 3rem;
}

.title-p p {
  font-size: 1.6rem;
  margin-top: 2rem;
}
.title-p:has(p) :empty {
  background-color: tomato;
  display: none;
}
.title-p:has(p) br{
  display: none;
}
.slider-wraper .img-container {
  width: 30%;
  display: inline-block;
}
.slider-wraper  .content-block img{width: 100%; height: 100px;}
.slider-wraper .title-p{display: inline-block;
  width: 68%;
  margin: 0;
  padding-left: 3rem; }
  .slider-wraper .blog-grid .content-block h5 {font-size: 1.5rem;}
  .slider-wraper  .content-block{    margin: 2rem 0;     align-items: center;}

  .no-padlft {
    padding-left: 0;
  }
  
  .no-padrght {
    padding-right: 0;
  }
  
  footer {
    background: #242424;
    width: 100%;
    float: left;
    padding: 30px 0 0 0;
  }
  
  footer .container {
    padding-bottom:0;
  }
  
  footer .listpge {
    padding-bottom: 15px;
  }
  
  footer h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  
  footer ul {}
  
  footer ul li {
    position: relative;
    padding: 5px 0 5px 14px;
  }
  
  footer ul li::before {
    content: "\f105";
    position: absolute;
    font-family: fontAwesome;
    font-size: 11px;
    color: #888888;
    left: 0;
    top: 9px;
  }
  
  footer ul li a {
    color: #888888;
    font-size: 13px;
  }
  
  footer ul li a:hover,
  a:focus {
    color: #a2a1a1;
  }
  
  .footer-bottom {
    background: #181818;
    padding: 10px 0;
  }
  
  .footer-bottom h4 {
    color: #999999;
    font-size: 14px;
  }
  
  .footer-bottom p {
    color: #999999;
    font-size: 11px;
  }
  
  footer .padd-btd {
    padding: 35px 0;
  }
  footer .padd-btd img{
    max-width: 35%;
  }
  
  footer .social-icons.padd-btd {
    padding: 12px 30px;
    border-left: 1px solid #4a4a4a;
    float: right;
  }
  
  footer .footer-logo img {}
  
  .ulsme-ctct ul li a {
    margin-left: 7px;
  }
  
  .ulsme-ctct ul .phone-list::before {
    content: "\f095";
    color: #ed3e25;
    font-size: 12px;
  }
  
  .ulsme-ctct ul .email-list::before {
    content: "\f0e0";
    color: #ed3e25;
    font-size: 12px;
  }
  
  .ulsme-ctct ul .chat-list::before {
    content: "\f086";
    color: #ed3e25;
    font-size: 12px;
  }
  
  .ulsme-ctct ul .address-list::before {
    content: "\f124";
    color: #ed3e25;
    font-size: 12px;
  }
  
  footer .brdr-top {
    border-top: 1px solid #4a4a4a;
    padding: 30px 15px 30px 0;
    display: flex;
    align-items: center;
  }
  
  footer .brdr-top .social-icons h4 {
    color: #ffffff;
    font-size: 16px;
    margin: 0 0 10px 0;
    font-weight: 600;
  }
  
  footer .brdr-top .social-icons i {
    font-size: 22px;
    color: #888888;
    margin: 0 4px;
    border: 2px solid;
    border-radius: 100%;
    padding: 9px 11px 10px 11px;
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
  }
  
  footer .brdr-top .social-icons i:hover {
    color: #3b7dff;
  }
  
  footer .brdr-top .social-icons a+a i:hover {
    color: #f00;
  }
  
  footer .brdr-top .social-icons a+a+a i:hover {
    color: #0056ff;
  }
  
  footer .brdr-top .social-icons a+a+a+a i:hover {
    color: #0274b3;
  }
  
  footer .brdr-top .social-icons a+a+a+a+a i:hover {
    color: #55adec;
  }
  
  footer .brdr-top .subcribe h4 {
    color: #ffffff;
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }
  
  footer .brdr-top .subcribe input {
    margin-top: 15px;
    border-radius: 25px;
    padding: 15px 38px;
    border: 0;
    width: 100%;
    font-weight: 700;
    color: #242424;
    font-size: 13px;
  }
  
  footer .brdr-top .subcribe.padd-btd {
    padding: 12px 0;
  }
  
  footer .brdr-top .subcribe i {
    color: #ed3e25;
    position: absolute;
    top: 62px;
    left: 30px;
    font-weight: 600;
  }
  
  footer .brdr-top .subcribe button {
    color: #ffffff;
    background: #ed3e25;
    border: 0;
    position: absolute;
    right: 20px;
    bottom: 16px;
    padding: 10px 30px;
    border-radius: 34px;
  }
  
  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col-xs-5ths {
    width: 20%;
    float: left;
  }
  
  .t-right {
    text-align: right;
  }
  
  .t-center {
    text-align: center;
  }
  
  .testimonials {
    display: flex;
    width: 100%;
    padding: 60px 0;
  }
  
  .testimonials h3 {
    color: #111111;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .testimonials p {
    text-align: center;
    font-weight: 500;
  }
  
  .testimonials .testi .testi-img {
    min-height: 160px;
    position: relative;
  }
  
  .testimonials .testi .testi-img::before {
    content: '';
    background: url(../images/sprites.png) no-repeat;
    background-position: -212px -8px;
    width: 18px;
    height: 15px;
    display: inline-block;
    position: absolute;
    right: 0;
  }
  
  .testimonials .testi .testi-img img {
    float: left;
    margin-right: 25px;
    margin-bottom: 25px;
  }
  
  .testimonials .testi .testi-img h4 {
    font-size: 18px;
    color: #ed3e25;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .testimonials .testi .testi-img p {
    text-align: left;
    font-size: 13px;
    color: #242424;
  }
  
  .testimonials .testi .testi-desc {
    border-top: 1px solid #d0d0d0;
    padding: 10px 0;
    width: 100%;
    float: left;
  }
  
  .testimonials .testi .testi-desc ul li {
    float: left;
    font-size: 13px;
    width: 33.33%;
  }
  
  .testimonials .testi .testi-desc i {
    font-size: 13px;
    color: #ffaa20;
  }
  
  .testimonials .testi .testi-desc .verify {
    background: url(../images/sprites.png) no-repeat;
    background-position: -181px -7px;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .testimonials .packagesall .portfolio-mmbx .leftcont-ban a {
    width: 145px;
  }
  
  .testimonials .packagesall .portfolio-mmbx .leftcont-ban a:hover {
    width: 190px;
  }
  
  .testimonials .row.testi-main {
    padding-top: 30px;
  }
  
  .content-incred h5 {
    color: #ed3e25 !important;
  }
  
  .what-offer ul li i.icon5 {
    background-position: -365px -172px;
    width: 64px;
    height: 63px;
  }
  
  .what-offer ul li i.icon6 {
    background-position: -459px -253px;
    width: 66px;
    height: 66px;
  }
  
  .what-offer ul li i.icon7 {
    background-position: -455px -173px;
    width: 73px;
    height: 59px;
  }
  
  .what-offer ul li i.icon8 {
    background-position: -354px -257px;
    width: 77px;
    height: 60px;
  }
  
  .f30 {
    font-size: 30px !important;
  }
  
  .fbold {
    font-weight: 700;
  }
  
  .f20 {
    font-size: 20px;
  }
  
  .f24 {
    font-size: 24px;
  }
  
  .boxpackages .packdetails .listpacks h3 {
    font-size: 22px;
    color: #ed3e25;
    font-weight: 700;
  }
  .about .bannermm {
    background-image: url(../images/banner-about.jpg) !important;
    padding: 200px 0 100px !important;
    min-height: 30vh !important;
}
  
  /*.howtabs-main{ padding: 40px 0; display: block; margin-top: 30px; }
  .howtabs-main .tabs-btns{ display: block; background: url("/web-content/images/how-works-bg.png") no-repeat; background-size: cover;  min-height: 130px;}
  .howtabs-main .tabs-btns ul{ width: 100%; display: block; }
  .howtabs-main .tabs-btns ul li{	float: left;  padding: 10px 10px; width: 16%; text-align: center; margin: 0 0 0 15px; position: relative; 	}
  .howtabs-main .tabs-btns ul li.first{ text-align: left; width: 15%; margin-left: 6px; 	}
  .howtabs-main .tabs-btns ul li:nth-child(2){    margin-left: 3px;  margin-right: 7px;}
  .howtabs-main .tabs-btns ul li:nth-child(4){  margin-left: 3px;  width: 17%; margin-right: 4px; }
  .howtabs-main .tabs-btns ul li.last{ text-align: right;     width: 14%; margin-left: 13px;}
  .howtabs-main .tabs-btns ul li i{ position: relative; }
  .howtabs-main .tabs-btns ul li span{color:#ccc;font-size: 25px;font-weight: 600;font-family: 'Work Sans', sans-serif;position: absolute;left: 0;right: 0;margin: 0 auto;text-align: center;}
  .howtabs-main .tabs-btns ul li span.ytop{ transform: translateY(-59px); display: block; }
  .howtabs-main .tabs-btns ul li span.ybot{ transform: translateY(120px); display: block; }
  .howtabs-main .tabs-btns ul li.first span.ytop{ text-align: left; padding-left: 40px; }
  .howtabs-main .tabs-btns ul li.last span.ybot{ text-align: right; padding-right: 40px; }
  
  .howdiscr{padding-top: 50px; display: none; }
  .howdiscr.active{ display: block; }
  .howdiscr .row{ align-items: center; }
  .howdiscr h3{ font-size: 28px; margin-bottom: 15px; font-weight: 600;    margin-top: 30px; }
  .howdiscr svg {height: 240px; display: inline-block;}
  .howdiscr p{ color: #242424; font-size: 16px; padding-right: 35px; line-height: 26px; font-weight: 400; }
  .howdiscr img{ width: initial; }
  .howtabs-main .tabs-btns ul li.active span{ color: #f9260e; }
  .howtabs-main .tabs-btns ul li.active i:before{ content: ''; position: absolute; bottom: -8px; left: 0; right: 0; margin: auto; background: url("/web-content/images/sprites.png") no-repeat; background-position:-259px -14px; width: 19px; height: 10px;}
  .sp-ico {  background: url(/web-content/images/sprites.png) no-repeat; display: inline-block; }
  .hw-ico1{  background-position: -34px -355px; width: 101px; height: 101px; }
  .hw-ico2{  background-position: -144px -354px; width: 101px; height: 101px; }
  .hw-ico3{  background-position: -259px -354px; width: 101px; height: 101px; }
  .hw-ico4{  background-position: -374px -360px; width: 102px; height: 101px; }
  .hw-ico5{  background-position: -490px -360px; width: 102px; height: 101px; }
  .hw-ico6{  background-position: -609px -360px; width: 101px; height: 101px; }*/
  .how-works {
    padding: 30px 0;
    width: 100%;
    float: left;
  }
  
  .howworks-mm {
    margin: 40px 0;
  }
  
  .howworks-mm .designbrief {
    text-align: center;
    position: relative;
    transition: all 0.3s ease-out;
  }
  
  .howworks-mm .designbrief:hover .processicons {
    transform: scale(1.07);
  }
  
  .howworks-mm .designbrief:before {
    content: "01";
    position: absolute;
    top: 0;
    left: 30px;
    font-size: 23px;
    font-weight: 700;
    color: #ed3e25;
  }
  
  .howworks-mm .col-md-3+.col-md-3 .designbrief:before {
    content: "02";
  }
  
  .howworks-mm .col-md-3+.col-md-3+.col-md-3 .designbrief:before {
    content: "03";
  }
  
  .howworks-mm .col-md-3+.col-md-3+.col-md-3+.col-md-3 .designbrief:before {
    content: "04";
  }
  
  .howworks-mm .designbrief .processicons {
    transition: all 0.3s ease-out;
    background: url("../images/sprites.png") no-repeat;
    display: inline-block;
    background-position: -510px -489px;
    width: 169px;
    height: 198px;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  
  .howworks-mm .designbrief .processicons i {
    background-position: -25px -699px;
    width: 41px;
    height: 52px;
    margin-top: -25px;
  }
  
  .howworks-mm .designbrief .processicons i.icon2 {
    background-position: -82px -704px;
    width: 51px;
    height: 47px;
  }
  
  .howworks-mm .designbrief .processicons i.icon3 {
    background-position: -146px -701px;
    width: 52px;
    height: 52px;
  }
  
  .howworks-mm .designbrief .processicons i.icon4 {
    background-position: -214px -704px;
    width: 50px;
    height: 50px;
  }
  
  .howworks-mm .designbrief .processicons i.icon5 {
    background-position: -13px -779px;
    width: 47px;
    height: 51px;
  }
  
  .howworks-mm .designbrief .processicons i.icon6 {
    background-position: -78px -781px;
    width: 48px;
    height: 48px;
  }
  
  .howworks-mm .designbrief .processicons i.icon7 {
    background-position: -147px -778px;
    width: 51px;
    height: 51px;
  }
  
  .howworks-mm .designbrief .processicons i.icon8 {
    background-position: -214px -704px;
    width: 50px;
    height: 50px;
  }
  
  .howworks-mm .designbrief h3 {
    font-size: 18px;
    color: #111111;
    font-weight: 700;
  }
  
  .howworks-mm .designbrief p {
    font-size: 14px;
    color: #242424;
  }
  
  .howworks-mm .col-md-3+.col-md-3 .designbrief:after {
    content: '';
    border-top: 2px dashed #ccc;
    width: 100px;
    display: block;
    position: absolute;
    top: 30%;
    left: -24%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }
  
  
  .logopackages-p .det-packages .tabscontent .col-md-4:nth-child(n+4) .boxpackages {
    margin-top: 0px;
    /*   margin-bottom: 0;*/
  }
  
  /*.logopackages-p .packagesbxs .packagesall  .portfolio-mmbx{ display: none; }*/
  .home-p .det-packages .tabscontent .col-md-4:nth-child(n+4) .boxpackages {
    display: none;
  }
  
  .logopackages-p .bannermm {
    background-image: url(../images/packages-banner.jpg) !important;
    padding: 200px 0 100px !important;
    background-position: center !important;
    min-height: 30vh !important;
  }
  
  .bannerdesign-p .bannermm {
    background-image: url(../images/banner-design-bg.jpg);
    padding: 250px 0 20px;
    background-position: center;
  }
  
  .portfolio-mmbx .fixportfoliobtns.leftcont-ban a {
    width: 326px;
  }
  
  .bannerdesign-p .portfoliomaindivs .topbartabs {
    border-top: none;
  }
  
  .broucherdesign-p .bannermm {
    background-image: url(../images/broucher-design-bg.jpg);
    padding: 250px 0 20px;
  }
  
  /*.broucherdesign-p .det-packages .boxpackages .packheads h3{ font-size: 32px; }*/
  .nopadd-l {
    padding-left: 0;
  }
  
  .nopadd-r {
    padding-right: 0;
  }
  
  .nopadd-lr {
    padding-left: 0;
    padding-right: 0;
  }
  
  .branding-portfolio {
    width: 100%;
    float: left;
    padding: 40px 0;
    background: #f8f8f8;
  }
  
  .bg-1 {
    background: #242424;
  }
  
  .bg-2 {
    background: #904d83;
  }
  
  .bg-3 {
    background: #68797a;
  }
  
  .bg-4 {
    background: #452b78;
  }
  
  .c-tabs {
    overflow: hidden;
  }
  
  .c-tabs .tabs-box.tabs-box-cer {
    overflow: hidden;
  }
  
  .c-tabs h3 {
    color: #ffffff;
    margin-top: 0;
  }
  
  .c-tabs p {
    color: #ffffff;
    font-size: 14px;
  }
  
  .c-tabs .col-md-3 h3 {
    color: #111111;
    margin: 0;
    font-weight: 800;
    font-size: 36px;
    line-height: 45px;
  }
  
  .c-tabs .col-md-3 p {
    color: #242424;
    font-size: 14px;
    margin: 10px 0 10px 0;
    font-weight: 600;
  }
  
  .c-tabs .tabs-list {
    margin: 20px 0;
  }
  
  .c-tabs .tabs-list li {
    margin-bottom: 3px;
    font-size: 15px;
    position: relative;
    z-index: 2;
  }
  
  .c-tabs .tabs-list li * {
    transition: all 0.3s ease-out;
  }
  
  .c-tabs .tabs-list li a {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 15px;
    display: block;
  }
  
  .c-tabs .tabs-list li:hover a:before,
  .c-tabs .tabs-list li.active a:before {
    width: 100%;
  }
  
  .c-tabs .tabs-list li:hover a,
  .c-tabs .tabs-list li.active a {
    color: #fff;
  }
  
  .branding .branding-portfolio .c-tabs-description p {
    min-height: 200px;
    line-height: 23px;
  }
  
  .c-tabs .tabs-list li a:before {
    content: '';
    background-color: #111111;
    width: 5px;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    z-index: -1;
    transition: all 0.7s ease-out;
  }
  
  .c-tabs .tabs-list li:nth-child(1) a:before {
    content: '';
    background-color: #111111;
  }
  
  .c-tabs .tabs-list li:nth-child(2) a:before {
    content: '';
    background-color: #904d83;
  }
  
  .c-tabs .tabs-list li:nth-child(3) a:before {
    content: '';
    background-color: #68797a;
  }
  
  .c-tabs .tabs-list li:nth-child(4) a:before {
    content: '';
    background-color: #452b78;
  }
  
  .branding .branding-portfolio .c-tabs-description {
    padding: 0 20px 0 0px;
  }
  
  .branding .branding-portfolio .c-tabs-description a {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 7px 35px;
    display: inline-block;
    font-size: 18px;
  }
  
  .branding .branding-portfolio .bg1-description {
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    /*padding: 46px 20px 46px;*/
  }
  
  .branding .branding-portfolio .bg1-description:before {
    content: '';
    position: absolute;
    left: 0;
    background: url("../images/shadow-branding.png") no-repeat;
    width: 577px;
    height: 377px;
  }
  
  .branding .branding-portfolio .bg1-description img {
    width: initial;
  }
  
  .branding-portfolio .c-tabs .tab-content {
    display: none;
    margin-left: 20px;
  }
  
  .branding-portfolio .c-tabs .tab-content.active {
    display: block;
    margin-left: 20px;
  }
  
  .branding .bannermm {
    background: url('../images/banner-branding.jpg');
    padding: 250px 0 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .animation .bannermm {
    background: url('../images/banner-animation.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 150px 0 100px !important;
    min-height: 30vh;
    
  }
  
  .nft.bannermm {
   
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 150px 0px 100px !important;
    min-height: 30vh !important;
  }
  
  .inner-pg .bannermm .leftcont-ban h1 {
    font-size: 35px;
  }
  
  .inner-pg .bannermm .leftcont-ban p {
    font-size: 20px;
  }
  
  .animation .animation-service {
    width: 100%;
    float: left;
    padding-bottom: 40px;
  }
  
  .animation-service .srvc-box {
    padding: 15px 15px;
    overflow: hidden;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 19px 1px rgba(150, 150, 150, 1);
    -moz-box-shadow: 0px 0px 19px 1px rgba(150, 150, 150, 1);
    box-shadow: 0px 0px 19px 1px rgb(216, 216, 216);
  }
  
  .animation-service .srvc-box {
    border: 2px solid transparent;
    margin-bottom: 20px;
    transition: 0.5s ease-out;
  }
  
  .animation-service .srvc-box:hover {
    border-color: #ed3e25;
    transition: 0.5s ease-in;
  }
  
  .animation-service .srvc-box .srvc-lft img,
  .animation-service .srvc-box .srvc-lft video {
    float: left;
    margin-right: 25px;
    width: 160px;
    height: 160px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }
  
  .animation-service .srvc-box .srvc-rht h4 {
    color: #ed3e25;
    font-size: 22px;
    font-weight: 600;
    margin-top: 0;
  }
  
  .animation-service .srvc-box .srvc-rht p {
    font-size: 14px;
    line-height: 19px;
  }
  
  .animation-service .srvc-box .srvc-rht a {
    font-size: 14px;
    text-decoration: underline;
  }
  
  .animation-service .srvc-box .srvc-rht a:hover {
    color: #232323;
  }
  
  .animation-service .srvc-box .srvc-rht a:focus {
    color: #232323;
  }
  
  .animation-service .srvc-box .srvc-rht a i {
    color: #ed3e25;
  }
  
  .video-development {
    width: 100%;
    float: left;
    background: url(../images/vd-development-bg.jpg);
    min-height: 670px;
    padding: 40px 0;
    background-size: cover;
  }
  
  .video-development .vd-devp-fr {
    text-align: center;
  }
  
  .video-development .vd-devp-fr h2 {
    font-size: 36px;
    color: #111111;
    font-weight: 800;
  }
  
  .video-development .vd-devp-fr p {
    font-size: 16px;
  }
  
  .video-development .vd-devp {
    text-align: center;
  }
  
  .video-development .vd-devp>div {
    background: #ffffff;
    padding: 15px 10px 15px 10px;
    border-radius: 10px;
    min-height: 355px;
    -webkit-box-shadow: 0px 0px 19px 1px rgba(150, 150, 150, 1);
    -moz-box-shadow: 0px 0px 19px 1px rgba(150, 150, 150, 1);
    box-shadow: 0px 0px 19px 1px rgb(216, 216, 216);
    position: relative;
    z-index: 2;
  }
  
  .nft-design .vd-devp.vd-bef>div {
    min-height: 366px;
  }
  
  .video-development .vd-devp h3 {
    color: #ed3e25;
    font-size: 21px;
    font-weight: 600;
  }
  
  .video-development .vd-devp p {
    font-size: 14px;
    padding: 0 10px;
  }
  
  .video-development .vd-devp span {
    position: absolute;
    background: #ed3e25;
    color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    left: -7px;
    font-weight: 700;
    font-size: 28px;
    top: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .padd-tp {
    padding-top: 40px;
  }
  
  .video-development .vd-bef:before {
    content: '';
    position: absolute;
    left: -20px;
    width: 50px;
    height: 10px;
    top: 15px;
    background: #ed3e25;
    display: inline-block;
    z-index: 1;
  }
  
  .testimo .bannermm {
    background: url(../images/banner-testimonials.jpg) !important;
    background-size: cover !important;
    background-position: center !important;
    padding: 100px 0  !important;
    min-height: 30vh !important;
  }
  
  .testimonials-sec {
    width: 100%;
    float: left;
    padding: 50px 40px;
  }
  
  .testimonials-sec .testi .testi-img {
    min-height: 120px;
  }
  
  .testimonials-sec .sechead {
    margin-bottom: 50px;
  }
  
  .testimo .testimonials-sec .row .col-md-6+.col-md-6 {
    margin-bottom: 40px;
  }
  
  .testimonials-sec .testi {
    padding-right: 20px;
    padding-left: 20px;
    position: relative;
  }
  
  .testimonials-sec .packagesall .portfolio-mmbx .leftcont-ban a {
    width: 170px;
  }
  
  .testimonials-sec .packagesall .portfolio-mmbx .leftcont-ban a:hover {
    width: 220px;
  }
  
  .testimo #alltest .testi-main>div {
    display: none;
  }
  
  .sitemap-main .sp-section {
    min-height: 571px;
    padding-top: 70px;
  }
  
  .sitemap-main .sp-section h3 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
  }
  
  .sitemap-main .sp-section ul {}
  
  .sitemap-main .sp-section ul li {
    position: relative;
    padding: 8px 0 5px 10px;
  }
  
  .sitemap-main .sp-section ul li::before {
    content: "\f105";
    position: absolute;
    color: #ed3e25;
    font-family: fontAwesome;
    font-size: 11px;
    left: 0;
    top: 13px;
    font-weight: 800;
  }
  
  .sitemap-main .sp-section ul li a {
    color: #242424;
    font-size: 13px;
  }
  
  .prvcy-securty .bannermm .leftcont-ban h1 strong {
    font-size: 58px;
  }
  
  .sitemap-main .termcndtn p strong {
    font-size: 22px;
    display: block;
  }
  
  .sitemap-main .termcndtn ul li {
    list-style: inside;
  }
  
  .sitemap-main.stp ul li {
    list-style: none;
  }
  
  .trms-condtons .tms-cdts {}
  
  .trms-condtons .bannermm .leftcont-ban h1 strong {
    font-size: 54px;
  }
  
  .bd-none {
    border: none !important;
    display: none !important;
  }
  
  .animation.charAnimation .bannermm {
    background: url(../images/character-Animation.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .animation.expVideo .bannermm {
    background: url(../images/explainer.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .animation.typoAnimation .bannermm {
    background: url(../images/typography-banner.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  
  .animation.grpgAnimation .bannermm {
    background: url(../images/graphicAnimation.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .animation.interactiveAnimation .bannermm {
    background: url(../images/interactive-design3D.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
  
  .animation.whtVideo .bannermm {
    background: url(../images/whteBoard-banner.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
  
  .charAnimation .bannermm h1 strong {
    font-size: 43px !important;
  }
  
  .whtVideo .bannermm .leftcont-ban h1 {
    font-size: 31px;
  }
  
  .video-development {
    min-height: 600px;
  }
  
  .inner-pg .bannermm .leftcont-ban h1 {
    display: inline-block;
  }
  
  .bannermm.animbanner {
    background: url("../images/animation-header.jpg") no-repeat;
    background-size: cover;
    height: 100vh;
  }
  
  .bannermm.brandingbanner {
    background: url("../images/brandingbanner.jpg") no-repeat;
    background-size: cover;
    
  }
  
  .bannermm a.logobtnsstart {
    width: 510px;
  }
  
  .bannermm a.logobtnsstart:hover {
    width: 530px;
  }
  
  .web-design .bannermm a.webbtnsstart {
    width: 478px;
  }
  
  .web-design .bannermm a.webbtnsstart:hover {
    width: 510px;
  }
  
  .animation-main .bannermm a.animbtnsstart {
    width: 388px;
  }
  
  .animation-main .bannermm a.animbtnsstart:hover {
    width: 566px;
  }
  
  .branding .bannermm a.brandbtnsstart {
    width: 525px;
  }
  
  .branding .bannermm a.brandbtnsstart:hover {
    width: 535px;
  }
  
  .sitemap-main .termcndtn ul li {
    position: relative;
    padding-left: 15px;
    list-style: none;
  }
  
  .sitemap-main .termcndtn ul li:before {
    content: '';
    background: #555;
    width: 5px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 15px;
    border-radius: 100%;
  }
  
  /* header .main-nav ul li{ position: relative; } */
  /* header .main-nav ul li.selectnavs:before{ content: '';  position: absolute; left: 0px; right: 0; margin: auto; bottom: 0; background: #fff;
      width: 80%;
      height: 3px;  } */
  header .main-nav ul li.first.selectnavs a:before {
    display: none;
  }
  
  /*header .main-nav ul li.selectnavs.dropparent a:before{ width: 75%; left: -14px; }*/
  header .main-nav ul li.last.selectnavs a:before {
    width: 90%;
    right: 0px;
    left: initial;
  }
  
  header .main-nav ul>li.selectnavs>a::before {
    right: -4px;
  }
  
  header .main-nav ul>li.dropparent>a::before {
    right: 9px;
  }
  
  .no-found {
    background: url(../images/notFound-bg.jpg);
    background-size: cover;
    background-position: center;
    min-height: 678px;
    background-repeat: no-repeat;
    padding-top: 90px;
  }
  
  .no-found .noFound-content h1 {
    font-size: 255px;
    font-weight: 800;
    margin: 0;
    color: #ed3e25;
  }
  
  .no-found .noFound-content h3 {
    margin: 0;
    color: #000000;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .no-found .noFound-content a {
    border: 1px solid #ed3e25;
    color: #ed3e25;
    border-radius: 20px;
    padding: 10px 20px;
    display: inline-block;
    font-weight: 700;
  }
  
  .slick-list {
    padding: 0 !important;
  }
  
  .trustpilot-class {
    margin-top: 30px;
  }
  
  @media (min-width: 768px) {
    .col-sm-5ths {
      width: 20%;
      float: left;
    }
  }
  
  @media (min-width: 992px) {
    .col-md-5ths {
      width: 20%;
      float: left;
    }
  }
  
  @media (min-width: 1200px) {
    .col-lg-5ths {
      width: 20%;
      float: left;
    }
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive */
@media (max-width: 1199px) {

  .slick-prev,
  .slick-next {
      display: none !important
  }

  .topnavs a {
      font-size: 15px;
      padding-right: 12px;
  }

  .topnavs a+a {
      padding-left: 13px;
  }

  header .main-nav ul>li>a {
      font-size: 13px;
      padding: 18px 4px 22px;
  }

  .topnavs .btn-radius-top a {
      padding: 6px 14px;
      font-size: 13px;
  }

  header .main-nav ul>li:first-child a {
      display: none
  }

  .logo img {
      width: 280px;
      max-width: initial
  }

  header {
      padding: 13px 0px 0;
  }

  .inner-pg .bannermm h1 strong,
  .inner-pages .bannermm h1 strong {
      font-size: 43px;
  }

  .web-design .bannermm .leftcont-ban h1,
  .inner-pg .bannermm h1,
  .inner-pg .bannermm h1 span,
  .inner-pages .bannermm h1,
  .inner-pages .bannermm h1 span {
      font-size: 30px;
  }

  .web-design .bannermm .leftcont-ban p {
      font-size: 16px
  }

  .leftcont-ban a {
      margin-top: 25px;
  }

  bannermm {
      min-width: 540px
  }

  .testimonials,
  .incred-expe {
      padding: 50px 0 30px;
  }

  .web-design .incred-expe .content-incred h4 {
      font-size: 21px;
      margin-bottom: 8px
  }

  .incred-expe .content-incred h3 {
      font-size: 27px;
      margin-bottom: 22px;
  }

  .det-packages .sechead h3 {
      font-size: 30px
  }

  .sechead p {
      font-size: 16px
  }

  .maincta .btn-radius-top a {
      padding: 6px 11px;
      font-size: 15px;
  }

  .maincta .callsdivs {
      font-size: 18px
  }

  .sitemap-main .bannermm .leftcont-ban h1 strong,
  .trms-condtons .bannermm .leftcont-ban h1 strong,
  .prvcy-securty .bannermm .leftcont-ban h1 strong,
  .bannermm .leftcont-ban h1 strong {
      font-size: 44px
  }

  .sitemap-main .bannermm {
      min-height: 330px;
  }

  .sitemap-main .termcndtn {
      padding: 20px 0;
  }

  .charAnimation .bannermm h1 strong {
      font-size: 44px !important
  }

  .inner-pg .bannermm .leftcont-ban h1,
  .bannermm .leftcont-ban h1,
  .bannermm .leftcont-ban h1 span {
      font-size: 30px
  }

  body.mainhome .bannermm .leftcont-ban p {
      font-size: 20px
  }

  body.inner-pg .bannermm .leftcont-ban p {
      font-size: 20px
  }

  .leftcont-ban a {
      margin: 5px 0 60px
  }

  .scrolldown-fl {
      margin: 0 0 20px
  }

  footer .social-icons.padd-btd {
      padding: 12px 20px;
  }

  footer .brdr-top .social-icons i {
      margin: 0 1px;
      width: 43px;
      height: 43px;
      font-size: 20px;
  }

  .what-offer ul li div {
      padding: 17px 3px 10px 60px;
  }

  .what-offer ul li div:before {
      transform: scale(0.9);
  }

  .what-offer ul li i.icon4,
  .what-offer ul li i.icon3,
  .what-offer ul li i.icon2,
  .what-offer ul li div+i {
      margin-left: 48px
  }

  .det-packages .boxpackages {
      padding: 17px 13px 20px;
      margin: 30px 0;
  }

  .packdetails .botarea-pack a.btn-orders {
      padding: 3px 8px;
      font-size: 14px;
  }

  .packdetails .botarea-pack .liovechats-bx a h5 {
      font-size: 14px;
  }

  .packdetails .botarea-pack .liovechats-bx a span {
      font-size: 9px
  }

  .packdetails .botarea-pack .liovechats-bx {
      padding-left: 10px
  }

  .boxpackages .packdetails .packtitles .fleft h3 {
      font-size: 45px
  }

  .packdetails .packtitles .fright h4 {
      font-size: 16px
  }

  .packdetails .listpacks li {
      font-size: 13px;
      padding: 5px 16px;
  }

  .packdetails .listpacks li:before {
      font-size: 13px
  }

  .boxpackages .packdetails .listpacks h3 {
      font-size: 18px
  }

  .mCSB_outside+.mCSB_scrollTools {
      right: -19px;
  }

  .testimonials .testi .testi-img h4 {
      font-size: 17px
  }

  .testimonials .testi .testi-img img {
      margin-right: 20px;
      margin-bottom: 20px;
  }

  .testimonials .testi .testi-desc i,
  .testimonials .testi .testi-desc ul li {
      font-size: 12px
  }

  .enterprices-bundles {
      padding: 10px 0 20px;
  }

  .sechead h3 {
      font-size: 30px;
  }

  .pacbundles-det .headbx-bundle ul.listbundles-packbx>li {
      padding: 5px 18px;
      font-size: 12px;
  }

  .pacbundles-det .headbx-bundle ul.listbundles-packbx>li:before {
      font-size: 12px
  }

  .pacbundles-det .headbx-bundle .packbundleprice span {
      font-size: 22px;
  }

  .pacbundles-det .headbx-bundle .packbundleprice {
      padding-left: 10px;
      margin: -10px 0 0 0;
  }

  .pacbundles-det .headbx-bundle li.haschildren .child-tooltip {
      right: 17px
  }

  .pacbundles-det .headbx-bundle .fleft h4 {
      margin: 0 !important;
      font-size: 18px
  }

  .enterprices-bundles .totalpackagespricess h4 {
      font-size: 28px
  }

  .enterprices-bundles .totalpackagespricess .btn-orders-bundles a {
      padding: 7px 23px;
      font-size: 18px;
  }

  footer {
      padding: 20px 0 0 0;
  }

  body.mainhome .bannermm .leftcont-ban p:before {
      width: 100% !important
  }

  .dropdown .listimgs {
      display: none
  }

  header .main-nav ul li .dropdown {
      width: 550px;
      overflow: hidden;
  }

  .portfoliomaindivs .topbartabs {
      padding: 0px 15px;
  }

  .hovereffects-det h3 {
      font-size: 20px;
  }

  .btnstyles-nn a {
      padding: 3px 20px;
      font-size: 16px;
  }

  .what-offer>div>ul>li div {
      padding: 12px 15px 10px 73px;
  }

  .maincta h3 strong {
      font-size: 38px;
  }

  .maincta p {
      font-size: 16px;
  }

  .maincta .callsdivs {
      padding: 25px 0 0px 0;
  }

  .mainhome .bannermm {
      height: auto
  }

  .bannermm {
      min-height: 600px;
  }

  .slick-dots {
      text-align: center !important
  }

  .what-offer ul li i.icon5,
  .what-offer ul li i.icon6,
  .what-offer ul li i.icon7,
  .what-offer ul li i.icon8 {
      margin-left: 50px;
  }

  .maincta .callsdivs a+a {
      display: block;
      border: none
  }

  .types-box {
      overflow: hidden;
  }

  img {
      max-width: 100%;
      height: auto;
  }

  .types-box h5 {
      padding-top: 20px
  }

  .types-box {
      min-height: 317px;
  }

  .testimonials,
  .incred-expe,
  .what-offer {
      padding: 30px 0;
  }

  .how-works,
  .homeport-mm {
      padding: 20px 0;
  }

  .pricerange-main .pricingbox .iconsbx {
      transform: scale(0.8);
  }

  .pricerange-main .pricingbox li h3 {
      margin-top: -6px;
  }

  .animation-service .srvc-box .srvc-lft img,
  .animation-service .srvc-box .srvc-lft video {
      margin-bottom: 25px;
  }

  .c-tabs .col-md-3 h3 {
      font-size: 29px;
      line-height: 34px;
  }

  .branding .branding-portfolio .tab-content .bg1-description {
      padding: 10px 0;
  }

  .incred-expe .padtop150 {
      padding-top: 50px;
  }

  .gettouchfaqs {
      padding: 20px 0;
  }

  .gettouchfaqs h3 {
      font-size: 32px;
      margin: 0;
  }

  .gettouchfaqs .packagesbxs .toptabs1 a {
      padding: 6px 25px;
      font-size: 17px;
  }

  .contact-form-main .red-bg .social-icons i {
      width: 36px;
      height: 36px;
      font-size: 17px;
      margin: 0;
  }

  .contact-form-main .gray-bg {
      padding: 23.5px 22px;
  }

  .incred-expe .content-incred p {
      font-size: 16px;
  }

  .sitemap-main .sp-section ul li a {
      font-size: 15px
  }

  .sitemap-main.stp .termcndtn ul li:before {
      top: 17px;
  }

  .sitemap-main .termcndtn ul li:before {
      top: 8px
  }
}

/* @media (min-width:1170px) and (max-width: 1281px) {} */
/* @media (min-width:1000px) and (max-width: 1169px) {} */
@media (max-width: 991px) {
  .gettouchfaqs .packagesbxs .toptabs1 a {
      padding: 4px 17px;
      font-size: 15px;
  }

  .custom-popup>div {
      padding: 80px 20px 20px;
  }

  .cbp-spmenu {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#9a092f+10,9a092f+24,ff0042+100 */
      background: rgb(154, 9, 47);
      /* Old browsers */
      background: -moz-linear-gradient(top, rgba(154, 9, 47, 1) 10%, rgba(154, 9, 47, 1) 24%, rgba(255, 0, 66, 1) 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(154, 9, 47, 1) 10%, rgba(154, 9, 47, 1) 24%, rgba(255, 0, 66, 1) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(154, 9, 47, 1) 10%, rgba(154, 9, 47, 1) 24%, rgba(255, 0, 66, 1) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a092f', endColorstr='#ed3e25', GradientType=0);
      /* IE6-9 */
  }

  a.toggle-menu.menu-left.push-body.jPushMenuBtn {
      /* margin-left: 11px; */
      /* display: inline-block; */
      position: relative;
      z-index: 99999;
  }

  a.toggle-menu:before {
      content: '\f0c9';
      font-family: fontAwesome;
      color: #fff;
      position: absolute;
      left: 45px;
      top: 6px;
      display: block;
      height: 13px;
      width: 22px;
      -webkit-transform-origin: .28571rem center;
      transform-origin: .28571rem center;
      font-size: 30px
  }

  header .logo {
      margin-left: 60px
  }

  .leftcont-ban {
      max-width: 380px
  }

  .creataccounts,
  .lg-hidden {
      display: none !important
  }

  .cbp-spmenu li.parenturl.home a {
      position: relative;
  }

  .cbp-spmenu li.parenturl.home a>img {
      display: none
  }

  .cbp-spmenu li.parenturl.home:nth-child(1) a::before {
      content: "HOME"
  }

  .dropdown .lstnavig .liststylss>li {
      border: none
  }

  .dropdown .lstnavig .liststylss li .innerdropdowns,
  .dropdown .lstnavig>.liststylss>li>a i {
      display: none
  }

  .dropdown .lstnavig .liststylss>li>a {
      padding: 7px 0 7px 35px;
      color: #000;
      border: none  
  }

  .dropdown .lstnavig .liststylss>li:hover>a,
  .dropdown .lstnavig .liststylss li.active>a {
      font-weight: 700;
      color: #fff
  }

  .dropdown .lstnavig .liststylss>li>a:after {
      color: #fff
  }

  .cbp-spmenu-vertical a {
      border-bottom: none;
      border-top: 1px solid rgba(255, 255, 255, 0.8)
  }

  .dropdown .lstnavig,
  .dropdown .listdiscret,
  .dropdown .listimgs {
      float: none;
  }

  .dropdown .lstnavig .liststylss {
      margin-bottom: 10px;
      margin-top: -10px
  }

  .stickyheader {
      position: static;
  }

  footer {
      padding: 10px 0 0 0;
  }

  footer h3 {
      font-size: 14px
  }

  footer ul li {
      padding: 4px 0 4px 10px;
  }

  footer ul li a {
      font-size: 11px
  }

  footer ul li::before {
      top: 7px
  }

  footer .listpge {
      padding-bottom: 0px;
  }

  footer .padd-btd {
      padding: 0px 0 13px;
  }

  footer .social-icons.padd-btd {
      border: none
  }

  footer .brdr-top {
      padding: 20px 0px 10px 0;
  }

  .ulsme-ctct ul li::before {
      font-size: 11px;
  }

  .sitemap-main .bannermm .leftcont-ban h1 strong,
  .trms-condtons .bannermm .leftcont-ban h1 strong,
  .prvcy-securty .bannermm .leftcont-ban h1 strong,
  .bannermm .leftcont-ban h1 strong {
      font-size: 35px;
  }

  .sitemap-main .bannermm {
      min-height: 200px;
  }

  .sitemap-main .bannermm .leftcont-ban {
      margin-top: 62px;
  }

  .sitemap-main .termcndtn {
      padding: 10px 0;
  }

  .sitemap-main .termcndtn ul li:before {
      top: 7px
  }

  .sec-pad.termcndtn p br {
      display: none
  }

  .sitemap-main .termcndtn p strong {
      font-size: 20px;
      margin-bottom: 5px
  }

  .bannermm .leftcont-ban h1,
  .bannermm .leftcont-ban h1 span {
      font-size: 22px;
  }

  .charAnimation .bannermm h1 strong {
      font-size: 35px !important;
  }

  .bannermm .leftcont-ban h1 {
      padding-bottom: 10px;
      margin-bottom: 5px
  }

  .bannermm {
      min-height: 440px
  }

  body.mainhome .bannermm .leftcont-ban p {
      font-size: 16px
  }

  .leftcont-ban a {
      font-size: 14px;
      height: 35px;
      width: 270px
  }

  .bannermm a.logobtnsstart {
      width: 270px
  }

  .bannermm a.logobtnsstart:hover {
      width: 340px
  }

  .testimonials,
  .incred-expe {
      padding: 20px 0 10px;
  }

  .mainhome .incred-expe .slick-dots,
  .testimonials .slick-dots,
  .incred-expe .slick-dots,
  .det-packages .slick-dots {
      bottom: -20px;
  }

  .slick-dots {
      text-align: center !important
  }

  header {
      padding: 0;
      padding: 10px 0;
  }

  .logo img {
      width: 300px;
      margin-left: -98px;
  }

  header .logo {
      padding-top: 0px !important;
      text-align: left;
  }

  .incred-expe .content-incred h5 {
      font-size: 18px
  }

  .incred-expe .content-incred h3 {
      font-size: 24px !important;
      margin-bottom: 10px
  }

  p {
      font-size: 14px
  }

  .incred-expe .content-incred p.f20 {
      font-size: 18px;
      margin-bottom: 5px
  }

  .what-offer ul li div:before,
  .scrolldown-fl,
  .md-hidden {
      display: none !important
  }

  .incred-expe .content-incred .fnt-18 {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600
  }

  .what-offer {
      padding: 0px 0 10px
  }

  .what-offer h3 {
      font-size: 28px;
  }

  .what-offer p {
      font-size: 14px;
  }

  .logodesign-p .what-offer>div>ul>li div,
  .what-offer ul li div {
      padding: 10px 10px 10px;
      background: #fff;
      min-height: 170px;
      position: relative;
      border-radius: 8px;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  }

  .what-offer ul li div a {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: auto;
      color: #ea333f;
      font-weight: 600
  }

  .what-offer ul li div h3 {
      margin: 0 0 5px
  }

  .what-offer ul li i.icon5,
  .what-offer ul li i.icon6,
  .what-offer ul li i.icon7,
  .what-offer ul li i.icon8,
  .what-offer ul li i.icon4,
  .what-offer ul li i.icon3,
  .what-offer ul li i.icon2,
  .what-offer ul li div+i {
      display: none
  }

  .what-offer ul {
      margin: 10px -5px 0
  }

  .what-offer ul li {
      padding: 0 5px;
  }

  .what-offer>div>ul>li div h3,
  .what-offer ul li div h3 {
      font-size: 18px
  }

  .what-offer>div>ul>li div p,
  .what-offer ul li div p {
      font-size: 12px;
      margin: 0
  }

  .maincta h3 strong {
      font-size: 28px;
  }

  .what-offer>div>ul {
      margin: 10px 0 0;
  }

  .maincta h3 {
      font-size: 26px;
      line-height: normal;
      margin-bottom: 0
  }

  .maincta .btn-radius-top {
      margin-top: 10px
  }

  .maincta p {
      font-size: 14px
  }

  .maincta .btn-radius-top a {
      margin: 0 3px;
      padding: 4px 7px;
      font-size: 11px;
  }

  .maincta .callsdivs {
      font-size: 15px;
      text-align: center;
      padding-top: 16px
  }

  .maincta .callsdivs a+a {
      border: none
  }

  .maincta .callsdivs .iconphone {
      transform: scale(0.8);
  }

  .maincta {
      padding: 13px 0;
  }

  .homeport-mm {
      padding: 0
  }

  .sechead h3 {
      font-size: 28px;
      margin-bottom: 5px
  }

  .sechead p {
      font-size: 16px
  }

  .toptabs1 li span,
  .toptabs1 li a {
      padding: 7px 0px;
      font-size: 14px;
  }

  .toptabs1 li {
      margin: 0 3px;
      width: 130px;
  }

  .hovereffects-det h3 {
      font-size: 16px;
      margin: 5px 0
  }

  .btnstyles-nn a {
      padding: 3px 18px;
      font-size: 14px;
  }

  .portfolio-mmbx .leftcont-ban a {
      width: 168px;
      height: 33px;
      margin: 0px auto 10px;
      font-size: 13px;
  }

  .portfolio-mmbx .box-ports {
      margin: 0 -5px 15px
  }

  .det-packages {
      padding: 0px 0 10px;
  }

  .portfoliomaindivs .topbartabs {
      padding: 0 30px;
  }

  .det-packages .sechead h3 {
      font-size: 22px
  }

  .toptabs1 {
      margin: 10px 0
  }

  .det-packages .boxpackages {
      margin: 20px auto 10px;
      max-width: 280px;
  }

  .packagesall .portfolio-mmbx .leftcont-ban a {
      width: 163px;
  }

  .packagesall .portfolio-mmbx .leftcont-ban a:hover {
      width: 215px;
      margin-bottom: 0
  }

  .enterprices-bundles {
      padding: 0 0 10px
  }

  .pacbundles-det .headbx-bundle ul.listbundles-packbx>li>br {
      display: none
  }

  .packdetails .listpacks li:after,
  .pacbundles-det .headbx-bundle ul.listbundles-packbx li:after {
      left: 20px
  }

  .enterprices-bundles .totalpackagespricess h4 {
      font-size: 22px;
  }

  .enterprices-bundles .totalpackagespricess .yellcol {
      font-size: 28px
  }

  .enterprices-bundles .totalpackagespricess .btn-orders-bundles a {
      padding: 5px 20px;
      font-size: 16px;
  }

  .testimonials h3 {
      font-size: 28px
  }

  .testimonials .row.testi-main {
      padding-top: 0
  }

  .testimonials .testi .testi-img img {
      margin-right: 10px;
      margin-bottom: 10px;
  }

  .testimonials .testi .testi-img h4 {
      font-size: 16px;
      margin-bottom: 5px
  }

  .testimonials .testi .testi-img p {
      font-size: 12px
  }

  .testimonials .testi .testi-img::before {
      transform: scale(0.8) translateX(5px);
  }

  .mainhome .bannermm {
      height: auto;
  }

  body.mainhome .bannermm .leftcont-ban p:before {
      top: 0
  }

  .mainhome .bannermm .leftcont-ban a,
  .mainhome .bannermm.animbanner .leftcont-ban a,
  .mainhome .bannermm.webbanner .leftcont-ban a {
      width: 285px
  }

  .mainhome .bannermm .leftcont-ban a:hover,
  .mainhome .bannermm.animbanner .leftcont-ban a:hover,
  .mainhome .bannermm.webbanner .leftcont-ban a:hover {
      width: 335px
  }

  .portfolio-mmbx .leftcont-ban a:hover {
      width: 210px
  }

  footer .brdr-top .subcribe input {
      padding: 13px 35px;
      font-size: 12px
  }

  footer .brdr-top .subcribe i {
      top: 59px;
      left: 28px;
  }

  footer .brdr-top .subcribe button {
      padding: 8px 27px;
  }

  .inner-pg .bannermm .leftcont-ban h1 {
      font-size: 28px;
  }

  .leftcont-ban a i {
      opacity: 0
  }

  .leftcont-ban a:hover i {
      opacity: 1
  }

  .portfoliomaindivs .tabschildrens ul li {
      padding: 5px 8px;
  }

  .portfoliomaindivs .tabschildrens ul li span {
      font-size: 13px
  }

  .portfolio-mmbx .box-ports {
      max-width: 220px;
      margin: 0 auto 35px;
  }

  .portfolio-mmbx .fixportfoliobtns.leftcont-ban a {
      width: 206px;
  }

  .slick-prev,
  .slick-next {
      display: none !important
  }

  .inner-pg .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9),
  .mainhome .portfoliomaindivs .innertabcontents .row .col-md-3:nth-child(n+9),
  .mainhome .portfoliomaindivs .innertabcontents .row .col-md-4:nth-child(n+7),
  .det-packages .packagesbxs .col-md-3:nth-child(n+5) {
      display: block !important
  }

  footer .social-icons.padd-btd {
      padding: 10px 0
  }

  .logopackages-p .det-packages .tabscontent .col-md-4:nth-child(n+4) .boxpackages,
  .det-packages .boxpackages {
      margin: 20px auto 20px;
      padding: 10px 10px;
      max-width: 100%;
      clear: both;
      overflow: hidden;
      float: none
  }

  .det-packages .boxpackages .packheads h3 {
      font-size: 22px
  }

  .responsive-slider {
      clear: both;
  }

  .pacbundles-det .headbx-bundle .packbundleprice {
      margin: -10px 0 0;
  }

  .pacbundles-det .headbx-bundle .fleft h4 {
      padding-top: 10px;
  }

  .enterprices-bundles .sechead {
      margin-bottom: 20px;
  }

  .cbp-spmenu-vertical {
      z-index: 99999
  }

  .det-packages .boxpackages:hover {
      transform: scale(1.04);
  }

  .packagesall .portfolio-mmbx .leftcont-ban {
      margin: 0 auto;
      display: none;
  }

  .packagesall .portfolio-mmbx .leftcont-ban a {
      padding: 7px 20px;
  }

  .portfolio-mmbx .leftcont-ban a {
      padding: 6px 20px;
  }

  .tabs-portfolio .slick-dotted.slick-slider {
      margin-bottom: 10px;
  }

  .slick-dots {
      bottom: -20px;
  }

  .portfoliomaindivs.tabsmainport .slick-dots {
      bottom: 0px;
  }

  .home-p .det-packages .tabscontent .col-md-4:nth-child(n+4) .boxpackages {
      display: block
  }

  .custom-popup h3 {
      font-size: 28px;
  }

  .custom-popup p {
      font-size: 18px;
  }

  .inner-pg .bannermm .leftcont-ban,
  .inner-pages .bannermm .leftcont-ban {
      margin-top: 60px;
  }

  .howworks-mm .designbrief:before,
  .howworks-mm .col-md-3+.col-md-3 .designbrief:after,
  .howworks-mm .col-md-3+.col-md-3+.col-md-3+.col-md-3 .designbrief:before,
  .howworks-mm .col-md-3+.col-md-3+.col-md-3 .designbrief:before {
      display: none
  }

  .what-offer ul li div {
      min-height: 206px;
  }

  .howworks-mm .designbrief h3 {
      font-size: 18px;
  }

  .howworks-mm .designbrief p {
      font-size: 14px;
  }

  .how-works {
      padding: 0
  }

  .testimonials .slick-dots {
      display: none !important
  }

  #section08 .portfoliomaindivs.tabsmainport .slick-dots {
      bottom: 12px;
  }

  body.logodesignport-p.inner-pg #section08 .portfoliomaindivs.tabsmainport .slick-dots {
      bottom: 0;
  }

  body.logodesignport-p.inner-pg #section08 .portfolio-mmbx .box-ports {
      margin: 10px auto 24px;
  }

  #section08 .portfolio-mmbx .box-ports {
      margin: 0 auto 24px;
  }

  body.portfolio #section08 .portfolio-mmbx .box-ports {
      margin: 0 auto 60px;
  }

  .web-design .slick-dotted.slick-slider {
      padding-bottom: 20px;
  }

  .testi-a a,
  .leftcont-ban a {
      font-size: 14px;
      height: auto;
      width: auto !important;
      display: inline-block;
  }

  .leftcont-ban a i.btnarow {
      display: none
  }

  .testi-a a:hover,
  .leftcont-ban a:hover {
      width: initial !important;
      display: inline-block;
  }

  #alltest .testi-a a {
      display: inline-block;
      width: auto;
  }

  #alltest .testi-a a:hover {
      display: inline-block;
  }

  .testimonials .col-md-6:nth-child(2n):before {
      display: none
  }

  .testimonials-sec .sechead {
      margin-bottom: 20px;
  }

  .testimo .testimonials-sec .row .col-md-6+.col-md-6 {
      margin-bottom: 0px;
  }

  .web-design .incred-expe .content-incred h4 {
      font-size: 20px;
      line-height: 26px;
  }

  .what-offer.types-main {
      margin-top: 20px
  }

  .types-box h5 {
      padding: 14px 0 5px;
      font-size: 18px;
  }

  .what-offer p {
      font-size: 14px;
      padding: 0px 10px;
  }

  /* .types-box { min-height: 245px;     margin: 10px 0;} */
  .types-box {
      min-height: 261px;
      margin: 10px auto;
      max-width: 260px;
  }

  .web-design .portfoliomaindivs .portfolio-mmbx .col-md-4:nth-child(n+7) {
      display: block
  }

  .web-design .responsive-slider .slick-dots {
      bottom: 5px
  }

  .animation-service .srvc-box .srvc-lft img,
  .animation-service .srvc-box .srvc-lft video {
      width: 80px;
      margin-bottom: 0px;
      margin-right: 12px;
      height: auto
  }

  .animation-service .srvc-box .srvc-rht h4 {
      font-size: 18px;
      margin: 0
  }

  .animation-service .srvc-box .srvc-rht p {
      font-size: 12px;
      display: flex;
  }

  .animation-service .srvc-box {
      padding: 10px 10px;
      min-height: 150px;
      margin: 0 0 10px;
      position: relative;
  }

  .animation-service .srvc-box .srvc-rht a {
      position: absolute;
      bottom: 8px;
      left: 50%;
      margin: auto;
      transform: translateX(-50%);
  }

  .animation .animation-service {
      padding-bottom: 10px;
  }

  .pricerange-main {
      padding: 0px 0 10px;
  }

  .mainpriceng-count {
      margin-top: 20px;
  }

  .mainvideo-dur {
      clear: both;
      padding: 5px 10px;
  }

  .animation-pricing {
      padding: 10px 10px;
      margin-top: 13px;
  }

  .pricerange-main .video-qty {
      padding: 5px 10px;
      margin-top: 8px
  }

  .pricerange-main .video-duration {
      margin: 0
  }

  input[type=range] {
      max-width: 320px;
      margin: 0 auto 5px;
  }

  .pricerange-main .video-qty h6,
  .pricerange-main .video-qty label {
      margin-bottom: 13px;
  }

  .pricerange-main .video-qty #videoqty {
      margin: 0 10px 0 0;
      padding: 3px 5px;
  }

  .pricerange-main .leftcont-ban {
      margin: 0 auto;
      text-align: center
  }

  .mainpriceng-count .leftcont-ban a {
      margin-top: 28px
  }

  .video-development {
      padding: 20px 0;
  }

  .video-development .vd-devp-fr h2 {
      font-size: 28px;
      margin: 5px 0;
  }

  .video-development .vd-bef:before,
  .video-development .vd-devp span {
      display: none
  }

  .video-development .vd-devp>div {
      padding: 10px 10px;
      min-height: auto;
      max-width: 276px;
      margin: 0 auto 10px;
      text-align: center
  }

  .video-development img {
      margin: 0 auto
  }

  .video-development .padd-tp {
      padding-top: 10px;
  }

  .video-development {
      min-height: auto
  }

  .branding .branding-portfolio .bg1-description {
      flex-wrap: wrap-reverse
  }

  .branding .branding-portfolio .c-tabs-description {
      padding: 0
  }

  .c-tabs .col-md-3 h3 {
      font-size: 22px;
      line-height: normal;
  }

  .c-tabs .col-md-3 p {
      font-size: 13px
  }

  .c-tabs .tabs-list li a {
      font-size: 14px;
      padding: 8px 14px;
  }

  .branding-portfolio {
      padding: 20px 0;
  }

  .branding .branding-portfolio .tab-content .bg1-description {
      padding: 10px 0 0;
  }

  .branding-portfolio .c-tabs-description h3 {
      font-size: 20px;
      margin: 10px 0 5px
  }

  .branding .branding-portfolio .c-tabs-description p {
      font-size: 13px;
      line-height: 21px;
      min-height: auto
  }

  .branding .branding-portfolio .c-tabs-description a {
      padding: 5px 30px;
      font-size: 16px;
  }

  .branding .branding-portfolio .bg1-description img {
      max-width: 225px;
      margin: -48px 0 0 auto;
      display: block
  }

  .tabscontent {
      clear: both;
  }

  .incred-expe .padtop150 {
      padding-top: 20px;
  }

  .about-phylosophy-bg .phylosophy-box {
      padding: 0;
      margin: 20px 0;
  }

  .about-phylosophy-bg .phylosophy-box i {
      left: -10px;
  }

  .about-phylosophy-bg {
      min-height: auto
  }

  .accordion-faq a.accord-tab {
      padding: 20px 10px;
      font-size: 14px;
      min-height: auto
  }

  .accordion-faq>li:after {
      font-size: 24px
  }

  .sitemap-main .termcndtn ul li {
      font-size: 14px;
      padding-left: 14px;
  }

  /* .sitemap-main .sp-section h3 { font-size: 16px;} */
  .sitemap-main .termcndtn ul li:before {
      top: 3px;
      margin: 5px 0
  }

  .sitemap-main.stp .termcndtn ul li:before {
      top: 11px;
      margin: 5px 0
  }

  .sitemap-main .termcndtn ul li:before {
      width: 4px;
      height: 4px;
  }

  .sitemap-main .col-sm-5ths {
      padding: 0;
  }

  .sitemap-main .sp-section {
      min-height: auto
  }

  body.sitemap-main .sec-pad.termcndtn.sp-section .row {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      flex-wrap: wrap;
      height: 560px;
  }

  .contact-form-main .red-bg h3 {
      font-size: 22px;
      margin: 0px 0 10px;
  }

  .contact-form-main .red-bg {
      padding: 10px 15px;
      width: 100%;
      text-align: center;
  }

  .contact-form-main .red-bg h4 {
      font-size: 18px;
      margin: 0px 0 8px 0;
      border-bottom: 1px solid #fff;
      padding: 0;
      display: inline-block
  }

  .contact-form-main .gray-bg h4 {
      font-size: 20px;
      margin: 0px 0 15px 0;
      border-bottom: 1px solid #fff;
      padding: 0;
      display: block;
      text-align: center
  }

  .contact-form-main .red-bg p {
      margin: 0px 0 16px 0;
      font-size: 16px;
  }

  .contact-form-main .red-bg h4:before {
      display: none
  }

  .contact-form-main .red-bg .social-icons>div {
      margin: 10px 0 0
  }

  .contact-form-main .gray-bg {
      padding: 15px 16px;
      width: 100%;
  }

  .contact-form-main .gray-bg .field {
      margin: 0 10px 15px 0;
      width: calc(50% - 10px);
  }

  /* .contact-form-main .gray-bg .field input[type="text"], .contact-form-main .gray-bg .field textarea, .contact-form-main .gray-bg .field select{     padding: 0 16.5px 0 30px; height: 33px; font-size: 13px; } */
  .contact-form-main .mm-field.phone fieldset {
      top: 6px;
  }

  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input,
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=text],
  .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-5 input[type=tel] {
      padding-left: 93px;
  }

  .contact-form-main .gray-bg .field input[type="text"],
  .contact-form-main .gray-bg .field textarea,
  .contact-form-main .gray-bg .field select {
      padding: 8px 16.5px 8px 30px;
      height: auto;
      font-size: 13px;
  }

  .fixedform-1 .input-btn>input {
      padding: 7px 10px;
      font-size: 16px;
  }

  .fixedform-1 .input-btn::after {
      display: none
  }

  .mm-field.email fieldset,
  .mm-field.name fieldset {
      top: 8px;
      left: 37px;
  }

  .contact-form-main .gray-bg .field i.email {
      top: 12px
  }

  .contact-form-main .gray-bg .field i.user {
      top: 9px
  }

  .contact-form-main .gray-bg .field i {
      top: 8px
  }

  .contact-form-main .red-bg {
      min-height: auto;
      padding: 10px 15px 20px;
  }

  .contact-main {
      margin: 10px 0 20px 0;
  }

  .contact .sechead {
      margin-bottom: 30px;
  }

  .fixedform-1 .jform .mm-field.namefield::before {
      transform: scale(0.8) translateX(-3px);
      height: 41px;
      background-position: -712px -1px;
  }

  .fixedform-1 .jform .mm-field.emailfield::before {
      transform: scale(0.8) translateX(-3px);
      height: 41px;
      background-position: -713px -41px;
  }

  .fixedform-1 .jform .mm-field.selectsertvice::before {
      transform: scale(0.8) translateX(-3px);
      height: 41px;
      background-position: -756px -1px;
  }

  .fixedform-1 .mm-field fieldset.active {
      left: 37px;
      font-size: 9px;
  }

  .fixedform-1 fieldset {
      top: 12px;
      font-size: 14px;
      left: 40px;

  }

  .fixedform-1 .mm-field input[type=text],
  .fixedform-1 .mm-field select,
  .fixedform-1 .mm-field input,
  .fixedform-1 .mm-field textarea,
  [type=email] {
      padding: 10px 8px 10px 40px;
  }

  .fixedform-1 .mm-field {
      margin-bottom: 10px;
  }

  .custom-popup {
      overflow-y: scroll;
      display: block
  }

  .howworks-mm .designbrief:hover .processicons {
      transform: scale(1);
  }
}

@media (max-width: 920px) {
  .logo img {
      margin-left: 0
  }

  header .logo {
      margin: 0 auto
  }

  a.toggle-menu:before {
      left: 20px;
  }
}

@media (max-width: 767px) {
  .nft-design .vd-devp.r-heigt>div {
      min-height: 366px;
  }
  .what-offer>div>ul>li{
    width: 100%;
    margin-bottom: 20px;
  }

  .sitemap-main.stp .termcndtn ul li:before {
      top: 6px;
  }

  .contact-form-main .red-bg .social-icons i {
      width: 30px;
      height: 30px;
      font-size: 14px;
  }

  .sm-hidden {
      display: none !important
  }

  .content-incred {
      text-align: center;
  }

  .leftcont-ban {
      max-width: 310px;
  }

  .cookie-bar p a {
      clear: both;
      float: initial !important;
  }

  .cookie-btn-3 {
      position: absolute;
      left: 10px;
      top: 0;
  }

  #allport .row {
      display: block;
  }

  .cookie-btn-3 {
      margin-right: 20px;
  }

  .no-found {
      min-height: 400px;
  }

  .no-found .noFound-content h1 {
      font-size: 80px;
  }

  .no-found .noFound-content h3 {
      font-size: 30px;
  }

  .contact-form-main .gray-bg .field {
      width: 100%;
  }

  .fixedform.fixed-forms-main {
      display: none
  }

  .testimonials .testi .testi-img {
      min-height: 125px;
  }

  .bannermm {
      min-height: 440px
  }

  .sitemap-main .bannermm .leftcont-ban h1 strong,
  .trms-condtons .bannermm .leftcont-ban h1 strong,
  .prvcy-securty .bannermm .leftcont-ban h1 strong,
  .bannermm .leftcont-ban h1 strong {
      font-size: 24px;
  }

  .sitemap-main .bannermm .leftcont-ban {
      margin-top: 50px;
  }

  .sitemap-main .termcndtn {
      padding: 0
  }

  .sitemap-main .termcndtn ul li {
      font-size: 12px
  }

  .sitemap-main .termcndtn ul li:before {
      width: 4px;
      height: 4px;
  }

  .sitemap-main .bannermm {
      min-height: 150px;
  }

  .charAnimation .bannermm h1 strong {
      font-size: 24px !important;
  }

  .bannermm .leftcont-ban h1,
  .bannermm .leftcont-ban h1 span {
      font-size: 22px
  }

  .bannermm .leftcont-ban h1 {
      padding: 5px 0
  }

  body .bannermm .leftcont-ban p {
      font-size: 13px;
      font-weight: 500 !important
  }

  .what-offer>div>ul {
      margin: 10px 0 0;
  }

  .what-offer .slick-dots {
      bottom: -27px;
  }
  
  .maincta {
      text-align: center;
      padding: 10px 0 0;
  }

  .maincta .btn-radius-top {
      float: none
  }

  .hovereffects-det h3 {
      font-size: 14px;
  }

  .btnstyles-nn a {
      padding: 2px 11px;
      font-size: 12px;
      margin: 2px 0
  }

  .toptabs1 li span,
  .toptabs1 li a {
      padding: 5px 0px;
      font-size: 14px;
      margin-bottom: 8px
  }

  .sitemap-main .termcndtn p strong,
  .sechead h3 {
      font-size: 22px;
      margin: 5px 0;
      padding: 10px 0 0;
  }

  .enterprices-bundles h3:before {
      height: 2px
  }

  .sechead p {
      font-size: 14px;
  }

  .pacbundles-det .headbx-bundle .fleft h4 {
      font-size: 15px
  }

  .pacbundles-det .headbx-bundle .packbundleprice {
      padding: 0 0 0 8px;
      font-size: 14px;
      padding-left: 10px;
      margin-top: -10px
  }

  .pacbundles-det .headbx-bundle .packbundleprice span {
      font-size: 18px
  }

  .pacbundles-det .headbx-bundle .packbundleprice:before {
      top: 0;
      margin: auto;
  }

  .pacbundles-det .headbx-bundle ul.listbundles-packbx {
      padding: 0px 0 15px;
  }

  .pacbundles-det .headbx-bundle ul.listbundles-packbx>li {
      padding: 4px 16px;
      font-size: 11px;
  }

  .pacbundles-det .headbx-bundle ul.listbundles-packbx>li:before {
      font-size: 11px
  }

  .enterprices-bundles .totalpackagespricess h4 {
      font-size: 16px;
  }

  .enterprices-bundles .totalpackagespricess .yellcol {
      font-size: 20px
  }

  .enterprices-bundles .totalpackagespricess h4 b:before {
      width: 93%;
      left: -2px;
      top: 8px;
  }

  .enterprices-bundles .totalpackagespricess .btn-orders-bundles a {
      padding: 3px 16px;
      font-size: 14px;
      margin: 0
  }

  .enterprices-bundles .totalpackagespricess .btn-orders-bundles p {
      font-size: 12px;
  }

  .testimonials h3 {
      font-size: 24px;
      margin-bottom: 5px
  }

  p {
      font-size: 14px;
  }

  .testimonials .testi .testi-img img {
      margin-right: 10px;
      margin-bottom: 5px;
      max-width: 60%
  }

  .testimonials .testi .testi-img h4 {
      font-size: 18px;
      margin-bottom: 5px;
  }

  .testimonials .testi .testi-img p {
      font-size: 15px;
  }

  .testimonials .testi .testi-img::before {
      transform: scale(0.7) translateX(6px);
  }

  .testimonials .testi .testi-desc i,
  .testimonials .testi .testi-desc ul li {
      font-size: 10px;
  }

  .testimonials .testi .testi-desc i,
  .testimonials .testi .testi-desc ul li {
      font-size: 14px;
  }

  .testimonials .testi .testi-desc .verify {
      transform: scale(0.8) translate(2px, -2px);
  }

  footer {
      padding: 5px 0 0
  }

  footer h3 {
      font-size: 12px;
      margin: 15px 0 5px
  }

  footer ul li {
      padding: 2px 0 2px 9px;
  }

  footer ul li::before {
      top: 5px;
  }

  footer .social-icons.padd-btd {
      padding: 0px 0 10px
  }

  footer .brdr-top .subcribe h4,
  footer .brdr-top .social-icons h4 {
      font-size: 16px
  }

  footer .brdr-top .social-icons i {
      font-size: 16px;
      width: 37px;
      height: 37px;
  }

  footer .brdr-top .subcribe input {
      margin-top: 10px;
  }

  footer .brdr-top .subcribe input {
      padding: 10px 30px;
      font-size: 11px;
      max-width: 320px;
  }

  footer .brdr-top .subcribe i {
      top: 47px;
      left: 25px;
      transform: scale(0.8);
  }

  footer .brdr-top .subcribe button {
      padding: 5px 23px;
      font-size: 12px;
      left: 241px
  }

  .footer-bottom {
      padding: 5px 0
  }

  .footer-bottom h4 {
      font-size: 14px;
  }

  .footer-bottom p {
      font-size: 11px;
  }

  footer .padd-btd>img {
      padding-top: 20px
  }

  footer .brdr-top {
      padding: 10px 0px 0px 0;
      border: none
  }

  footer .listpge {
      display: none !important
  }

  .maincta h3 strong {
      font-size: 20px;
  }

  .maincta h3 {
      font-size: 16px;
  }

  .maincta p {
      font-size: 12px;
  }

  .maincta .callsdivs {
      font-size: 13px;
      padding: 10px 0
  }

  .maincta .callsdivs .iconphone {
      transform: scale(0.6) translateX(5px);
  }

  .maincta .btn-radius-top a {
      padding: 4px 7px;
      font-size: 10px;
  }

  .what-offer ul li div {
      padding: 7px 16px 0;
      min-height: 116px;
      position: relative;
  }

  .what-offer ul li div h3 {
      font-size: 16px;
  }

  .what-offer ul li div p {
      font-size: 13px;
  }

  .what-offer ul li div a {
      font-size: 13px;
      margin: 10px 0;
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: auto;
      color: #ea333f;
      font-weight: 600
  }

  .what-offer h3 {
      font-size: 22px;
      margin: 10px 0 5px;
  }

  .what-offer p {
      font-size: 14px;
  }

  .what-offer>div>ul {
      margin: 0;
      display: flex;
      flex-direction: column;
  }

  /* .bannermm a.logobtnsstart,  .bannermm .leftcont-ban a,  .mainhome .bannermm.webbanner .leftcont-ban a, .mainhome .bannermm.animbanner .leftcont-ban a{    } */
  .leftcont-ban a {
      font-size: 13px;
      height: auto !important;
      padding: 6px 10px;
      width: auto !important;
      background: #fff;
      color: #242424;
      display: inline-block;
      font-weight: 600
  }
  .what-offer>div>ul>li div{
    width: 100%;
    padding: 0 25px;
    position: relative;
    z-index: 2;
    /* padding: 25px 60px 10px; */
    min-height: 191px;
    width: 191px;
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-flow: column;
    transition: all 0.3s ease-out;
    justify-content: center;
  }

  .leftcont-ban a i.btnarow {
      display: none !important
  }

  .mainhome .bannermm .leftcont-ban a:hover,
  .mainhome .bannermm.animbanner .leftcont-ban a:hover,
  .mainhome .bannermm.webbanner .leftcont-ban a:hover {
      width: auto;
      color: #242424
  }

  .portfolio-mmbx .leftcont-ban a {
      width: 128px;
      height: 27px;
      font-size: 12px;
  }

  .leftcont-ban a {
      padding: 5px 12px;
  }

  .portfolio-mmbx .leftcont-ban a:hover {
      width: 180px;
  }

  .det-packages .sechead h3 {
      font-size: 20px;
  }

  .bannermm a.logobtnsstart:hover {
      width: 268px;
  }

  .inner-pg .bannermm .leftcont-ban h1 {
      font-size: 18px;
  }

  body.inner-pg .bannermm .leftcont-ban p {
      font-size: 14px;
      padding: 5px 0
  }

  .enterprices-bundles .totalpackagespricess {
      margin: 0;
      padding: 0
  }

  .enterprices-bundles .sechead {
      margin: 0
  }

  .enterprices-bundles h3:before {
      display: none
  }

  .portfoliomaindivs .tabschildrens {
      margin-top: 0
  }

  .det-packages .boxpackages .packheads h3 {
      font-size: 18px;
      margin: 0px 0 2px;
  }

  .det-packages .boxpackages .packheads p {
      font-size: 11px;
      min-height: auto;
  }

  .boxpackages .packdetails .packtitles .fleft h3 {
      font-size: 24px;
  }

  .packdetails .packtitles .fright h4 {
      font-size: 12px;
      margin: -2px 0 0;
      line-height: 12px
  }

  .packdetails .packtitles .fright h4 b:before {
      top: 11px;
      left: -3px;
  }

  .packdetails .listpacks {
      padding: 10px 0;
  }

  .packdetails .listpacks li {
      font-size: 11px;
      padding: 5px 16px;
  }

  .packdetails .listpacks li:before {
      font-size: 11px;
      top: 8px
  }

  .packdetails .botarea-pack {
      padding: 5px 0 0;
  }

  .packdetails .botarea-pack a.btn-orders {
      padding: 3px 7px;
      font-size: 11px;
  }

  .packdetails .botarea-pack .liovechats-bx a span:before {
      font-size: 13px;
      right: -15px;
  }

  .boxpackages .packdetails .listpacks h3 {
      font-size: 14px;
      margin: 10px 0 5px
  }

  .packdetails .botarea-pack .liovechats-bx a h5 {
      font-size: 12px;
  }

  .packdetails .listpacks {
      height: 200px;
  }

  .det-packages .boxpackages:hover {
      transform: scale(1.05);
  }

  .mCSB_outside+.mCSB_scrollTools {
      right: -16px;
  }

  .logo img {
      width: 262px;
      /* margin-left: 30px; */
  }

  header .logo {
      padding-top: 22px;
  }

  a.toggle-menu:before {
      font-size: 25px;
      left: 26px;
  }

  .cbp-spmenu h3 {
      color: #fff;
      font-size: 1.4em;
      padding: 14px;
  }

  .cbp-spmenu-vertical a {
      font-size: 14px;
      padding: 12px 10px;
  }

  .dropdown .lstnavig .liststylss>li>a {
      padding: 5px 0 5px 25px;
      font-size: 11px;
  }

  .dropdown .lstnavig .liststylss>li>a:after {
      top: 0px;
      font-size: 10px;
  }

  .what-offer ul li div h3 br {
      display: none
  }

  .portfolio-mmbx .box-ports {
      margin: 0 auto 40px;
  }

  .bannermm .container {
      margin-left: 0px
  }

  .maincta .callsdivs {
      text-align: center
  }

  .howworks-mm .designbrief h3 {
      font-size: 17px;
  }

  .howworks-mm .designbrief p {
      font-size: 13px;
  }

  .howworks-mm .designbrief .processicons {
      transform: scale(0.7) !important;
  }

  .howworks-mm .designbrief {
      margin: -28px 0 0 0;
  }

  .howworks-mm .designbrief h3 {
      margin-top: -17px;
  }

  .brdcrme li {
      padding: 6px 12px;
  }

  .brdcrme li+li:before {
      top: 5px;
  }

  .what-offer ul li div {
      padding: 7px 10px 0;
      min-height: 190px;
  }

  .leftcont-ban a {
      margin: 5px 0 0px;
  }

  .incred-expe .content-incred h3 {
      font-size: 24px !important;
  }

  .inner-pg .bannermm .leftcont-ban,
  .inner-pages .bannermm .leftcont-ban {
      margin-top: 20px;
  }

  .types-box h5 {
      padding: 10px 0 5px;
      font-size: 16px;
  }

  .animation-service .srvc-box {
      max-width: 345px;
      margin: 0 auto 10px;
  }

  .pricerange-main .pricingbox .iconsbx {
      transform: scale(0.6);
  }

  .pricerange-main .pricingbox li h3 {
      margin-top: -13px;
      margin-bottom: 8px;
      line-height: normal
  }

  .pricerange-main .pricingbox li a {
      margin-top: -18px;
  }

  .mainvideo-dur {
      padding: 0 10px
  }

  .pricerange-main .video-duration h6 {
      font-size: 16px;
  }

  input[type=range] {
      max-width: 300px;
  }

  .slider {
      height: 18px;
  }

  .slider::-webkit-slider-thumb {
      height: 18px;
      border-width: 2px
  }

  .animation-pricing {
      margin-top: 8px;
      padding: 5px 0;
  }

  .animation-pricing h6 {
      font-size: 14px;
      margin: 0px 0 5px;
  }

  .animation-pricing h5 {
      font-size: 18px;
  }

  .pricerange-main .video-qty {
      padding: 4px 0px
  }

  .pricerange-main .video-qty h6,
  .pricerange-main .video-qty label {
      margin-bottom: 12px;
      font-size: 16px;
  }

  .pricerange-main .video-qty #videoqty {
      margin: 0 5px 0 0;
      padding: 0px 5px;
  }

  .mainpriceng-count .leftcont-ban a {
      margin-top: 15px;
  }

  .video-development .vd-devp-fr h2 {
      font-size: 20px;
  }

  .video-development {
      padding: 10px 0;
  }

  .video-development .vd-devp-fr p {
      font-size: 13px;
  }

  .testimonials,
  .incred-expe {
      padding: 10px 0 10px;
  }

  .branding-portfolio>div>div.row {
      margin: 0
  }

  .branding-portfolio {
      padding: 10px 0;
      text-align: center
  }

  .branding-portfolio .nopadd-l {
      padding-left: 15px;
  }

  .c-tabs .col-md-3 h3 {
      font-size: 20px;
      margin: 5px 0 0
  }

  .c-tabs .col-md-3 p {
      font-size: 14px;
      margin: 5px 0;
  }

  .c-tabs .tabs-list {
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      text-align: center
  }

  .c-tabs .tabs-list li a {
      font-size: 14px;
      padding: 6px 10px;
      border-radius: 8px
  }

  .c-tabs .tabs-list li a:before {
      display: none
  }

  .c-tabs .tabs-list li {
      width: 33.3333%;
      padding: 3px 4px;
      text-align: center;
      margin: 0 auto
  }

  .c-tabs .tabs-list li:nth-child(1) a {
      border: 1px solid #111111
  }

  .c-tabs .tabs-list li:nth-child(2) a {
      border: 1px solid #904d83
  }

  .c-tabs .tabs-list li:nth-child(3) a {
      border: 1px solid #68797a
  }

  .c-tabs .tabs-list li:nth-child(4) a {
      border: 1px solid #452b78
  }

  .c-tabs .tabs-list li.active:nth-child(1) a {
      background: #111111
  }

  .c-tabs .tabs-list li.active:nth-child(2) a {
      background: #904d83
  }

  .c-tabs .tabs-list li.active:nth-child(3) a {
      background: #68797a
  }

  .c-tabs .tabs-list li.active:nth-child(4) a {
      background: #452b78
  }

  .branding-portfolio .c-tabs .tab-content.active {
      margin-left: 0;
  }

  .incred-expe .padtop150 {
      padding-top: 0px;
  }

  body.inner-pages .bannermm .leftcont-ban h1 {
      margin-top: 0;
  }

  .about-phylosophy-bg .phylosophy-box:after {
      display: none
  }

  .gettouchfaqs h3 {
      font-size: 22px;
      text-align: center
  }

  .gettouchfaqs {
      padding: 10px 0;
  }

  .gettouchfaqs .packagesbxs .toptabs1 {
      text-align: center
  }

  .gettouchfaqs .packagesbxs .toptabs1 a {
      padding: 5px 15px;
      font-size: 13px;
      margin-bottom: 7px
  }

  .gettouchfaqs .packagesbxs {
      padding: 10px 0
  }

  .accordion-faq a.accord-tab {
      padding: 10px 10px;
      font-size: 12px;
  }

  .accordion-faq>li:after {
      font-size: 18px;
  }

  .sitemap-main .termcndtn br {
      display: none
  }

  .sitemap-main .termcndtn p strong {
      margin-top: 5px
  }

  .ulsme-ctct ul li+li::before {
      font-size: 10px
  }

  body.sitemap-main .sec-pad.termcndtn.sp-section .row {
      padding: 0 10px;
      height: 555px;
  }

  .sitemap-main .col-sm-5ths {
      width: 33.3333%;
      padding-left: 5px;
      padding-right: 5px;
  }

  .sitemap-main .sp-section h3 {
      font-size: 16px;
      margin: 10px 0 5px
  }

  .sitemap-main .termcndtn ul li {
      font-size: 14px;
      padding: 3px 5px 3px 14px;
  }

  .sitemap-main .termcndtn ul li:before {
      top: 6px;
  }

  body.sitemap-main .termcndtn {
      padding: 10px 0
  }

  .contact-form-main .red-bg {
      padding: 6px 10px
  }

  .contact-form-main .red-bg h3 {
      font-size: 20px;
      margin: 0px 0 8px;
  }

  .contact-form-main .red-bg h4 {
      font-size: 16px
  }

  .contact-form-main .red-bg p {
      margin: 0px auto 6px;
      font-size: 14px;
      max-width: 210px;
  }

  .contact-form-main .red-bg .social-icons>div {
      margin: 5px 0
  }

  .contact-main {
      margin: 0 0 20px
  }

  .contact .sechead {
      margin-bottom: 0px;
  }

  .custom-popup h3 {
      font-size: 22px !important;
  }

  .custom-popup p {
      font-size: 17px !important;
  }

  .custom-popup a.closed::before {
      padding: 0 6px !important;
      top: -41px !important;
      right: 0 !important;
      font-size: 17px !important;
  }

  .custom-popup>div {
      padding: 51px 20px 20px !important;
  }

  .packages-wrapper .nav {
      flex-wrap: wrap;
  }

  .packages-wrapper .nav li a {
      margin-bottom: 15px;
  }

  .incred-expe .content-incred p {
      font-size: 14px;
      line-height: 23px;
      margin-top: 20px;
  }

  @media (max-width: 599px) {

      .what-offer ul li div {
          padding: 7px 10px 0;
          min-height: 170px;
      }

      .articl-dtail {
          width: 92%;
      }

      .art-imbx {
          width: 100%;
      }

      .article {
          margin-bottom: 40px;
      }

      .main-artcl {
          margin-top: 30px;
      }
      .topnavs{
        display: none;
      }
      header .main-nav ul li{
        display: block;
      }
      header .main-nav ul>li>a{
        font-size: 14px;
        padding: 0px 22px 26px;
      }
      header{
        height: 65px;
      }
  }

  /*media 599px End*/

  @media (max-width: 480px) {
      .sitemap-main.stp .termcndtn ul li:before {
          top: 6px;
      }

      .homeport-mm .leftcont-ban {
          max-width: initial;
      }

      .video-development .vd-devp-fr h2 {
          font-size: 18px;
      }

      .incred-expe .content-incred p {
          font-size: 13px;
      }

      .about-phylosophy-bg .phylosophy-box i {
          transform: scale(0.8);
          margin: -28px auto;
      }

      .web-design .slick-dotted.slick-slider {
          margin-bottom: 40px;
      }

      .bannermm .leftcont-ban h1,
      .bannermm .leftcont-ban h1 span {
          font-size: 16px
      }

      .leftcont-ban {
          max-width: 225px;
      }

      .custom-popup h3 {
          font-size: 20px;
      }

      .custom-popup p {
          font-size: 16px;
      }

      .fixedform-1 .input-btn>input {
          padding: 6px 0;
          font-size: 14px;
      }

      .fixedform-1 .input-btn {
          width: 80%;
      }

      .fixedform-1 form {
          margin: 10px 0;
      }

      .fixedform-1 .mm-field textarea {
          padding-left: 14px;
      }

      .testimonials .testi .testi-desc i,
      .testimonials .testi .testi-desc ul li {
          font-size: 11px;
      }

      .logopackages-p .det-packages .tabscontent .col-md-4:nth-child(n+4) .boxpackages,
      .det-packages .boxpackages {
          max-width: 90%;
          margin: 20px auto 37px;
      }

      .packages-wrapper .nav {
          margin: 20px 0 10px;
      }

      .enterprices-bundles .totalpackagespricess {
          text-align: center;
      }

      .enterprices-bundles .totalpackagespricess .btn-orders-bundles {
          float: none;
      }

      .bannermm {
          min-height: 335px;
      }

      .portfolio-mmbx.edit-1 .slick-dots {
          bottom: -2px
      }

      body.mainhome .bannermm .leftcont-ban h1 {
          margin: 65px 0 0;
          text-shadow: 1px 1px 1px #000;
      }

      body.inner-pg .bannermm .leftcont-ban h1 {
          margin: 65px 0 0;
          max-width: 100%;
          font-size: 16px
      }

      .bannermm .leftcont-ban h1,
      .bannermm .leftcont-ban h1 span {
          font-size: 16px;
      }
      .contact-form-main .red-bg{
        width: 100% !important; 
      }
      .contact-form-main{
        margin: 0 !important;
      }
      .continue{
     
        width: 100% !important;
       
      }
      .modal-box{
        width: 70% !important;
        height: 60% !important;
      }
      .contact-form-main .gray-bg{
        width: 100% !important;
      }

      .bannermm .leftcont-ban h1 strong {
          font-size: 18px;
          margin: 2px 0
      }

      .charAnimation .bannermm h1 strong {
          font-size: 18px !important;
      }

      body.mainhome .bannermm .leftcont-ban p {
          font-size: 12px;
          max-width: 255px;
          margin: 5px 0;
          text-shadow: 1px 1px 1px #000;
      }

      body.inner-pg .bannermm .leftcont-ban p {
          font-size: 14px;
          max-width: 100%;
          margin: 5px 0
      }

      .leftcont-ban a {
          margin: 5px 0 45px;
          text-shadow: none;
      }

      .bannermm a.logobtnsstart,
      .mainhome .bannermm .leftcont-ban a,
      .mainhome .bannermm.animbanner .leftcont-ban a,
      .mainhome .bannermm.webbanner .leftcont-ban a {
          background: #fff;
          color: #242424;
          width: auto;
          height: auto;
          padding: 4px 10px;
          font-size: 10px;
          font-weight: 600;
          display: inline-block;
      }

      .mainhome .bannermm .leftcont-ban a:hover,
      .mainhome .bannermm.animbanner .leftcont-ban a:hover,
      .mainhome .bannermm.webbanner .leftcont-ban a:hover {
          width: auto;
      }

      /* .leftcont-ban{ margin-left: -10px } */
      .leftcont-ban a i.btnarow {
          display: none
      }

      .logo img {
          width: 170px;
          margin: none;
      }

      a.toggle-menu:before {
          font-size: 22px;
          top: 14px;
      }

      .incred-expe .content-incred h3 {
          font-size: 18px !important;
          margin-bottom: 5px;
      }

      .mainhome .incred-expe .content-incred h3 {
          font-size: 22px !important;
          margin-bottom: 10px;
      }

      .incred-expe .content-incred .fnt-18 {
          margin-bottom: 10px;
          font-size: 15px;
      }

      p {
          font-size: 13px;
          line-height: normal;
          margin-bottom: 5px;
      }

      .what-offer h3 {
          font-size: 18px;
          margin: 15px 0 3px;
      }

      .what-offer p {
          font-size: 13px;
          padding: 2px 0 5px;
      }

      .what-offer ul li div {
          padding: 9px 8px 0;
          min-height: 150px;
          margin: 0 auto;
      }

      .maincta h3 {
          font-size: 16px;
      }

      .maincta h3 strong {
          font-size: 18px;
      }

      .maincta p {
          font-size: 13px;
      }

      .maincta .btn-radius-top a {
          padding: 4px 10px;
          font-size: 11px;
      }

      .maincta .callsdivs {
          font-size: 12px;
          padding: 15px 0 10px;
      }

      .maincta .callsdivs .iconphone {
          transform: scale(0.6) translateX(4px);
      }

      .sitemap-main .termcndtn p strong,
      .sechead h3,
      .sechead h3 {
          font-size: 20px;
          margin: 5px 0;
      }

      .sitemap-main ul {
          margin: 5px 0
      }

      .sechead p {
          font-size: 12px;
      }

      .toptabs1 li {
          margin: 2px 2px;
          width: 45%;
      }

      .toptabs1 {
          margin: 10px 0 0;
      }

      /* .leftcont-ban {
      max-width: inherit;
  } */
      .toptabs1 li span,
      .toptabs1 li a {
          padding: 2px 8px;
          font-size: 10px;
      }

      .hovereffects-det h3 {
          font-size: 18px;
          margin: 5px 0 10px
      }

      .btnstyles-nn a {
          padding: 3px 14px;
          font-size: 14px;
          margin: 0px 0;
          border-width: 1px
      }

      .btnstyles-nn a i {
          display: none
      }

      .portfoliomaindivs .tabschildrens ul li {
          padding: 2px 8px;
      }

      .det-packages .portfoliomaindivs .tabschildrens {
          background: #fff;
          border-top: 1px solid #d1d7e3;
      }

      .portfoliomaindivs .tabschildrens ul li span {
          font-size: 11px;
      }

      .portfolio-mmbx .box-ports {
          max-width: 100%;
          margin: 10px auto 40px;
      }

      .portfolio-mmbx .slick-dotted.slick-slider {
          margin-bottom: 10px;
      }

      .testimonials .packagesall .portfolio-mmbx .leftcont-ban a,
      .packagesall .portfolio-mmbx .leftcont-ban a,
      .portfolio-mmbx .leftcont-ban a,
      .portfolio-mmbx .leftcont-ban a {
          width: auto;
          height: auto;
          font-size: 14px;
          padding: 5px 17px;
          display: inline-block;
      }

      .testimonials .packagesall .portfolio-mmbx .leftcont-ban a:hover,
      .packagesall .portfolio-mmbx .leftcont-ban a:hover,
      .portfolio-mmbx .leftcont-ban a:hover,
      .portfolio-mmbx .leftcont-ban a:hover {
          width: auto;
          background: none;
          color: #ed3e25
      }

      .det-packages .sechead h3 {
          font-size: 18px;
          margin: 5px 0
      }

      .sechead p {
          font-size: 13px;
          padding: 2px 0 5px;
      }

      .toptabs1 li span,
      .toptabs1 li a {
          padding: 3px 0;
          font-size: 13px;
          border-radius: 0
      }

      .portfolio-mmbx {
          margin-top: 5px
      }

      .mainhome .incred-expe .slick-dots,
      .testimonials .slick-dots,
      .incred-expe .slick-dots,
      .det-packages .slick-dots {
          bottom: -10px;
      }

      .inner-pg .incred-expe .slick-dots,
      .testimonials .slick-dots,
      .incred-expe .slick-dots,
      .det-packages .slick-dots {
          bottom: -10px;
      }

      .portfolio-mmbx {
          margin: 0
      }

      .det-packages {
          padding: 0;
      }

      .enterprices-bundles .totalpackagespricess h4 {
          font-size: 14px;
      }

      .enterprices-bundles .totalpackagespricess .yellcol {
          font-size: 16px;
      }

      .enterprices-bundles .totalpackagespricess .btn-orders-bundles a {
          padding: 4px 18px;
          font-size: 13px;
      }

      .enterprices-bundles .totalpackagespricess .btn-orders-bundles p {
          padding: 4px 18px;
          font-size: 14px;
      }

      .testimonials h3 {
          font-size: 20px;
          margin: 3px;
      }

      .testimonials,
      .incred-expe {
          padding: 10px 0 0px;
          display: block;
      }

      .testimonials .testi .testi-img p {
          font-size: 12px;
      }

      .testimonials .testi .testi-img h4 {
          font-size: 14px;
          margin-bottom: 4px;
      }

      .testimonials .testi .testi-img img {
          max-width: 36%;
      }

      .testimonials .testi .testi-img {
          min-height: 123px;
      }

      footer .brdr-top .subcribe h4,
      footer .brdr-top .social-icons h4 {
          font-size: 14px;
      }

      footer .brdr-top .social-icons i {
          font-size: 10px;
          width: 23px;
          height: 23px;
          padding: 5px 0;
          margin: 1px 0;
          border-width: 1px;
      }

      footer .brdr-top .subcribe input {
          padding: 8px 26px;
          font-size: 10px;
      }

      footer .brdr-top .subcribe i {
          top: 42px;
          left: 22px;
          transform: scale(0.7);
      }

      footer .brdr-top .subcribe button {
          padding: 4px 23px;
          font-size: 11px;
          right: 18px;
          max-width: 220px
      }

      .footer-bottom h4 {
          font-size: 13px;
          margin: 5px 0;
      }

      .pacbundles-det .headbx-bundle .fleft h4 {
          font-size: 17px;
          padding-top: 6px
      }

      .pacbundles-det .headbx-bundle .packbundleprice {
          margin-top: 0;
          padding: 0 0 0 8px;
      }

      .pacbundles-det .headbx-bundle .packbundleprice:before {
          display: none
      }

      .pacbundles-det .headbx-bundle ul.listbundles-packbx>li {
          padding: 4px 14px;
          font-size: 13px;
      }

      .pacbundles-det .headbx-bundle ul.listbundles-packbx>li:before {
          font-size: 10px;
      }

      .slick-dots li.slick-active button {
          width: 16px;
          height: 12px;
      }

      .slick-dots li button {
          width: 12px;
          height: 12px;
      }

      .what-offer ul li {
          padding: 0 3px;
      }

      .portfolio-mmbx .box-ports img {
          width: 100%;
      }

      header .logo {
          padding-top: 21px;
      }

      .portfoliomaindivs .topbartabs {
          padding: 0 10px;
      }

      .bannermm .container {
          margin-left: 0
      }

      .inner-pg .bannermm .leftcont-ban,
      .inner-pages .bannermm .leftcont-ban {
          margin-top: 0px;
          text-shadow: 1px 1px 1px #000;
      }

      .howworks-mm .designbrief .processicons {
          transform: scale(0.5) translateX(-5px) !important;
      }

      .howworks-mm .designbrief {
          margin: -47px 0 0 0;
      }

      .howworks-mm .designbrief h3 {
          margin: -41px 0 5px;
          font-size: 14px;
      }

      .howworks-mm {
          margin: 0
      }

      .incred-expe .content-incred h5 {
          font-size: 16px;
          margin: 0 0 9px;
      }

      .brdcrme li {
          padding: 3px 12px;
          font-size: 13px;
      }

      .brdcrme li+li:before {
          top: 4px;
      }

      .footer-bottom {
          padding: 2px 0;
          text-align: center;
      }

      footer .brdr-top {
          padding: 5px 0px 0px 0;
      }

      .testimonials .testi .testi-img::before {
          display: none
      }

      .pricerange-main .pricingbox li {
          width: 50%
      }

      .pricerange-main .pricingbox li h3 {
          margin-top: -15px;
          font-size: 12px;
      }

      .pricerange-main .pricingbox {
          margin: 0
      }

      .pricerange-main .video-duration h6 {
          font-size: 14px;
          margin: 5px 0
      }

      .animation-pricing h6 {
          font-size: 12px;
          margin: 0px 0 0px;
      }

      .animation-pricing h5 {
          font-size: 14px;
      }

      .animation-pricing {
          margin-top: 2px;
      }

      .pricerange-main .video-duration span {
          font-size: 13px;
      }

      input[type=range] {
          margin: 0 auto 2px;
          max-width: 230px;
      }

      .pricerange-main .video-qty h6,
      .pricerange-main .video-qty label {
          margin: 4px 0 8px;
          font-size: 13px;
      }

      .pricerange-main .video-qty #videoqty {
          font-size: 12px;
      }

      .slider {
          height: 15px;
      }

      .slider::-webkit-slider-thumb {
          height: 15px;
          border-width: 1px
      }

      .animation-service .srvc-box .srvc-lft img,
      .animation-service .srvc-box .srvc-lft video {
          width: 50px;
      }

      .animation-service .srvc-box .srvc-rht h4 {
          font-size: 14px;
      }

      .animation-service .srvc-box .srvc-rht p {
          font-size: 12px;
          line-height: normal
      }

      .animation .animation-service {
          margin-top: 10px
      }

      body.inner-pg.animation.logopackages-p .bannermm .leftcont-ban h1 {
          margin: 85px 0 0;
      }

      .branding-portfolio .nopadd-l {
          margin-left: 0
      }

      .c-tabs .tabs-list li a {
          font-size: 11px;
          padding: 4px 8px;
      }

      .branding-portfolio .c-tabs .tab-content {
          margin-left: 0
      }

      .branding-portfolio .c-tabs-description h3 {
          font-size: 18px;
          margin: 2px 0 5px;
      }

      .branding .branding-portfolio .c-tabs-description p {
          font-size: 13px;
          line-height: 20px;
      }

      .branding .branding-portfolio .c-tabs-description a {
          padding: 3px 22px;
          font-size: 14px;
      }

      .branding .branding-portfolio .bg1-description img {
          margin: -15px auto 0;
      }

      .branding-portfolio .nopadd-l {
          padding: 0
      }

      body .bannermm .leftcont-ban p {
          font-size: 12px;
          margin: 5px 0;
      }

      .gettouchfaqs h3 {
          font-size: 18px;
          margin: 0 0
      }

      .gettouchfaqs .packagesbxs {
          padding: 10px
      }

      .gettouchfaqs .packagesbxs .toptabs1 {
          margin-bottom: 0
      }

      .gettouchfaqs .packagesbxs .toptabs1 a {
          padding: 3px 9px;
          font-size: 12px;
          margin-bottom: 4px;
      }

      .accordion-faq a.accord-tab {
          padding: 7px 4px;
          font-size: 11px;
      }

      .accordion-faq>li:after {
          font-size: 15px;
      }

      .sitemap-main .col-sm-5ths {
          width: 50%;
          padding-left: 5px;
          padding-right: 5px;
      }

      .sitemap-main .sp-section h3 {
          font-size: 16px;
          margin: 10px 0 2px
      }

      .sitemap-main .termcndtn ul li {
          font-size: 13px;
          padding: 3px 5px 3px 14px;
      }

      .sitemap-main .termcndtn ul li:before {
          top: 5px;
      }

      body.sitemap-main .termcndtn {
          padding: 5px 0 10px
      }

      body.sitemap-main .sec-pad.termcndtn.sp-section .row {
          height: 676px;
      }

      .col-480-100 {
          width: 100% !important
      }

      .contact-form-main .red-bg h3 {
          font-size: 20px;
          margin: 0px 0 5px;
      }

      .contact-form-main .red-bg h4 {
          font-size: 14px;
          margin: 5px 0 4px;
          line-height: normal
      }

      .contact-form-main .red-bg p {
          margin: 0px auto 5px
      }

      .contact-main {
          margin: 0 0 10px;
      }

      .contact-form-main .gray-bg {
          padding: 10px;
      }

      .contact-form-main .gray-bg h4 {
          font-size: 14px;
          margin: 0px 0 10px 0;
          text-align: center;
          display: block
      }

      .contact-form-main .gray-bg .field input[type="text"],
      .contact-form-main .gray-bg .field textarea,
      .contact-form-main .gray-bg .field select {
          padding: 7px 10px 7px 27px;
          font-size: 12px;
      }

      .contact-form-main .gray-bg .field i.user {
          top: 6px;
          left: 5px;
          transform: scale(0.8);
      }

      .contact-form-main .gray-bg .field i.email {
          top: 9px;
          left: 6px;
          transform: scale(0.9);
      }

      .contact-form-main .gray-bg .field i.select {
          top: 8px;
          left: 5px;
          transform: scale(0.9);
      }

      .mm-field.email fieldset,
      .mm-field.name fieldset {
          top: 6px;
          left: 27px;
          font-size: 12px;
      }

      .intl-tel-input .selected-flag .iti-flag {
          transform: scale(0.8) translateX(-6px);
      }

      .intl-tel-input.separate-dial-code .selected-dial-code {
          font-size: 12px;
      }

      .contact-form-main .mm-field.phone fieldset {
          top: 6px !important;
          left: 90px;
          font-size: 11px;
      }

      .contact-form-main {
          margin: 0
      }

      .contact-form-main .mm-field.textarea textarea {
          padding: 12px 10px;
          height: 130px;
      }

      .contact-form-main .mm-field.textarea fieldset {
          font-size: 11px
      }

      .contact-form-main .mm-field fieldset.active {
          font-size: 7px !important;
          top: 0 !important
      }

      .mm-field.textarea fieldset {
          left: 10px;
          top: 6px;
      }

      .contact-form-main .gray-bg .field {
          margin: 0 0 10px 0;
      }

      .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
      .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text],
      .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
          font-size: 11px
      }

      .contact-form-main .gray-bg .submit {
          margin: 10px auto 0;
          max-width: 150px;
      }

      .contact-form-main .gray-bg .submit:before {
          left: 18px;
          top: 8px;
          transform: scale(0.7) translate(0, -7px)
      }

      .contact-form-main .gray-bg .submit input[type="submit"] {
          height: auto;
          padding: 5px 10px 5px 45px;
          font-size: 13px
      }

      .det-packages .toptabs1 li a {
          border-radius: 0;
      }
  }

  @media (max-width: 360px) {
      footer .brdr-top .subcribe button {
          left: auto;
          right: 21px;
          padding: 4px 16px;
      }

      .howworks-mm .designbrief .processicons {
          transform: scale(0.5) translateX(0px) !important;
      }

      .what-offer ul li div {
          padding: 9px 5px 0;
          min-height: 125px;
      }

      body.sitemap-main .sec-pad.termcndtn.sp-section .row {
          height: auto;
      }

      .sitemap-main .col-sm-5ths {
          width: 100%;
      }
  }

  @media (max-width: 320px) {
      .contact-form-main .mm-field.phone fieldset {
          left: 85px;
          top: 0;
          font-size: 11px;
      }
  }
}
.web-design .incred-expe .content-incred h4 {
  font-size: 24px;
  font-weight: 700;
  color: #ed3e25;
  padding: 0px;
  margin: 0px 0 10px 0;
}

.incred-expe .content-incred h3 {
  font-size: 30px;
  font-weight: 300;
}

.incred-expe .content-incred h5 {
  font-size: 20px;
  font-weight: 700;
  color: #111111;
}

.web-design .bannermm {
  background-image: url(../images/banner-web-design.jpg) !important;
  padding: 150px 0 100px !important;
  min-height: 30vh !important;
}

.web-design .bannermm .leftcont-ban h1 {
  font-size: 35px;
  font-weight: 600;
}

.web-design .bannermm .leftcont-ban p {
  font-size: 20px;
  font-weight: 400;
}

.web-design .slick-dotted.slick-slider {
  padding-bottom: 50px;
  margin-bottom: 0px;
}

/*.web-design .det-packages .boxpackages {margin: 50px 0 0;}*/
.types-box {
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 335px;
  margin: 30px 0 50px 0;
  transition: all 0.3s ease-out;
}

.types-box:hover {
  -webkit-box-shadow: 0px 2px 45px 5px rgba(103, 103, 103, 0.75);
  -moz-box-shadow: 0px 2px 45px 5px rgba(103, 103, 103, 0.75);
  box-shadow: 0px 2px 45px 5px rgba(103, 103, 103, 0.75);
}

.types-box img {
  display: block;
}

.types-box h5 {
  font-size: 22px;
  color: #ff0000;
  font-weight: 600;
  text-align: center;
  padding: 30px 0 10px;
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
}

.types-box p {
  font-size: 14px;
  color: #111111;
  font-weight: 400;
  text-align: center;
  padding: 0px 12px;
  margin: 0px;
}

.web-design.portfolio .portfoliomaindivs .topbartabs {
  border: 0px;
  margin: 0px 0 0 0;
}

.about .bannermm {
  background-image: url(../images/banner-about.jpg);
  padding: 300px 0 20px;
}

.padtop150 {
  padding-top: 150px;
}

.padtop80 {
  padding-top: 80px;
}

.about-phylosophy-bg {
  background: url(../images/about-philosophy.jpg) no-repeat;
  background-size: 100% 100%;
  min-height: 590px;
  clear: both;
}

.about-phylosophy-bg h3 {
  margin: 55px 0 10px 0;
}

.about-phylosophy-bg .phylosophy-box {
  padding: 0px 15px;
  position: relative;
  margin: 50px 0 0 0;
  cursor: pointer;
}

.about-phylosophy-bg .phylosophy-box i {
  width: 172px;
  height: 172px;
  display: block;
  margin: 0px auto;
  background: url(../images/about-philosophy-icons.png) -2px -10px no-repeat;
  position: relative;
  z-index: 2;
}

.about-phylosophy-bg .phylosophy-box h4 {
  font-size: 18px;
  font-weight: 700;
  color: #111111;
  text-align: center;
  padding: 0px;
  margin: 20px 0 15px 0;
  letter-spacing: -0.5px;
}

.about-phylosophy-bg .phylosophy-box p {
  font-size: 14px;
  font-weight: 400;
  color: #242424;
  text-align: center;
  padding: 0;
  margin: 0;
}

.about-phylosophy-bg .phylosophy-box i span {
  position: absolute;
  right: -15px;
  top: 0px;
  font-size: 24px;
  color: #cccccc;
  font-weight: 600;
  font-style: normal;
  display: none;
}

.about-phylosophy-bg .phylosophy-box i span.num-bot {
  top: inherit;
  bottom: 0px;
}

.about-phylosophy-bg .phylosophy-box:hover i span {
  color: #ed3e25;
}

.about-phylosophy-bg .phylosophy-box:after {
  position: absolute;
  right: -90px;
  top: 70px;
  content: '';
  background: url(../images/about-step-sep.png) left top no-repeat;
  width: 173px;
  height: 37px;
  z-index: 1;
}

.about-phylosophy-bg .phylosophy-box.no-sep:after {
  display: none;
}

.about-phylosophy-bg .phylosophy-box.round:after {
  transform: rotate(180deg);
}

.about-phylosophy-bg .phylosophy-box i.two {
  background-position: -190px -10px;
}

.about-phylosophy-bg .phylosophy-box i.three {
  background-position: -374px -10px;
}

.about-phylosophy-bg .phylosophy-box i.four {
  background-position: 0px -192px;
}

.contact .bannermm {
  background-image: url(../images/contact-banner.jpg) !important;
  padding: 200px 0 100px !important;
  min-height: 30vh !important;
}

.contact-main {
  margin: 20px 0 50px 0;
}

.contact .sechead {
  margin-bottom: 50px;
}

.contact .fixedform {
  display: none;
}

.contact-form-main {
  margin: 0 35px;
}

.contact-form-main .gray-bg {
  float: left;
  width: 70%;
  padding: 26px 25px;
  background-color: #f4f4f4;
}

.contact-form-main .gray-bg h4 {
  font-size: 18px;
  font-weight: 700;
  color: #111;
  padding: 0px;
  margin: 0px 0 20px 0;
}

.contact-form-main .gray-bg .field {
  width: calc(50% - 15px);
  float: left;
  margin: 0 15px 21.3px 0;
  position: relative;
  background: #fff;
  border: 1px solid #e6e6e6;
}

.contact-form-main .gray-bg .field:nth-child(odd) {
  margin-right: 0px;
}

.contact-form-main .gray-bg .field i {
  position: absolute;
  left: 10px;
  top: 13px;
  background: url(../images/about-philosophy-icons.png) 0 0 no-repeat;
  width: 17px;
  height: 17px;
  display: block;
}

.contact-form-main .gray-bg .field i.select {
  background-position: -126px -374px;
  transform: translateY(2px);
}

.contact-form-main .gray-bg .field i.user {
  background-position: -3px -375px;
  top: 11px;
}

.contact-form-main .gray-bg .field i.phone {
  background-position: -25px -375px;
}

.contact-form-main .gray-bg .field i.email {
  background-position: -46px -378px;
  top: 14px;
}

.mm-field.email fieldset,
.mm-field.name fieldset {
  left: 41.5px
}

.mm-field.textarea fieldset {
  left: 35px;
}

.mm-field.phone fieldset {
  left: 86px
}

.mm-field.email fieldset.active,
.mm-field.name fieldset.active {
  top: 0px;
  font-size: 9px;
  color: #000
}

.mm-field.textarea fieldset.active {
  top: 1px;
  font-size: 9px;
  color: #000
}

.mm-field.phone fieldset.active {
  top: 1px;
  font-size: 9px;
  color: #000
}

.contact-form-main .gray-bg .field i.subject {
  background-position: -68px -376px;
}

.contact-form-main .gray-bg .field.full {
  width: 100%;
  margin: 0px;
  float: none;
  clear: both;
}

.contact-form-main .gray-bg .field input[type="text"],
.contact-form-main .gray-bg .field textarea,
.contact-form-main .gray-bg .field select {
  width: 100%;
  background: transparent;
  height: 40px;
  padding: 0 20px 0 40px;
  font-size: 14px;
  font-weight: 400;
  color: #242424;
  position: relative;
  z-index: 2;
  border: none;
}

.contact-form-main .gray-bg .field textarea {
  height: 141px;
  resize: none;
  padding: 10px 15px;
  width: calc(100% - 15px);
}

.contact-form-main .gray-bg .submit {
  display: block;
  max-width: 200px;
  margin: 20px auto 0;
  position: relative;
}

.contact-form-main .gray-bg .submit button[type="submit"] {
  background: #ed3e25;
  width: 100%;
  height: 45px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border: 0px;
  text-align: left;
  padding-left: 59px;
}
.continue{
  background: #ed3e25;
  width: 35%;
  height: 45px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  border: 0px;
  text-align: left;
  padding-left: 59px;
}

.contact-form-main .gray-bg .submit:before {
  position: absolute;
  left: 15%;
  top: 12px;
  content: '';
  background: url(../images/about-philosophy-icons.png) no-repeat -94px -374px;
  width: 22px;
  height: 21px;
}

.contact-form-main .red-bg {
  float: left;
  width: 30%;
  padding: 20px 25px 20px 25px;
  background-color: #ed3e25;
}

.contact-form-main .red-bg h3 {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  padding: 0px;
  margin: 0px 0 15px;
  line-height: normal;
}

.contact-form-main .red-bg h4 {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  padding: 0 0 10px 0;
  margin: 0px 0 10px 0;
  line-height: normal;
  position: relative;
}

.contact-form-main .red-bg h4:before {
  width: 48px;
  height: 2px;
  background: #fff;
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0;
}

.contact-form-main .red-bg p {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 0 0 0px 0;
  margin: 0px 0 20px 0;
  position: relative;
}

.contact-form-main .red-bg .social-icons i {
  font-size: 18px;
  color: #fff;
  margin: 5 4px;
  border: 2px solid #fff;
  border-radius: 100%;
  padding: 9px 11px 10px 11px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s;
}

.contact-form-main .red-bg .social-icons i:hover {
  color: #3b7dff;
  border-color: #3b7dff;
}

.contact-form-main .red-bg .social-icons a+a i:hover {
  color: #282828;
  border-color: #282828;
}

.contact-form-main .red-bg .social-icons a+a+a i:hover {
  color: #0056ff;
  border-color: #0056ff;
}

.contact-form-main .red-bg .social-icons a+a+a+a i:hover {
  color: #0274b3;
  border-color: #0274b3;
}

.contact-form-main .red-bg .social-icons a+a+a+a+a i:hover {
  color: #55adec;
  border-color: #55adec;
}

.custom-popup {
  position: fixed;
  overflow: hidden;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff0d4c url('../images/popup-bg.jpg') center center no-repeat;
  background-size: cover;
  z-index: 999999;
  display: flex;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease
}

.custom-popup.acttive {
  top: 0
}

.custom-popup h3 {
  font-size: 36px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  padding: 0;
  margin: 0
}

.custom-popup p {
  color: #fff;
  font-size: 20px;
}

.custom-popup a.closed {
  position: relative;
  display: block
}

.custom-popup a.closed::before {
  content: "\f00d";
  position: absolute;
  top: -40px;
  right: 14px;
  font-family: fontAwesome;
  font-size: 20px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  padding: 0 6.5px
}

.custom-popup>div {
  padding: 80px 20px
}

/* .custom-popup .jform{ margin: 20px 0; width: 100%; }
.custom-popup .jform .mm-field input,.custom-popup .jform .mm-field select , .custom-popup .jform textarea{width: 100%; background: transparent; border: 1px solid #fff; padding: 14px 14px 14px 45px; border-radius: 8px; margin: 0 0 20px; font-size: 15px; color: #fff; }
.custom-popup .jform .mm-field option { color: #000  }
.custom-popup .jform .intl-tel-input{width: 100%}
.custom-popup .intl-tel-input .selected-flag .iti-arrow{display: none}
.custom-popup .jform ::placeholder{color: #fff}
.custom-popup .jform .mm-field.pnfields input{ padding-left: 56px }
.custom-popup .jform textarea{ padding-top: 17px;padding-bottom: 15px; padding-left: 14px  }
.custom-popup .intl-tel-input.separate-dial-code .selected-dial-code{color: #fff}
.custom-popup .input-btn{ width: 303px; margin: 10px auto 0;position: relative;}
.custom-popup .input-btn::after{content: ""; color: #ff0243; position: absolute; top: 17px; right: 20px; background: url('/web-content/images/sprites.png') center no-repeat; background-position: -3px -19px; width: 39px; height: 10px; }
.custom-popup .input-btn > input{ font-size: 18px; color: #242424; font-family: 'Montserrat', sans-serif; background: #ffffff; font-weight: 600; padding: 10px 30px 7px 0px; border-radius:25px; outline: none; border: none; width: 100% } */
.fixedform-1 form {
  width: 100%;
  margin: 30px 0
}

.fixedform-1 .mm-field {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.fixedform-1 .mm-field input {
  width: 100%;
}

.fixedform-1 .mm-field input[type=text],
.fixedform-1 .mm-field select,
.fixedform-1 .mm-field input,
.fixedform-1 .mm-field textarea,
[type=email] {
  background-color: transparent !important;
  z-index: 10;
  position: relative;
  border: 1px solid #fff;
  padding: 14px 10px 14px 50px;
  width: 100%;
  border-radius: 8px;
  color: #fff;
}

.fixedform-1 .mm-field {
  position: relative;
  border-radius: 2px;
}

.fixedform-1 option {
  color: #000
}

.fixedform-1 .intl-tel-input.separate-dial-code .selected-dial-code {
  color: #fff
}

.fixedform-1 .intl-tel-input .selected-flag .iti-arrow {
  border-top-color: #fff
}

.fixedform-1 .intl-tel-input.separate-dial-code .selected-dial-code {
  padding-left: 0
}

.fixedform-1 .mm-field.pnfields:before {
  display: none;
}

.fixedform-1 .mm-field.emailfield:before {
  background-position: -623px -167px;
  width: 52px;
  height: 44px;
}

.fixedform-1 .mm-field.pnfields .intl-tel-input {
  width: 100%;
}

.fixedform-1 .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input,
.fixedform-1 .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=text],
.fixedform-1.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-3 input[type=tel] {
  padding-left: 90px;
}

.fixedform-1 fieldset {
  position: absolute;
  top: 14px;
  left: 15px;
  z-index: 2;
  color: #fff;
  font-size: 15px;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease-in-out;
}

.fixedform-1 .intl-tel-input.allow-dropdown .flag-container,
.intl-tel-input.separate-dial-code .flag-container {
  z-index: 999;
}

.fixedform-1 .intl-tel-input.allow-dropdown .flag-container .selected-flag {
  border-right: none;
}

.fixedform-1 .mm-field.selectsertvice:before {
  background-position: -684px -166px;
  width: 52px;
  height: 44px;
}

.fixedform-1 .mm-field.whbgnone {
  margin-bottom: 0;
}

.fixedform-1 .mm-field.whbgnone:before {
  background-position: -725px -97px;
  width: 21px;
  height: 21px;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: initial;
}

.fixedform-1 .mm-field.whbgnone input[type="submit"] {
  background: #ed3e25;
  border-radius: 30px;
  width: 100%;
  padding: 14px 35px;
  color: #fff;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  border: transparent;
}

.fixedform-1 .mm-field fieldset.active {
  top: 0px;
  font-size: 10px;
  color: #fff;
  padding: 1px 8px;
  z-index: 20;
  left: 47px;
  font-weight: 500;
}

.fixedform-1 .mm-field.pnfields fieldset {
  left: 15px;
}

.fixedform-1 .mm-field.pnfields fieldset.active {
  left: 82px;
}

.fixedform-1.active {
  right: 0;
}

.fixedform-1.active .barforms {
  background-position: -815px -8px;
}

.fixedform-1 .mm-field textarea {
  padding-left: 14px
}

.fixedform-1 .textarea fieldset {
  left: 16px
}

.fixedform-1 .textarea fieldset {
  left: 16px
}

.fixedform-1 .mm-field.textarea fieldset.active {
  left: 16px;
}

.fixedform-1 .input-btn {
  width: 303px;
  margin: 10px auto 0;
  position: relative;
}

.fixedform-1 .input-btn::after {
  content: "";
  color: #ff0243;
  position: absolute;
  top: 20px;
  right: 20px;
  background: url('../images/sprites.png') center no-repeat;
  background-position: -3px -19px;
  width: 39px;
  height: 10px;
}

.fixedform-1 .input-btn>button {
  font-size: 18px;
  color: #242424;
  font-family: 'Montserrat', sans-serif;
  background: #ffffff;
  font-weight: 600;
  padding: 12px 30px 12px 0px;
  border-radius: 25px;
  outline: none;
  border: none;
  width: 100%
}

.fixedform-1 .jform .mm-field {
  position: relative;
}

.fixedform-1 .jform .mm-field.namefield::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url('../images/sprites.png')center center no-repeat;
  background-position: -712px 2px;
  width: 50px;
  height: 50px;
}

.fixedform-1 .jform .mm-field.emailfield::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url('../images/sprites.png')center center no-repeat;
  background-position: -713px -37px;
  width: 50px;
  height: 50px;
}

.fixedform-1 .jform .mm-field.selectsertvice::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url('../images/sprites.png')center center no-repeat;
  background-position: -756px 2px;
  width: 50px;
  height: 50px;
}


.contact-form-main .intl-tel-input {
  width: 100%;
  padding-left: 16px
}

.contact-form-main .mm-field.phone fieldset {
  left: 40px;
}

.contact-form-main .intl-tel-input.allow-dropdown .flag-container .selected-flag {
  background: #fff
}

/* .fixed-forms-main{ opacity: 1; } */
.web-design .det-packages .packagesall a {
  width: 285px;
}

.web-design.process .det-packages .packagesall a {
  width: 260px;
}

.web-design .det-packages .packagesall a:hover {
  width: 350px;
}

.thanku {
  padding: 150px 0 100px;
}

.thank-you-img {
  text-align: center;
}

.thank-you-img p {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  padding: 0 0 50px 0;
  margin: 0px;
  color: #666
}

.thanks-box {
  text-align: center;
}

.thanks-box i {}

.thanks-box i:before {
  content: "\f0e0";
  color: #ed3e25;
  font-size: 50px;
  font-family: 'FontAwesome';
  font-style: normal;
}

.thanks-box h6 {
  font-size: 20px;
  font-weight: 600;
  color: #ed3e25;
  padding: 0;
  margin: 20px 0;
}

.thanks-box i.phone:before {
  content: "\f095";
}

.thanks-box i.chat:before {
  content: "\f086";
}

.thanks-box p {
  padding: 0;
  margin: 5px 0;
  font-size: 14px;
}

.thanks-box a {
  font-weight: 600;
  color: #333;
}
label.error {
  display: none !important
}

input.error {
  border-color: #ecdc56 !important;
}

.gray-bg input.error {
  border-color: #ff0000 !important;
}

#side-form input.error {
  border-color: #ff0000 !important;
}


ul.secondinner-navs li:hover a{
  color: #ed3e25 !important;
}





.popup-main-wrap {
  padding: 80px 36px;
  background-color: #f4f4f4;;
  border: 5px solid #f13120;
  border-radius: 15px
}


.entry-box1 {
  max-width: 900px;
  margin: 0 auto;
  max-height: 580px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9999;
  overflow: unset!important;
  display: none
}


.form-left-art,.main-banner {
  background-size: cover;
  background-repeat: no-repeat
}

.banner-logos-common,.form-left-art,.main-banner,.package-box h4+span,.sprite {
  background-repeat: no-repeat
}

.form-left-art h3 {
  font-family: Poppins,sans-serif;
  font-weight: 400;
  font-size: 30px;
  color: #292929;
  margin-bottom: 20px
}

.form-left-art h2 {
  font-family: Poppins,sans-serif;
  font-weight: 800;
  font-size: 54px;
  color: #95ce01;
  margin-bottom: 40px
}

.form-left-art h4,.form-left-art sup {
  color: #fff;
  font-family: Poppins,sans-serif
}

.form-left-art sup {
  font-weight: 400;
  font-size: 20px;
  vertical-align: super;
  position: absolute;
  left: 49px;
  top: 27px
}

.form-left-art h4 {
  font-size: 111px;
  margin-bottom: 0;
  background-color: #95ce01;
  margin-left: -50px;
  position: relative;
  padding: 0 106px;
  line-height: 125px;
  max-width: 380px
}



.form-right-art h3 {
  font-weight: 400;
  font-size: 25px;
  color: #000;
  margin-bottom: 0
}

.form-right-art h2 {
  font-weight: 700;
  font-size: 41px;
  color: #ff7100
}

.form-left-art {
  background-color: wheat;
  padding: 20px 90px 20px 50px;
  border-radius: 15px;
  margin-left: -125px
}


.sup2 {
  left: 310px!important;
  top: 46px!important
}

.ftr-frm button,.ftr-frm input {
  border-radius: 50rem!important;
  padding: 10px 31px!important;
  height: auto
}



.clientProject .input-group .form-control {
  height: 3.125rem;
  border-radius: 0;
  border: 0
}

.clientProject .input-group .form-control:focus {
  box-shadow: none
}


.clientProject .form-control {
  height: 39.5px;
  border-radius: .25rem!important;
  border: 0
}

.margin-bottom-3{
margin-bottom: 5%;
}

.ftr-frm button {
  font-family: Poppins,sans-serif;
  font-weight: 500;
  font-size: 22px;
  background-color: #ed3e25;
  color: #fff;
  border: 2px solid transparent;
  width: 100%
}


.ftr-frm button:focus,:focus,button:focus {
  outline: 0
}

.cross {
  position: absolute;
  color: #fff;
  right: 16px;
  top: 15px;
  padding: 0!important;
  background-color: #ed3e25!important;
  border-radius: 50%;
  opacity: 1;
  font-weight: 400;
  font-size: 32px;
  width: 40px;
  height: 40px;
  text-shadow: none;
  line-height: 38px;
  float: none;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal-img{
  position: relative;
  left: -120px;
  top: 30px;
}








.step-form-3{
  
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 280px;
    font-weight: 800;
    font-size: 60px;

}
























.form-sec ul {
  margin-bottom: 0px;
  padding: 0;
}



.form-sec ul li {padding-bottom: 20px;clear: both;}

.form-sec ul li label {
display: block;
margin-bottom: 8px;
color: #515151;
font-size: 15px;
text-transform: uppercase;
}

.form-sec ul li label .req-field-star {
color: red;
}

input.seq_continuew_btn.continue_active {
background: #92002c!important;
}
.form-sec ul li input[type=text], .form-sec ul li input[type=email], .form-sec ul li input[type=tel], .form-sec ul li input[type=password], .form-sec ul li select, .form-sec ul li textarea {background: #fff;font-size: 14px;border-radius: 2px;color: #4e4e4e;border: #e2e2e2 1px solid;padding: 16px 15px;text-transform: none;width: 100%;}
