.stepper-wrapper {
    * {
      box-sizing: border-box;
    }
  
    /* styles of default stepper */
    .stepper-head {
      display: flex;
      position: relative;
      width: 100%;
      user-select: none;
  
      /* styles of inline stepper */
      &.inline-stepper-head {
        .stepper-step {
          &:after {
            top: 50%;
            transform: translateY(-50%);
          }
        }
  
        .stepper-indicator,
        .stepper-label {
          display: inline-block;
          vertical-align: middle;
        }
  
        .stepper-label {
          text-align: left;
          padding: 0 0.5rem;
          background: #fff;
        }
      }
  
      /* styles of vertical stepper */
      &.vertical-stepper-head {
        flex-direction: column;
        width: auto;
  
        .stepper-step {
          display: flex;
          align-items: center;
          text-align: start;
  
          &:after {
            content: " ";
            position: absolute;
            left: 1.5rem;
            right: auto;
            top: 2rem;
            bottom: -0.5rem;
            width: 0;
            height: auto;
            border-left: 0.125rem solid #e3e8ec;
          }
  
          &.rightToLeft {
            &:after {
              right: 1.5rem;
              left: auto;
            }
          }
        }
  
        .stepper-label {
          padding-inline-start: 1rem;
        }
  
        + .stepper-body {
          margin-inline-start: 3.5rem;
        }
      }
  
      .stepper {
        &-step {
          position: relative;
          text-align: center;
          padding: 0.5rem;
          flex-basis: 100%;
  
          &.rightToLeft {
            &:after {
              left: -50%;
            }
          }
  
          &.is-complete {
            .stepper-indicator-info {
              border-color: #4caf50;
              background-color: #4caf50;
              cursor: pointer;
            }
  
            .stepper-label {
              color: #4caf50;
            }
  
            // &:after {
            //   background-color: #4caf50;
            // }
          }
  
          &.is-active {
            .stepper-indicator-info {
              border-color: #627c90;
              background-color: #627c90;
              cursor: initial;
            }
  
            .stepper-label {
              color: #627c90;
            }
          }
  
          &.is-warning {
            .stepper-indicator-info {
              border-color: #f1c40f;
              background-color: #f1c40f;
            }
  
            .stepper-label {
              color: #f1c40f;
            }
  
            // &:after {
            //   background-color: #f1c40f;
            // }
          }
  
          &.is-error {
            .stepper-indicator-info {
              border-color: #e95a4b;
              background-color: #e95a4b;
            }
  
            .stepper-label {
              color: #e95a4b;
            }
  
            // &:after {
            //   background-color: #e95a4b;
            // }
          }
  
          &:after {
            content: " ";
            position: absolute;
            left: 50%;
            top: 1.5rem;
            width: 100%;
            height: 0.125rem;
            background-color: #e3e8ec;
            z-index: 1;
          }
  
          &:last-child:after {
            display: none;
          }
        }
  
        &-indicator {
          position: relative;
          display: block;
          z-index: 2;
  
          &-info {
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 2rem;
            height: 2rem;
            border: 1px solid #e3e8ec;
            border-radius: 50%;
            background-color: #e3e8ec;
            font-size: 1rem;
            line-height: 2.5rem;
            text-align: center;
            color: #fff;
            z-index: 2;
  
            .stepper-tick {
              width: 14px;
              fill: #ffffff;
            }
          }
        }
  
        &-label {
          position: relative;
          display: block;
          margin: 0.5rem 0;
          color: #cfd7de;
          z-index: 2;
        }
      }
    }
  
    .stepper-body {
      flex: 1;
      min-height: 100px;
      padding: 10px 0;
    }
  
    .stepper-footer {
      display: flex;
      align-items: center;
      padding-top: 10px;
  
      &-btn {
        color: rgba(0, 0, 0, 0.87);
        padding: 6px 16px;
        font-size: 0.875rem;
        min-width: 64px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        border-radius: 4px;
        letter-spacing: 0.02857em;
        cursor: pointer;
        border: none;
        outline: none;
  
        &.primary {
          color: #fff;
          background-color: #1976d2;
        }
  
        &.success {
          color: #fff;
          background-color: #4caf50;
        }
  
        &:disabled {
          color: rgba(0, 0, 0, 0.26);
          background-color: rgba(0, 0, 0, 0.12);
          cursor: not-allowed;
        }
      }
    }
  }
  

  .card-item__cvvBand{
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35)
  }






  @import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

// * {
//   box-sizing: border-box;
//   outline: none;
// }

// body {
//   background: #ddeefc;
//   font-family: "Source Sans Pro", sans-serif;
//   font-size: 16px;
// }

.card-form {
  max-width: 570px;
  min-width: 490px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 70px;
  width: 100%;

  .card-inputs {
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding-top: 150px; //180
  }

  form {
    margin: 0 auto;
    padding: 35px;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 2fr 1fr;
    width: 100%;
  }

  .lg-input {
    grid-column: 1 / 4;
  }

  .med-input {
    grid-column: 1 / 3;
  }

  .sm-input {
    grid-column: 3 / 4;
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }

  .name-input,
  .number-input,
  .cvv-input {
    width: 100%;
  }

  .month-input,
  .year-input {
    width: 44%;
    margin-right: 15px;
  }

  input,
  select {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ced6e0;
    box-shadow: none;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;
    transition: all 0.3s ease-in-out;
    letter-spacing: 1px;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
  }

  select {
    -webkit-appearance: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
    background-size: 12px;
    background-position: 90% center;
    background-repeat: no-repeat;
    padding-right: 30px;
  }

  button {
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.disabled {
      opacity: 0.5;
      cursor: default;
      box-shadow: none;
    }
  }
}

#root div {
  /*   background: red; */
}

.card {
  margin: 0 auto;
  width: 430px;
  height: 270px;
  border-radius: 15px;
  position: relative;

  &.container {
    margin-bottom: -130px;
    perspective: 1500px;
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.4s ease-in-out;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }

    .inner {
      width: 100%;
      height: 100%;
      position: relative;
      transition: transform 1s;
      transform-style: preserve-3d;
      box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);

      &.flipped {
        transform: rotateY(180deg);
      }

      .back,
      .front {
        position: absolute;
        height: 100%;
        width: 100%;
        backface-visibility: hidden;
      }

      .back {
        transform: rotateY(180deg);
      }
    }
  }

  .back {
    background: yellow;
    transform: rotateY(180deg);
    backface-visibility: hidden;
    position: absolute;
    top: 0;
  }

  .cover {
  }

  .overlay {
    background: rgba(6, 2, 29, 0.4);
  }

  .overlay,
  .content,
  .slider {
    position: absolute;
    top: 0;
  }

  .slider {
    transition: all 0.45s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 3px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    @mixin selection {
      opacity: 1;
      border-radius: 8px;
    }

    &.on-number {
      transform: translate(15px, 95px);
      @include selection();
      width: 350px;
      height: 50px;
    }

    &.on-name {
      transform: translate(15px, 185px);
      @include selection();
      width: 300px;
      height: 57px;
    }

    &.on-expiration {
      transform: translate(315px, 185px);
      @include selection();
      width: 100px;
      height: 57px;
    }
  }
  .hidden{
    display: block !important;
  }
  .content {
    padding: 25px 15px;
    color: #fff;

    label {
      color: #fff;
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: 5px;
    }

    .number {
      // background: blue;
      width: 92%;
      padding: 15px;
      margin-bottom: 30px;
      font-weight: 500;
      line-height: 1;
      color: #fff;
      font-size: 27px;
      position: relative;
      cursor: pointer;

      .digit-wrapper {
        display: inline-block;
        width: 16px;
        .digit {
          display: inline-block;
          width: 100%;
          vertical-align: middle;
          transition: all 0.3s ease-in-out;
          position: absolute;
          top: 10px;
        }

        &:nth-child(4n) {
          margin-right: 20px;
        }
      }
    }

    .shown {
      transform: translateY(0);
      opacity: 1;
    }

    .hidden:nth-child(1) {
      transform: translateY(-15px);
      opacity: 0;
    }

    .hidden:nth-child(2) {
      transform: translateY(15px);
      opacity: 0;
    }

    .name {
      width: 75%;
      // background: red;
      padding-left: 15px;
      padding-top: 8px;
      position: relative;
      cursor: pointer;

      .placeholder {
        transition: all 0.3s ease-in-out;
      }

      .name-container {
        position: absolute;
        top: 25px;
      }
      .character {
        animation: character-slide 0.6s ease-in-out forwards;
        display: inline-block;

        &.space {
          height: 100%;
          width: 8px;
        }

        @keyframes character-slide {
          0% {
            opacity: 0;
            transform: translateX(50px) rotate(180deg);
          }
          20% {
            opacity: 1;
          }
          100% {
            transform: translateY(0) rotate(0);
          }
        }
      }
    }

    .expiration {
      width: 25%;
      // background: pink;
      padding-right: 10px;
      padding-left: 15px;
      padding-top: 5px;
      cursor: pointer;

      .double-digit {
        display: inline-block;
        text-align: center;
      }

      .double-digit:nth-child(2n + 1) {
        width: 29px;
      }

      .double-digit:nth-child(2) {
        margin-left: 2px;
      }

      .toggle1 {
        animation: toggle1 0.24s ease-in-out forwards;
      }

      .toggle2 {
        animation: toggle2 0.24s ease-in-out forwards;
      }

      @keyframes toggle1 {
        0% {
          opacity: 0;
          transform: translateY(15px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      @keyframes toggle2 {
        0% {
          opacity: 0;
          transform: translateY(15px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .name,
    .expiration {
      display: inline-block;
      padding-bottom: 9px;
      font-weight: 500;

      div {
        font-size: 18px;
        line-height: 1;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  .chip {
    margin-left: 10px;
    margin-bottom: 35px;
    width: 60px;
    height: 50px;
    background-image: url(https://res.cloudinary.com/jasuaje/image/upload/v1661352906/chip_gviivf.png);//https://pngimage.net/wp-content/uploads/2018/05/credit-card-chip-png-9.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  


  .type {
    width: 85px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 25px;
    top: 25px;
    transition: opacity 0.3s ease-in-out;

    $duration: 0.24s;
    $distance: 20px;
    $function: ease-in-out;

    &.amex {
      //345
      background-image: url(https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/amex.png);
      height: 35px;
      animation: amex $duration $function forwards;
    }

    &.visa {
      //any
      background-image: url(https://res.cloudinary.com/jasuaje/image/upload/v1661353947/visa_b4lhz1.png);
      height: 45px;
      animation: visa $duration $function forwards;
    }

    &.mastercard {
      //51
      background-image: url(https://res.cloudinary.com/jasuaje/image/upload/v1661353947/mastercard_v7vv3g.png);
      height: 70px;
      animation: mastercard $duration $function forwards;
    }

    &.discover {
      //6011
      background-image: url(https://res.cloudinary.com/jasuaje/image/upload/v1661353947/discover_d1f7xj.png);
      height: 20px;
      animation: discover $duration $function forwards;
    }

    @keyframes visa {
      0% {
        opacity: 0;
        transform: translateY($distance);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes amex {
      0% {
        opacity: 0;
        transform: translateY($distance);
      }
      100% {
        opacity: 1;
        transform: translateY(0px);
      }
    }

    @keyframes mastercard {
      0% {
        opacity: 0;
        transform: translateY($distance);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes discover {
      0% {
        opacity: 0;
        transform: translateY($distance);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 107.4%;
  height: 50px;
  margin-top: -247px;
  position: relative;
  z-index: 2;
}

.card-item__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}